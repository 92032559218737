import Vue from 'vue';
import Vuetify, { VRow } from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify, {
  components: {
    VRow,
  },
});

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'fa',
    values: {
      sort: 'fas fa-caret-up',
    },
  },
});
