<template>
  <span :class="classes">{{ formattedValue }}</span>
</template>

<script>
import Big from 'big.js';
import { displayMoney } from '../../../../helpers';

export default {
  name: 'BaseAmount',
  props: {
    value: {
      required: true,
      type: Big,
    },
    colored: {
      required: false,
      type: Boolean,
      default: false,
    },
    positive: {
      required: false,
      type: Boolean,
      default: false,
    },
    negative: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      return displayMoney({ value: this.value });
    },
    classes() {
      const classes = {};

      if (this.positive === true) {
        classes['success--text'] = true;
        return classes;
      }

      if (this.negative === true) {
        classes['error--text'] = true;
        return classes;
      }

      if (this.colored === false || this.value.eq(0)) {
        return classes;
      }

      const isNegative = this.value.lt(0);

      return {
        'success--text': isNegative === false,
        'error--text': isNegative === true,
      };
    },
  },
};
</script>

<style scoped>

</style>
