import gql from 'graphql-tag';

// export const queryDataIncomeOutcomePerMonth = gql`query incomeOutcomePerMonth {
//   incomeOutcomePerMonth {
//     dateGroupedBy
//     income
//     outcome
//   }
// }
// `;
//
// export const queryDataIncomeOutcomePerWeek = gql`query incomeOutcomePerWeek {
//   incomeOutcomePerWeek {
//     dateGroupedBy
//     income
//     outcome
//   }
// }
// `;

export const queryDataBasicStatisticsOverTime = gql`query basicStatisticsOverTime($categoriesExcluded: [ID], $categoriesIncluded: [ID]) {
    basicStatisticsOverTime(categoriesExcluded: $categoriesExcluded, categoriesIncluded: $categoriesIncluded) {
      dateGroupedBy
      income
      outcome
      balance
    }
}
`;

export const queryDataStatisticsAccountsOverTime = gql`query statisticsAccountsOverTime($accountsExcluded: [ID], $accountsIncluded: [ID]) {
    statisticsAccountsOverTime(accountsExcluded: $accountsExcluded, accountsIncluded: $accountsIncluded) {
      dateGroupedBy
      accounts {
        id
        income
        outcome
        balance
      }
    }
}
`;

export const queryDataStatisticsInvestmentsOverTime = gql`query statisticsInvestmentsOverTime {
    statisticsInvestmentsOverTime {
      dateGroupedBy
      invested
      interestNet
    }
}
`;

export const queryDataStatisticsBookingsOverTime = gql`query statisticsBookingsOverTime {
    statisticsBookingsOverTime {
      dateGroupedBy
      shares
      amount
      fee
    }
}
`;

export const queryDataIncomeOutcomePerMonthCategories = gql`query incomeOutcomePerMonthCategories($categoriesExcluded: [ID], $categoriesIncluded: [ID], $titlesExcluded: [ID]) {
  incomeOutcomePerMonthCategories(categoriesExcluded: $categoriesExcluded, categoriesIncluded: $categoriesIncluded, titlesExcluded: $titlesExcluded) {
    dateGroupedBy
    categories {
      id
      amount
    }
  }
}
`;

export const queryDataIncomeOutcomePerWeekCategories = gql`query incomeOutcomePerWeekCategories($categoriesExcluded: [ID], $categoriesIncluded: [ID]) {
  incomeOutcomePerWeekCategories(categoriesExcluded: $categoriesExcluded, categoriesIncluded: $categoriesIncluded) {
    dateGroupedBy
    categories {
      id
      amount
    }
  }
}
`;

export const queryDataStatisticsSharesProfit = gql`query dataStatisticsSharesProfit {
  statisticsSharesProfit
}
`;
