
















import TheSnackbar from '@/modules/app/components/the-snackbar';
import { ServiceApp } from '@/modules/app/app.service';
import BaseUpdate from '@/modules/app/components/base/base-update.vue';

export default {
  name: 'App',
  components: { BaseUpdate, TheSnackbar },
  created() {
    ServiceApp.initialize();
  },
};
