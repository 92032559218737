import { apolloClient } from '@/vue-apollo';
import {
  mutateAddImportSetting, mutateDeleteImportSetting,
  mutateEditImportSetting,
  mutateImportData, mutateSubmitImportData,
  queryImportData,
} from '@/modules/import/import.graphql';
import { store } from '@/modules/app/app.store';
import { Transaction } from '@/modules/transactions/transaction.model';
import { ServiceTransactions } from '@/modules/transactions/transactions.service';
import { ServiceTransfers } from '@/modules/transfer/transfer.service';
import { Transfer } from '@/modules/transfer/transfer.model';
import { Title } from '@/modules/title/title.model';
import Big from 'big.js';
import { i18n } from '@/i18n';
import { ImportSetting } from '@/modules/import/import-setting.model';

const postProcessImportData = async (data) => {
  if (data === null) {
    return data;
  }

  data.transactions = await Promise.all(data.transactions.map((transaction) => Transaction.parseFromServer(transaction)));
  data.transfers = await Promise.all(data.transfers.map((transfer) => Transfer.parseFromServer(transfer)));

  return data;
};

export const useImport = {
  async readImportData() {
    const response = await apolloClient.query({
      query: queryImportData,
      fetchPolicy: 'no-cache',
    });

    store.commit('moduleImport/setImportData', await postProcessImportData(response.data.importData));
  },
  async import({ file }: {file: File}) {
    const response = await apolloClient.mutate({
      mutation: mutateImportData,
      variables: {
        file,
      },
    });

    store.commit('moduleImport/setImportData', await postProcessImportData(response.data.importData));
  },
  async submitImport({ transactions, transfers }) {
    const response = await apolloClient.mutate({
      mutation: mutateSubmitImportData,
      variables: {
        transactions: transactions.map((transaction) => transaction.prepareForServer()),
        transfers: transfers.map((transfer) => transfer.prepareForServer()),
      },
    });

    store.commit('addSnackbar', {
      message: i18n.t('import.imported'),
      color: 'success',
    });

    store.commit('moduleImport/setImportData', null);
  },
  async addSetting(setting) {
    const response = await apolloClient.mutate({
      mutation: mutateAddImportSetting,
      variables: {
        setting: setting.prepareForServer(),
      },
    });

    store.commit('moduleImport/addImportSetting', await ImportSetting.parseFromServer(response.data.addImportSetting.setting));
  },
  async editSetting(setting) {
    const response = await apolloClient.mutate({
      mutation: mutateEditImportSetting,
      variables: {
        setting: setting.prepareForServer(),
      },
    });

    store.commit('moduleImport/editImportSetting', await ImportSetting.parseFromServer(response.data.editImportSetting.setting));
  },
  async editSetting(setting) {
    const response = await apolloClient.mutate({
      mutation: mutateEditImportSetting,
      variables: {
        setting: setting.prepareForServer(),
      },
    });

    store.commit('moduleImport/editImportSetting', await ImportSetting.parseFromServer(response.data.editImportSetting.setting));
  },
  async deleteSetting(setting) {
    const response = await apolloClient.mutate({
      mutation: mutateDeleteImportSetting,
      variables: {
        idImportSetting: setting.id,
      },
    });

    store.commit('moduleImport/deleteImportSetting', setting);
  },
};
