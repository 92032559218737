




































































































































































import CreateGroupCategory from '@/modules/category/components/groupCategory/create-group-category';
import { useGroupsCategory } from '@/modules/category/composables/useGroupCategories';
import { defineComponent, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';
import ItemGroupCategory from '@/modules/category/components/groupCategory/item-group-category.vue';
import { GroupCategory } from '@/modules/category/groupCategory.model';
import BaseCategoryName from '@/modules/category/components/base/base-category-name.vue';

export default defineComponent({
  name: 'TableGroupsCategory',
  components: {
    BaseCategoryName,
    ItemGroupCategory,
    CreateGroupCategory,
  },
  setup() {
    const headers = [
      {
        text: i18n.t('category.item.title'),
        value: 'title',
        class: ['text-no-wrap'],
      },
      {
        text: i18n.tc('category.title', 2),
        value: 'categories',
        width: '1px',
        align: 'right',
        class: ['text-no-wrap'],
      },
      {
        value: 'action',
        sortable: false,
        align: 'right',
        width: '1px',
      },
    ];

    const dialogDelete = ref(false);
    const dialogEdit = ref(false);
    const itemCurrent = ref({});

    const { groupsCategorySorted } = useGroupsCategory();

    return {
      headers,
      dialogDelete,
      dialogEdit,
      itemCurrent,
      groupsCategorySorted,
      openEditItem(item) {
        itemCurrent.value = new GroupCategory(item);
        dialogEdit.value = true;
      },
      openDeleteItem(item) {
        itemCurrent.value = item;
        dialogDelete.value = true;
      },
      async editItem() {
        await useGroupsCategory().edit(itemCurrent.value);
        dialogEdit.value = false;
      },
      async deleteItem() {
        await useGroupsCategory().delete(itemCurrent.value);
        dialogDelete.value = false;
      },
    };
  },
});
