<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            ref="title"
            v-model="value.title"
            prepend-icon="fas fa-tags"
            autofocus
            :label="$t('category.item.title')"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <base-input-color
            v-model="value.color"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-radio-group
            v-model="value.income"
            row
          >
            <v-radio
              :label="$t('app.income')"
              :value="true"
            />
            <v-radio
              :label="$t('app.outcome')"
              :value="false"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { Category } from '@/modules/category/category.model';
import BaseInputColor from '@/modules/app/components/base/base-input-color';

export default {
  name: 'ItemCategory',
  components: { BaseInputColor },
  props: {
    value: {
      type: Category,
      required: true,
    },
  },
  methods: {
    focus() {
      this.$refs.title.focus();
    },
  },
};
</script>

<style scoped>

</style>
