<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col>
          <v-card>
            <table-titles />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import TableTitles from '@/modules/title/components/table-titles.vue';

export default {
  name: 'ViewTitles',
  components: { TableTitles },
};
</script>

<style scoped>

</style>
