import {
  mutateTransactionCreate, mutateTransactionDelete, mutateTransactionEdit,
  queryTransactionsPage,
} from '@/modules/transactions/transaction.graphql';
import { store } from '@/modules/app/app.store';
import { apolloClient } from '@/vue-apollo';
import { Transaction } from '@/modules/transactions/transaction.model';
import { Title } from '@/modules/title/title.model';

export class ServiceTransactions {
  static async loadPage({
    page, sortBy, sortDesc, optionsFilter = {}, initialize = false,
  }: { page: number; sortBy: string[]; sortDesc: boolean[]; optionsFilter: {}; initialize: boolean }): Promise<Transaction[]> {
    if (initialize) {
      await store.dispatch('moduleTransaction/setTransactions', {
        transactions: [],
      });
    }

    const optionsFilterProcessed = Object.entries(optionsFilter).reduce((obj, [key, value]) => {
      if (value !== null) {
        obj[key] = value;
      }
      return obj;
    }, {});

    if (optionsFilterProcessed.category !== undefined) optionsFilterProcessed.category = optionsFilterProcessed.category.id;
    if (optionsFilterProcessed.account !== undefined) optionsFilterProcessed.account = optionsFilterProcessed.account.id;
    if (optionsFilterProcessed.title !== undefined) optionsFilterProcessed.title = optionsFilterProcessed.title.id;

    const response = await apolloClient.query({
      query: queryTransactionsPage,
      variables: {
        page,
        count: 100,
        sortBy,
        sortDesc,
        filters: optionsFilterProcessed,
      },
      fetchPolicy: 'no-cache',
    });

    const transactions = await Promise.all(response.data.transactions.map((transaction: {}) => Transaction.parseFromServer(transaction)));

    await store.dispatch('moduleTransaction/setTransactions', {
      transactions: initialize ? transactions : [...store.state.moduleTransaction.transactions, ...transactions],
    });

    await store.dispatch('moduleTransaction/setCountTotalTransactions', {
      count: response.data.countTransactions,
    });

    return transactions;
  }

  static async create({ transaction: transactionPassed }: { transaction: Transaction}): Promise<Transaction> {
    console.log('creating transaction', transactionPassed, JSON.stringify(transactionPassed));

    const response = await apolloClient.mutate({
      mutation: mutateTransactionCreate,
      variables: {
        transaction: transactionPassed.prepareForServer(),
      },
    });

    const transactionNew = await Transaction.parseFromServer(response.data.transactionCreate.transaction);

    return transactionNew;
  }

  static async edit({ transaction: transactionPassed }: { transaction: Transaction}) {
    console.log('editing transaction', transactionPassed, JSON.stringify(transactionPassed));

    const response = await apolloClient.mutate({
      mutation: mutateTransactionEdit,
      variables: {
        transaction: transactionPassed.prepareForServer(),
      },
    });

    const transactionEdited = await Transaction.parseFromServer(response.data.transactionEdit.transaction);

    console.warn('response', response);

    return transactionEdited;
  }

  static async delete({ transaction: transactionPassed }: { transaction: Transaction}) {
    console.log('deleting transaction', transactionPassed, JSON.stringify(transactionPassed));

    const response = await apolloClient.mutate({
      mutation: mutateTransactionDelete,
      variables: {
        idTransaction: transactionPassed.id,
      },
    });

    console.warn('response', response);
  }
}
