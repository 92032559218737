<template>
  <v-snackbar
    v-model="isOpen"
    :timeout="-1"
    class="text-center"
    color="warning"
    top
  >
    {{ $t('app.update') }}
    <v-btn
      :loading="isUpdating"
      @click="update"
    >
      Update
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BaseUpdate',
  data() {
    return {
      registration: null,
      isOpen: false,
      isUpdating: false,
    };
  },
  created() {
    document.addEventListener(
      'swUpdated', this.showAlert, { once: true },
    );

    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        window.location.reload();
      },
    );
  },
  methods: {
    update() {
      if (!this.registration || !this.registration.waiting) return;

      this.isUpdating = true;

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
    showAlert(e) {
      this.registration = e.detail;
      this.isOpen = true;
    },
  },
};
</script>

<style scoped>

</style>
