<template>
  <span>{{ dateFormatted }}</span>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'BaseDate',
  props: {
    value: {
      required: true,
      type: Date,
    },
  },
  computed: {
    dateFormatted() {
      return format(this.value, 'dd.MM.yyyy');
    },

  },
};
</script>

<style scoped>

</style>
