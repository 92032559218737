



















































import { Person } from '@/modules/person/person.model';
import ItemPerson from '@/modules/person/components/item-person';
import { ServicePerson } from '@/modules/person/person.service';
import BaseButtonCancel from '@/modules/app/components/base/base-button-cancel.vue';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import BaseButtonSaveAndNew from '@/modules/app/components/base/base-button-save-and-new.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { store } from '@/modules/app/app.store';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'CreatePerson',
  components: {
    BaseButtonSaveAndNew, BaseButtonSave, BaseButtonCancel, ItemPerson,
  },
  setup(props, { emit }) {
    const person = ref<Person>(new Person());
    const dialog = ref(false);
    const isLoading = ref(false);
    const refItemPerson = ref(null);

    const reset = ({ addNew = false } = {}) => {
      if (addNew === false) {
        this.dialog = false;
      }
      person.value = new Person();

      refItemPerson.value.focus();
    };

    const save = async ({ addNew = false } = {}) => {
      isLoading.value = true;

      const personNew = await ServicePerson.create({
        person: person.value,
      });
      reset({ addNew });

      emit('created', personNew);

      isLoading.value = false;
    };

    return {
      vuelidate: useVuelidate({
        name: {
          required,
          unique: (name) => store.getters['modulePerson/names'][name] === undefined,
        },
      }, person, { $stopPropagation: true }),
      refItemPerson,
      reset,
      save,
      dialog,
      person,
    };
  },
});
