import './installCompositionAPI';
import Vue from 'vue';
import { apolloProvider } from '@/vue-apollo';
import Chart from 'chart.js';
import { i18n } from '@/i18n';
import HighchartsVue, { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import App from './App';
import './registerServiceWorker';
import router from './router';
import { store } from './modules/app/app.store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Chart.Tooltip.positioners.zero = (chartelements) => {
  const gridLineItems = chartelements[1]._yScale._gridLineItems;
  let y = 0;
  if (gridLineItems !== null) {
    y = gridLineItems[chartelements[1]._yScale.zeroLineIndex].y1;
  }

  return {
    x: chartelements[1]._view.x - (chartelements[1]._view.width / 2.0) - 8,
    y,
  };
};

stockInit(Highcharts);
Vue.use(HighchartsVue);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
