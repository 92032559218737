




































































import { computed, defineComponent, ref } from '@vue/composition-api';
import BaseTableAmountsPerCategory from '@/modules/data/components/base/base-table-amounts-per-category.vue';
import { comparatorAmount } from '@/helpers';
import BaseChart from '@/modules/data/chart/base-chart.vue';
import { Options } from 'highcharts';
import { getConfigChart } from '@/modules/data/chart/base-config-chart';
import { i18n } from '@/i18n';
import { merge } from 'lodash-es';
import BaseAmount from '@/modules/app/components/base/base-amount.vue';
import Big from 'big.js';

export default defineComponent({
  name: 'BaseDetailsMonth',
  components: { BaseAmount, BaseChart, BaseTableAmountsPerCategory },
  props: {
    month: {
      required: false,
      type: Object,
      default: null,
    },
    disableAnimation: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const chart1 = ref(null);
    const chart2 = ref(null);

    const blackListIncome = ref<Record<string, boolean>>({});
    const blackListOutcome = ref<Record<string, boolean>>({});

    const categoriesIncome = computed(() => props.month.data.filter((data) => data.category.income === true).sort((data1, data2) => comparatorAmount(data1.amount, data2.amount)));
    const categoriesOutcome = computed(() => props.month.data.filter((data) => data.category.income === false).sort((data1, data2) => comparatorAmount(data1.amount, data2.amount)).reverse());

    const categoriesIncomeFiltered = computed(() => categoriesIncome.value.filter((data) => blackListIncome.value[data.category.id] !== true));
    const categoriesOutcomeFiltered = computed(() => categoriesOutcome.value.filter((data) => blackListOutcome.value[data.category.id] !== true));

    const amountTotalIncome = computed(() => categoriesIncomeFiltered.value.reduce((sum, category) => sum.plus(category.amount), new Big(0)));
    const amountTotalOutcome = computed(() => categoriesOutcomeFiltered.value.reduce((sum, category) => sum.plus(category.amount.mul(-1)), new Big(0)));

    const dataIncome = computed(() => categoriesIncomeFiltered.value.map((data) => ({
      id: data.category.id, name: data.category.title, y: data.amount.toNumber(), color: data.category.color,
    })));

    const dataOutcome = computed(() => categoriesOutcomeFiltered.value.map((data) => ({
      id: data.category.id, name: data.category.title, y: data.amount.mul(-1).toNumber(), color: data.category.color,
    })));

    const options: Options = {
      tooltip: {
        pointFormat: '{point.y} €: <b>{point.percentage:.2f} %</b>',
      },
    };

    if (props.disableAnimation) {
      options.plotOptions = {
        series: {
          animation: false,
        },
      };
      options.chart = {
        animation: false,
      };
    }

    const optionsIncomeGeneral = getConfigChart(merge(
      options,
      {
        title: {
          text: i18n.t('app.income'),
        },
      },
    ));

    const optionsOutcomeGeneral = getConfigChart(merge(
      options,
      {
        title: {
          text: i18n.t('app.outcome'),
        },
      },
    ));

    const optionsIncome = computed<Options>(() => ({
      ...optionsIncomeGeneral,
      series: [
        {
          type: 'pie',
          data: dataIncome.value,
        },
      ],
    }));

    const optionsOutcome = computed<Options>(() => ({
      ...optionsOutcomeGeneral,
      series: [
        {
          type: 'pie',
          data: dataOutcome.value,
        },
      ],
    }));

    return {
      categoriesIncome,
      categoriesOutcome,
      optionsIncome,
      optionsOutcome,
      amountTotalIncome,
      amountTotalOutcome,
      updateBlacklist(event, type: 'income' | 'outcome') {
        switch (type) {
          case 'income':
            blackListIncome.value = event;
            break;
          case 'outcome':
            blackListOutcome.value = event;
            break;
        }
      },
      chart1,
      chart2,
      redraw() {
        chart1.value.redraw();
        chart2.value.redraw();
      },
    };
  },
});
