import router from '@/router';
import { store } from '@/modules/app/app.store';
import { queryInitial } from '@/modules/app/app.graphql';
import { apolloClient } from '@/vue-apollo';
import { Person } from '@/modules/person/person.model';
import { Account } from '@/modules/account/account.model';
import { Category } from '@/modules/category/category.model';
import { useUserSettings } from '@/modules/app/composables/useUserSettings';
import { useGroupsCategory } from '@/modules/category/composables/useGroupCategories';
import { useImportSetting } from '@/modules/import/composables/useImportSetting';
import { queryImportSettings } from '../import/import.graphql';

export class ServiceApp {
  static async initialize() {
    try {

      const [response, responseImportSettings] = await Promise.all([
        apolloClient.query({
          query: queryInitial,
        }),
        // Loading Import Settings
        apolloClient.query({
          query: queryImportSettings,
        })
      ]);

      useUserSettings().setSettings(JSON.parse(response.data.user.settings));

      await store.dispatch('moduleCategory/setCategories', {
        categories: response.data.categories.map((category: {}) => new Category(category)),
      });

      const { initialize } = useImportSetting();

      initialize(responseImportSettings.data.importSettings).then((data) => {
        store.commit('moduleImport/setImportSettings', {
          importSettings: data,
        })
      });

      useGroupsCategory().setGroupsCategory(response.data.groupCategories);

      await store.dispatch('modulePerson/setPersons', {
        persons: response.data.persons.map((person: {}) => new Person(person)),
      });

      await store.dispatch('moduleAccount/setAccounts', {
        accounts: response.data.accounts.map((account: {}) => new Account(account)),
      });


      await store.dispatch('setState', {
        nameState: 'isAuthenticated',
        objectState: true,
      });

      if (router.currentRoute.name === 'login') {
        router.push({
          name: 'home',
        });
      }
    } catch (e) {}

    await store.dispatch('setState', {
      nameState: 'isInitialized',
      objectState: true,
    });
  }

  static async login({ username, password }: { username: string; password: string }) {
    const formData = new FormData();

    formData.append('username', username);
    formData.append('password', password);

    const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/login`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      if (response.status === 401) {
        return false;
      }
      throw `Received ${response.status}`;
    }

    await ServiceApp.initialize();

    return true;
  }

  static async logout() {
    const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/logout`, {
      method: 'GET',
    });

    if (!response.ok) {
      return false;
    }

    router.push({
      name: 'login',
    }).then();

    return true;
  }
}
