import { computed } from '@vue/composition-api';
import { store } from '@/modules/app/app.store';

const dataIncomeOutcomePerMonthAll = computed(() => store.state.moduleData.dataIncomeOutcomePerMonth);
const dataIncomeOutcomePerMonthFiltered = computed(() => store.state.moduleData.dataIncomeOutcomePerMonthFiltered);

const dataIncomeOutcomePerMonth = computed(() => {
  if (dataIncomeOutcomePerMonthFiltered.value !== null) {
    return dataIncomeOutcomePerMonthFiltered.value;
  }

  return dataIncomeOutcomePerMonthAll.value;
});

const getMonth = (offset: number, data = dataIncomeOutcomePerMonth) => computed(() => {
  if (data.value === null) {
    return null;
  }

  if (data.value.length === 0) {
    return undefined;
  }

  return data.value[data.value.length - offset];
});

export const ServiceChartData = {

  // currentMonth: getMonth(1),
  // lastMonth: getMonth(2),
  currentMonthAll: getMonth(1, dataIncomeOutcomePerMonthAll),
  lastMonthAll: getMonth(2, dataIncomeOutcomePerMonthAll),

  getMonth: (timestamp) => dataIncomeOutcomePerMonth.value.find((data) => data.dateGroupedBy.getTime() === timestamp),
};
