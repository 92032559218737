





















import { defineComponent, ref, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useImport } from '@/modules/import/import.service';
import { store } from '@/modules/app/app.store';

export default defineComponent({
  name: 'Import',
  setup() {
    const file = ref<File>();

    watch(store.state.moduleImport, (value) => {
      if (value.importData === null) {
        file.value = undefined;
      }
    });

    return {
      vuelidate: useVuelidate({
        file: {
          required,
        },
      }, { file }),
      importData: () => {
        useImport.import({
          file: file.value,
        });
      },
      file,
    };
  },
});
