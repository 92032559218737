import { Person } from '@/modules/person/person.model';
import { store } from '@/modules/app/app.store';
import { apolloClient } from '@/vue-apollo';
import {
  mutatePersonCreate,
  mutatePersonDelete,
  mutatePersonEdit,
} from '@/modules/person/person.graphql';

export class ServicePerson {
  static async create({ person: personPassed }: { person: Person}): Promise<Person> {
    console.log('creating person', personPassed, JSON.stringify(personPassed));

    const response = await apolloClient.mutate({
      mutation: mutatePersonCreate,
      variables: {
        person: personPassed,
      },
    });

    const person = new Person(response.data.personCreate.person);

    await store.dispatch('modulePerson/addPerson', {
      person,
    });

    console.warn('response', response);

    return person;
  }

  static async edit({ person }: { person: Person}) {
    console.log('editing person', person, JSON.stringify(person));

    const response = await apolloClient.mutate({
      mutation: mutatePersonEdit,
      variables: {
        person,
      },
    });

    await store.dispatch('modulePerson/editPerson', {
      person,
    });

    console.warn('response', response);
  }

  static async delete({ person }: { person: Person}) {
    console.log('deleting person', person, JSON.stringify(person));

    const response = await apolloClient.mutate({
      mutation: mutatePersonDelete,
      variables: {
        idPerson: person.id,
      },
    });

    await store.dispatch('modulePerson/removePerson', {
      person,
    });

    console.warn('response', response);
  }
}
