<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <table-categories />
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <table-category-groups />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import TableCategories from '@/modules/category/components/category/table-categories';
import TableCategoryGroups from '@/modules/category/components/groupCategory/table-groups-category';

export default {
  name: 'ViewCategories',
  components: { TableCategoryGroups, TableCategories },
};
</script>

<style scoped>

</style>
