export class Category {
  id?: number;

  title?: string;

  color?: string;

  income?: boolean;

  constructor({
    id,
    title,
    color,
    income,
  }: {
    id?: number;
    title?: string;
    color?: string;
    income?: boolean;
  } = {}) {
    this.id = id;
    this.title = title;
    this.color = color;
    this.income = income;
  }
}
