var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groupsCategorySorted,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$tc('groupCategory.title', 2)))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('create-group-category')],1)]},proxy:true},{key:"item.categories",fn:function(ref){
var value = ref.value;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._v(" "+_vm._s(value.length)+" "),_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa fa-search fa-fw ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},_vm._l((value),function(category){return _c('v-list-item',{key:category.id},[_c('base-category-name',{attrs:{"options-chip":{},"category":category}})],1)}),1)],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.id >= 0)?_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.openEditItem(item)}}},[_vm._v(" fas fa-edit ")])],1),_c('v-col',[_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.openDeleteItem(item)}}},[_vm._v(" fas fa-trash ")])],1)],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('category.edit'))+" ")]),_c('v-form',[_c('v-card-text',[_c('v-container',{staticClass:"py-0"},[_c('item-group-category',{ref:"item-category",model:{value:(_vm.itemCurrent),callback:function ($$v) {_vm.itemCurrent=$$v},expression:"itemCurrent"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogEdit = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.editItem}},[_vm._v(" "+_vm._s(_vm.$t('app.save'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('category.delete'))+" ")]),_c('v-form',[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('category.deleteConfirmation', {name:_vm.itemCurrent.title}))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t('app.delete'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }