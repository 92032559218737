import { Transaction } from '@/modules/transactions/transaction.model';

export const moduleTransaction = {
  namespaced: true,
  state: {
    transactions: [],
    countTotalTransactions: 0,

    lastInputs: {},
  },
  mutations: {
    setTransactions(state, { transactions }: {transactions: Transaction[]}) {
      state.transactions = transactions;
    },
    setCountTotalTransactions(state, { count }: {count: number}) {
      state.countTotalTransactions = count;
    },
    setLastInputs(state, { lastInputs }: {lastInputs: {}}) {
      state.lastInputs = lastInputs;
    },
    // addAccount(state, { account }: {account: Account}) {
    //   if (account.id !== undefined) {
    //     Vue.set(state.accounts, account.id, account);
    //   }
    // },
    // editAccount(state, { account }: {account: Account}) {
    //   if (account.id !== undefined) {
    //     Vue.set(state.accounts, account.id, account);
    //   }
    // },
    // removeAccount(state, { account }: {account: Account}) {
    //   Vue.delete(state.accounts, account.id);
    // },
  },
  actions: {
    async setTransactions({ commit }, { transactions }: {transactions: Transaction[]}) {
      commit('setTransactions', {
        transactions,
      });
    },
    async setCountTotalTransactions({ commit }, { count }: {count: number}) {
      commit('setCountTotalTransactions', {
        count,
      });
    },
    async setLastInputs({ commit }, { lastInputs }: {lastInputs: {}}) {
      commit('setLastInputs', {
        lastInputs,
      });
    },
    // async addAccount({ commit }, { account }: {account: Account}) {
    //   commit('addAccount', {
    //     account,
    //   });
    // },
    // async editAccount({ commit }, { account }: {account: Account}) {
    //   commit('editAccount', {
    //     account,
    //   });
    // },
    // async removeAccount({ commit }, { account }: {account: Account}) {
    //   commit('removeAccount', {
    //     account,
    //   });
    // },
  },
};
