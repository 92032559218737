




































export default {
  name: 'BaseInputColor',
  props: {
    value: {
      type: String,
      required: false,
      default() {
        this.$emit('input', '#009fe3');
        return '#009fe3';
      },
    },
    label: {
      type: String,
      required: false,
      default() {
        return this.$t('app.color');
      },
    },
  },
  data() {
    return {
      menu: false,
      color: this.value,
    };
  },
  created() {
    this.$emit('input', this.color);
  },
};
