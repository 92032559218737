














































import { defineComponent, ref } from '@vue/composition-api';
import { useImport } from '@/modules/import/import.service';

export default defineComponent({
  name: 'DeleteImportSettings',
  props: {
    importSetting: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const dialogDelete = ref(false);
    return {
      dialogDelete,
      deleteItem: async () => {
        await useImport.deleteSetting(props.importSetting);
        dialogDelete.value = false;
      },
    };
  },
});
