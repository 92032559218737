<template>
  <v-data-iterator
    dense
    :headers="headers"
    :items="transactionsGrouped"
    :loading="isLoading"
    disable-pagination
    hide-default-footer
    :server-items-length="$store.state.moduleTransaction.countTotalTransactions"
  >
    <!--    :page="page"-->
    <!--    :sort-by="sortBy"-->
    <!--    :sort-desc="sortDesc"-->
    <template #header>
      <v-toolbar
        dense
        elevation="2"
      >
        <table-transactions-filter v-bind.sync="optionsFilter" />
        <v-divider
          vertical
          class="mx-1 mx-md-3"
        />
        <sort-transactions
          v-bind.sync="optionsSort"
        />
        <v-spacer />
        <v-checkbox
          v-model="groupByDate"
          class="mr-3"
          :label="$t('transaction.groupByDate')"
          hide-details
        />
        <create-transaction @created="loadPage" />
        <!--        <v-toolbar-title>{{ $tc('transaction.title', 2) }}</v-toolbar-title>-->
        <!--        <v-divider-->
        <!--          class="mx-4"-->
        <!--          inset-->
        <!--          vertical-->
        <!--        />-->
        <!--        <sort-transactions />-->
        <!--        <v-spacer></v-spacer>-->
        <!--        <create-transaction />-->
      </v-toolbar>
    </template>

    <template #default="{ items }">
      <v-row dense>
        <v-col cols="12">
          <v-row
            v-for="(month, indexMonth) in items"
            :key="indexMonth"
            dense
          >
            <v-col
              cols="12"
              :class="{
                'text-h4': true,
                'mt-5': indexMonth !== 0,
              }"
            >
              {{ displayMonth(month) }}
            </v-col>
            <v-col cols="12">
              <v-row
                v-if="groupByDate === false"
                dense
              >
                <template>
                  <template
                    v-for="(day, indexDay) in month"
                  >
                    <template v-for="(transaction, index) in day">
                      <transaction
                        :key="`${indexDay}_${transaction.id}`"
                        v-intersect="transactions.length - transaction.index === 50 && intersected"
                        :transaction="transaction"
                        @update="loadPage"
                        @delete="loadPage"
                      />
                    </template>
                  </template>
                </template>
              </v-row>
              <template v-else>
                <v-row
                  v-for="(day, indexDay) in month"
                  :key="indexDay"
                  dense
                >
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col class="shrink mr-3">
                        <base-date :value="day[0].date" />
                      </v-col>
                      <v-col>
                        <v-row
                          no-gutters
                          align="center"
                          style="height: 100%"
                        >
                          <v-col>
                            <v-divider />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!--                <v-divider></v-divider>-->
                  <template v-for="(transaction, index) in day">
                    <transaction
                      :key="transaction.id"
                      v-intersect="transactions.length - transaction.index === 50 && intersected"
                      :transaction="transaction"
                      @update="loadPage"
                      @delete="loadPage"
                    />
                  </template>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template #footer>
      <v-row v-if="isLoading === true">
        <v-col class="text-center">
          <v-progress-circular indeterminate />
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import CreateTransaction from '@/modules/transactions/components/create-transaction';
import { ServiceTransactions } from '@/modules/transactions/transactions.service';
import Transaction from '@/modules/transactions/components/list-transactions/transaction';
import SortTransactions
  from '@/modules/transactions/components/list-transactions/sort-transactions';
import TableTransactionsFilter
  from '@/modules/transactions/components/list-transactions/table-transactions-filter';
import {
  isSameMonth, isSameDay, format,
} from 'date-fns';
import BaseDate from '@/modules/app/components/base/base-date';
import { useUserSettings } from '@/modules/app/composables/useUserSettings';
import { ServiceTitle } from '@/modules/title/title.service';

const keyUserSettingsFiltersTransactions = 'filters-transactions';

export default {
  name: 'TableTransactions',
  components: {
    BaseDate,
    TableTransactionsFilter,
    SortTransactions,
    Transaction,
    CreateTransaction,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('transaction.item.title'),
          value: 'title',
          class: ['text-no-wrap'],
        },
        {
          value: 'action',
          sortable: false,
          align: 'right',
          width: '1px',
        },
      ],
      optionsSort: {
        sortBy: ['date'],
        sortDesc: [true],

      },
      optionsFilter: {
        category: null,
        account: null,
        title: null,
      },
      page: 1,
      isLoading: false,
      groupByDate: true,
    };
  },
  computed: {
    transactionsGrouped() {
      if (this.transactions.length === 0) return [];
      const start = performance.now();
      // console.warn('optionsSort', this.optionsSort.sortBy);
      const groups = [];
      let groupMonth = [];
      let groupDay = [];
      let datePrevious = this.transactions[0].date;
      // console.warn(' this.transactions',  this.transactions.map(t => t.date));

      for (let i = 0; i < this.transactions.length; i += 1) {
        const transaction = this.transactions[i];
        transaction.index = i;
        // console.warn('transaction.date', transaction.date);

        if (isSameDay(datePrevious, transaction.date)) {
          // console.log('is same day');
          groupDay.push(transaction);
        } else {
          // console.log('is not same day');
          groupMonth.push(groupDay);

          if (!isSameMonth(datePrevious, transaction.date)) {
            // console.log('is not same month');
            groups.push(groupMonth);
            groupMonth = [];
          } else {

          }
          groupDay = [transaction];
        }

        datePrevious = transaction.date;
      }

      groupMonth.push(groupDay);
      groups.push(groupMonth);

      // console.warn('groups', groups);

      console.log(performance.now() - start);
      return groups;
      // return this.transactions;
    },
    transactions() {
      return this.$store.state.moduleTransaction.transactions;
    },
  },
  watch: {
    optionsSort: {
      handler() {
        this.loadPage();
      },
      deep: true,
    },
    optionsFilter: {
      handler() {
        const { setSetting } = useUserSettings();

        setSetting(keyUserSettingsFiltersTransactions, {
          category: this.optionsFilter.category?.id,
          account: this.optionsFilter.account?.id,
          title: this.optionsFilter.title?.id,
        });

        this.loadPage();
      },
      deep: true,
    },
    '$route.query': {
      handler() {
        this.readFiltersFromUrl();

        this.loadPage();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getFiltersFromSettingsUser();

    this.readFiltersFromUrl();

    this.loadPage();
  },
  methods: {
    async getFiltersFromSettingsUser() {
      const { getSetting } = useUserSettings();
      const settingsUserFiltersTransactions = getSetting(keyUserSettingsFiltersTransactions);

      if (settingsUserFiltersTransactions.value === undefined) return;

      if (settingsUserFiltersTransactions.value.category !== undefined) {
        this.optionsFilter.category = this.$store.state.moduleCategory.categories[
          settingsUserFiltersTransactions.value.category
        ];
      }

      if (settingsUserFiltersTransactions.value.account !== undefined) {
        this.optionsFilter.account = this.$store.state.moduleAccount.accounts[
          settingsUserFiltersTransactions.value.account
        ];
      }

      if (settingsUserFiltersTransactions.value.title !== undefined) {
        this.optionsFilter.title = (await ServiceTitle.load({ id: settingsUserFiltersTransactions.value.title }));
      }
    },
    displayMonth(month) {
      return format(month[0][0].date, 'MMMM');
    },
    readFiltersFromUrl() {
      if (this.$route.query.filters__category !== undefined) {
        this.optionsFilter.category = this.$store.state.moduleCategory.categories[this.$route.query.filters__category];
      }
      if (this.$route.query.filters__account !== undefined) {
        this.optionsFilter.account = this.$store.state.moduleAccount.accounts[this.$route.query.filters__account];
      }
    },
    intersected(entries, observer, isIntersecting) {
      if (
        isIntersecting
        && this.isLoading === false
        && this.transactions.length < this.$store.state.moduleTransaction.countTotalTransactions
      ) {
        this.page += 1;
        this.loadPage({
          initialize: false,
        });
      }
    },
    async loadPage({ initialize = true } = {}) {
      this.isLoading = true;

      if (initialize === true) {
        this.page = 1;
      }

      await ServiceTransactions.loadPage({
        page: this.page,
        initialize,
        // filters: this.filters,
        sortBy: this.optionsSort.sortBy,
        sortDesc: this.optionsSort.sortDesc,
        optionsFilter: this.optionsFilter,
      });

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>

</style>
