import { Title } from '@/modules/title/title.model';

export const moduleTitle = {
  namespaced: true,
  state: {
    titles: [],
    countTotalTitles: 0,
  },
  mutations: {
    setTitles(state, { titles }: {titles: Title[]}) {
      state.titles = titles;
    },
    setCountTotalTitles(state, { count }: {count: number}) {
      state.countTotalTitles = count;
    },
  },
  actions: {
  },
};
