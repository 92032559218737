






































import { defineComponent } from '@vue/composition-api';
import BaseDatePicker from '@/modules/app/components/base/base-date-picker.vue';
import CreatePerson from '@/modules/person/components/create-person';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category';
import BaseSelectAccount from '@/modules/account/components/base-select-account';
import BaseSelectTitle from '@/modules/title/components/base/base-select-title.vue';
import { ImportSetting } from '../../import-setting.model';

export default defineComponent({
  name: 'ItemImportSetting',
  components: {
    BaseSelectTitle,
    BaseSelectAccount,
    BaseSelectCategory,
    CreatePerson,
    BaseDatePicker,
  },
  props: {
    value: {
      type: ImportSetting,
      required: true,
    },
  },
  setup(props) {
    const focus = () => {
      console.warn('FOCUS');
      // this.$refs.title.focus();
    };
    return { focus };
  },
});
