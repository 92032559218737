import gql from "graphql-tag";
import {fragments} from "@/modules/app/app.fragments";

export const mutatePersonCreate = gql`mutation personCreate($person: PersonInput!) {
  personCreate(person: $person) {
    person {
      ...person
    }
  }
}
${fragments.person}
`;

export const mutatePersonEdit = gql`mutation personEdit($person: PersonInput!) {
  personEdit(person: $person) {
    person {
    ...person
    }
  }
}
${fragments.person}
`;

export const mutatePersonDelete = gql`mutation personDelete($idPerson: ID!) {
  personDelete(idPerson: $idPerson) {
    success
  }
}`;
