











import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import { ServiceData } from '@/modules/data/data.service';
import { Options } from 'highcharts';
import { formatterTooltip, getConfigChart } from '@/modules/data/chart/base-config-chart';
import BaseChart from '@/modules/data/chart/base-chart.vue';
import { useDataBudget } from '@/modules/data/composables/useDataBudget';
import { displayMoney } from '@/helpers';
import { i18n } from '@/i18n';

export default defineComponent({
  name: 'CapitalTotal',
  components: {
    BaseChart,
  },
  setup(props, { emit }) {
    ServiceData.loadStatisticsAccountsOverTime();
    ServiceData.loadStatisticsInvestmentssOverTime();
    ServiceData.loadStatisticsInvestmentsStockOverTime();
    ServiceData.loadStatisticsSharesProfit();

    const {
      capitalTotalPerMonth,
      investmentsP2PPerMonth,
      capitalAndInvestmentsPerMonth,
      interestPerMonth,
      investmentsStockPerMonth,
      sharesProfit,
    } = useDataBudget();

    const currentMonth = ref<Record<string, unknown> | null>(null);
    const chart = ref(null);

    const updateTitle = () => {
      let result = `${i18n.t('data.capitalTotal')}: `;

      if (capitalAndInvestmentsPerMonth.value !== null) {
        result += displayMoney({ value: capitalAndInvestmentsPerMonth.value[capitalAndInvestmentsPerMonth.value.length - 1][1].toFixed(2) });
      }

      if (sharesProfit.value !== null) {
        result += sharesProfit.value.gte(0) ? '&nbsp;<small class="success--text">' : '&nbsp;<small class="error--text">';
        result += `(${displayMoney({ value: sharesProfit.value.toFixed(2) })})</small>`;
      }

      emit('update-title', result);
    };

    watch(capitalAndInvestmentsPerMonth, () => {
      updateTitle();
    });

    watch(sharesProfit, () => {
      updateTitle();
    });

    const options = computed<Options>(() => getConfigChart({
      chart: {
        height: 500,
      },
      xAxis: {
        type: 'datetime',
        endOnTick: true,
      },
      yAxis: {
        min: 0,
      },
      legend: {
        enabled: true,
      },
      series: [
        {
          type: 'line',
          name: 'Total',
          data: capitalAndInvestmentsPerMonth.value,
        },
        {
          type: 'line',
          name: 'Kapital',
          data: capitalTotalPerMonth.value,
          visible: false,
        },
        {
          type: 'line',
          name: 'P2P-Investments',
          data: investmentsP2PPerMonth.value,
          visible: false,
        },
        {
          type: 'line',
          name: 'P2P-Interest',
          data: interestPerMonth.value,
          visible: false,
        },
        {
          type: 'line',
          name: 'Stock-Investments',
          data: investmentsStockPerMonth.value,
          visible: false,
        },
        // {
        //   type: 'line',
        //   name: 'Stock-Interest',
        //   data: interestPerMonth.value,
        //   visible: false,
        // },
      ],
      tooltip: {
        formatter: formatterTooltip,
      },
    }));

    return {
      options,
      currentMonth,
      chart,
      redraw() {
        chart.value.redraw();
      },
    };
  },
});
