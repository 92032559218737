<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col>
          <table-transactions />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import TableTransactions from '@/modules/transactions/components/list-transactions/table-transactions';

export default {
  name: 'ViewTransactions',
  components: { TableTransactions },
};
</script>

<style scoped>

</style>
