<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col>
          <v-card>
            <table-accounts />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import TableAccounts from '../modules/account/components/table-accounts';

export default {
  name: 'ViewAccounts',
  components: { TableAccounts },
};
</script>

<style scoped>

</style>
