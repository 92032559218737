var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"value":_vm.value,"items":_vm.categories,"label":_vm.hideLabel ? null : _vm.label,"item-value":"id","item-text":"title","return-object":"","prepend-icon":_vm.hideIconPreprended ? null : 'fas fa-list',"dense":"","multiple":_vm.multiple,"single-line":_vm.singleLine,"hide-details":"","clearable":_vm.clearable},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [(_vm.multiple)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":attrs.inputValue,"color":"primary"}})],1):_vm._e(),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('v-chip',{attrs:{"color":item.color,"dark":""}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1),_c('v-list-item-content',{staticClass:"py-0 text-right"},[_c('v-list-item-title',[(item.income === true)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('app.income'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('app.outcome'))+" ")])])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('base-category-name',{attrs:{"category":item,"clickable":false}}):_vm._e(),(index === 1)?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('category.multipleSelected', {count: _vm.value.length - 1}))+" ")]):_vm._e()]}},(_vm.displayAdd === true)?{key:"append-outer",fn:function(){return [_c('create-category',{on:{"created":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',[_vm._v("fas fa-plus")])],1)]}}],null,false,826661549)})]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }