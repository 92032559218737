

























































import { Transfer } from '@/modules/transfer/transfer.model';
import ItemTransfer from '@/modules/transfer/components/item-transfer.vue';
import { ServiceTransfers } from '@/modules/transfer/transfer.service';
import BaseButtonCancel from '@/modules/app/components/base/base-button-cancel.vue';
import BaseButtonSaveAndNew from '@/modules/app/components/base/base-button-save-and-new.vue';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import { not, required, sameAs } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import { store } from '@/modules/app/app.store';
import { i18n } from '@/i18n';

export default defineComponent({
  name: 'CreateTransfer',
  components: {
    BaseButtonSave, BaseButtonSaveAndNew, BaseButtonCancel, ItemTransfer,
  },
  setup(props, { emit }) {
    const transfer = ref<Transfer>(new Transfer({ title: i18n.tc('transfer.title') }));
    const dialog = ref(false);
    const isLoading = ref(false);
    const refItemTransfer = ref(null);

    const accounts = computed(() => Object.values(store.state.moduleAccount.accounts));
    const persons = computed(() => Object.values(store.state.modulePerson.persons));

    const prepareTransfer = () => {
      const { lastInputs } = store.state.moduleTransfer;
      // Date
      if (lastInputs.date !== undefined) {
        transfer.value.date = lastInputs.date;
      }
      // Account
      if (lastInputs.accountSource !== undefined) {
        transfer.value.accountSource = lastInputs.accountSource;
      } else if (accounts.value.length === 1) {
        transfer.value.accountSource = accounts.value[0];
      }
      if (lastInputs.accountDestination !== undefined) {
        transfer.value.accountDestination = lastInputs.accountDestination;
      } else if (accounts.value.length === 1) {
        transfer.value.accountDestination = accounts.value[0];
      }
      // Person
      if (lastInputs.person !== undefined) {
        transfer.value.person = lastInputs.person;
      } else if (persons.value.length === 1) {
        transfer.value.person = persons.value[0];
      }
    };

    const reset = ({ addNew = false } = {}) => {
      transfer.value = new Transfer({ title: i18n.tc('transfer.title') });

      if (addNew === false) {
        dialog.value = false;
      } else {
        prepareTransfer();
      }

      refItemTransfer.value.focus();
    };

    const save = async ({ addNew = false } = {}) => {
      isLoading.value = true;

      const transferNew = await ServiceTransfers.create({
        transfer: transfer.value,
      });

      updateLastInputs();

      reset({ addNew });

      emit('created', transferNew);

      isLoading.value = false;
    };
    const updateLastInputs = async () => {
      await store.dispatch('moduleTransfer/setLastInputs', {
        lastInputs: {
          date: transfer.value.date,
          accountSource: transfer.value.accountSource,
          accountDestination: transfer.value.accountDestination,
          person: transfer.value.person,
        },
      });
    };

    watch(dialog, () => {
      if (dialog.value === true) {
        prepareTransfer();
      }
    });

    return {
      vuelidate: useVuelidate({
        title: {
          required,
        },
        amount: {
          required,
        },
        date: {
          required,
        },
        person: {
          required,
        },
        accountSource: {
          notSame: not(sameAs('accountDestination')),
          required,
        },
        accountDestination: {
          required,
        },
      }, transfer, { $stopPropagation: true }),
      accounts,
      persons,
      refItemTransfer,
      transfer,
      save,
      reset,
      dialog,
    };
  },
});
