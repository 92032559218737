var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:_vm.classes},[_c('td',[_c('base-date',{attrs:{"value":_vm.transaction.date}})],1),_c('td',[_c('base-amount',{class:{
        'success--text': _vm.transaction.category.income === true,
        'error--text': _vm.transaction.category.income === false
      },attrs:{"value":_vm.transaction.amount}})],1),_c('td',[_c('base-select-title',{attrs:{"hide-icon-preprended":"","clearable":false,"single-line":""},model:{value:(_vm.transaction.title),callback:function ($$v) {_vm.$set(_vm.transaction, "title", $$v)},expression:"transaction.title"}})],1),_c('td',[_c('base-select-category',{attrs:{"hide-icon-preprended":"","clearable":false,"single-line":"","show-meta-categories":""},model:{value:(_vm.transaction.category),callback:function ($$v) {_vm.$set(_vm.transaction, "category", $$v)},expression:"transaction.category"}})],1),_c('td',[(_vm.amazonOrder)?_c('a',{attrs:{"href":("https://www.amazon.de/gp/your-account/order-details?orderID=" + _vm.amazonOrder),"target":"_blank"}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" fab fa-amazon ")])],1):_vm._e()]),_c('td',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-dialog',{attrs:{"max-width":"1000","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" fas fa-search ")])]}}]),model:{value:(_vm.infoOpen),callback:function ($$v) {_vm.infoOpen=$$v},expression:"infoOpen"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('transaction.item.title')))]),_c('td',[_vm._v(_vm._s(_vm.transaction.title ? _vm.transaction.title.text : null))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('transaction.item.iban')))]),_c('td',[_vm._v(_vm._s(_vm.transaction.accountNumber))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('transaction.item.comment')))]),_c('td',[_vm._v(_vm._s(_vm.transaction.comment))])])])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.infoOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.close'))+" ")])],1)],1)],1)],1),_c('v-col',[_c('v-dialog',{attrs:{"max-width":"1000","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"error"}},on),[_vm._v(" fas fa-trash ")])]}}]),model:{value:(_vm.deleteOpen),callback:function ($$v) {_vm.deleteOpen=$$v},expression:"deleteOpen"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('import.deleteConfirmationItem'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.close'))+" ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(_vm.transaction)}}},[_vm._v(" "+_vm._s(_vm.$t('app.delete'))+" ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }