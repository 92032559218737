import { Account } from '@/modules/account/account.model';
import { store } from '@/modules/app/app.store';
import { apolloClient } from '@/vue-apollo';
import {
  mutateAccountCreate,
  mutateAccountDelete,
  mutateAccountEdit, queryAccountsWithAmounts,
} from '@/modules/account/account.graphql';
import format from 'date-fns/format';

export class ServiceAccount {
  static async create({ account: accountPassed }: { account: Account}): Promise<Account> {
    console.log('creating account', accountPassed, JSON.stringify(accountPassed));

    const response = await apolloClient.mutate({
      mutation: mutateAccountCreate,
      variables: {
        account: {
          id: accountPassed.id,
          title: accountPassed.title,
          amountInitial: accountPassed.amountInitial,
        },
      },
    });

    const account = new Account(response.data.accountCreate.account);

    await store.dispatch('moduleAccount/addAccount', {
      account,
    });

    console.warn('response', response);

    return account;
  }

  static async edit({ account }: { account: Account}) {
    console.log('editing account', account, JSON.stringify(account));

    const response = await apolloClient.mutate({
      mutation: mutateAccountEdit,
      variables: {
        account: {
          id: account.id,
          title: account.title,
          amountInitial: account.amountInitial,
          iban: account.iban,
        },
      },
    });

    await store.dispatch('moduleAccount/editAccount', {
      account: new Account(response.data.accountEdit.account),
    });

    console.warn('response', response);
  }

  static async delete({ account }: { account: Account}) {
    console.log('deleting account', account, JSON.stringify(account));

    const response = await apolloClient.mutate({
      mutation: mutateAccountDelete,
      variables: {
        idAccount: account.id,
      },
    });

    await store.dispatch('moduleAccount/removeAccount', {
      account,
    });

    console.warn('response', response);
  }

  static async loadAmounts({ date }: {date?: Date} = {}) {
    const response = await apolloClient.query({
      query: queryAccountsWithAmounts,
      fetchPolicy: 'no-cache',
      variables: {
        date: date === undefined ? undefined : format(date, 'yyyy-MM-dd'),
      },
    });

    await store.dispatch('moduleAccount/setAmounts', {
      accounts: response.data.accountsWithAmounts,
    });
  }
}
