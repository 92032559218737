import gql from 'graphql-tag';
import { fragments } from '@/modules/app/app.fragments';

export const mutateCategoryCreate = gql`mutation categoryCreate($category: CategoryInput!) {
  categoryCreate(category: $category) {
    category {
      ...category
    }
  }
}
${fragments.category}
`;

export const mutateCategoryEdit = gql`mutation categoryEdit($category: CategoryInput!) {
  categoryEdit(category: $category) {
    category {
    ...category
    }
  }
}
${fragments.category}
`;

export const mutateCategoryDelete = gql`mutation categoryDelete($idCategory: ID!) {
  categoryDelete(idCategory: $idCategory) {
    success
  }
}`;

export const mutateGroupCategoryCreate = gql`mutation groupCategoryCreate($groupCategory: GroupCategoryInput!) {
  groupCategoryCreate(groupCategory: $groupCategory) {
    groupCategory {
      ...groupCategory
    }
  }
}
${fragments.groupCategory}
`;

export const mutateGroupCategoryEdit = gql`mutation groupCategoryEdit($groupCategory: GroupCategoryInput!) {
  groupCategoryEdit(groupCategory: $groupCategory) {
    groupCategory {
    ...groupCategory
    }
  }
}
${fragments.groupCategory}
`;

export const mutateGroupCategoryDelete = gql`mutation groupCategoryDelete($idGroupCategory: ID!) {
  groupCategoryDelete(idGroupCategory: $idGroupCategory) {
    success
  }
}`;
