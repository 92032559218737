import Vue from 'vue';
import VueRouter from 'vue-router';
import ViewHome from '@/views/home.view.vue';
import ViewLogin from '@/views/login.view.vue';
import ViewApp from '@/views/app.view.vue';
import ViewTransaction from '@/views/transactions.view';
import ViewTransfer from '@/views/transfers.view';
import ViewCategory from '@/modules/category/categories.view.vue';
import ViewPerson from '@/views/person.view';
import ViewAccounts from '@/views/account.view';
import ViewImport from '@/modules/import/components/import.view';
import ViewTitles from '@/modules/title/components/titles.view';
import { queue } from '@/queue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: ViewLogin,
  },
  {
    path: '/',
    component: ViewApp,
    children: [
      {
        path: '',
        name: 'home',
        component: ViewHome,
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: ViewTransaction,
      },
      {
        path: 'transfers',
        name: 'transfers',
        component: ViewTransfer,
      },
      {
        path: 'categories',
        name: 'categories',
        component: ViewCategory,
      },
      {
        path: 'persons',
        name: 'persons',
        component: ViewPerson,
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: ViewAccounts,
      },
      {
        path: 'import',
        name: 'import',
        component: ViewImport,
      },
      {
        path: 'titles',
        name: 'titles',
        component: ViewTitles,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

queue.listen('graphqlError', (error) => {
  if (error.graphQLErrors[0].message === 'PermissionDenied') {
    if (router.currentRoute.name !== 'login') {
      router.push({
        name: 'login',
      });
    }
  }
});

export default router;
