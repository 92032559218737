import {
  mutateTransferCreate, mutateTransferDelete, mutateTransferEdit,
  queryTransfersPage,
} from '@/modules/transfer/transfer.graphql';
import { store } from '@/modules/app/app.store';
import { apolloClient } from '@/vue-apollo';
import { Transfer } from '@/modules/transfer/transfer.model';

export class ServiceTransfers {
  static async loadPage({
    page, sortBy, sortDesc, initialize = false,
  }: { page: number; sortBy: string[]; sortDesc: boolean[]; initialize: boolean }): Promise<Transfer[]> {
    if (initialize) {
      await store.dispatch('moduleTransfer/setTransfers', {
        transfers: [],
      });
    }

    const response = await apolloClient.query({
      query: queryTransfersPage,
      variables: {
        page,
        count: 100,
        sortBy,
        sortDesc,
      },
      fetchPolicy: 'no-cache',
    });

    const transfers = await Promise.all(response.data.transfers.map((transfer: {}) => Transfer.parseFromServer(transfer)));

    await store.dispatch('moduleTransfer/setTransfers', {
      transfers: initialize ? transfers : [...store.state.moduleTransfer.transfers, ...transfers],
    });

    await store.dispatch('moduleTransfer/setCountTotalTransfers', {
      count: response.data.countTransfers,
    });

    return transfers;
  }

  static async create({ transfer: transferPassed }: { transfer: Transfer}): Promise<Transfer> {
    console.log('creating transfer', transferPassed, JSON.stringify(transferPassed));

    const response = await apolloClient.mutate({
      mutation: mutateTransferCreate,
      variables: {
        transfer: transferPassed.prepareForServer(),
      },
    });

    const transfer = await Transfer.parseFromServer(response.data.transferCreate.transfer);

    // await store.dispatch('moduleTransfer/addPerson', {
    //   transfer,
    // });

    console.warn('response', response);

    return transfer;
  }

  static async edit({ transfer: transferPassed }: { transfer: Transfer}) {
    console.log('editing transfer', transferPassed, JSON.stringify(transferPassed));

    const response = await apolloClient.mutate({
      mutation: mutateTransferEdit,
      variables: {
        transfer: transferPassed.prepareForServer(),
      },
    });

    const transfer = await Transfer.parseFromServer(response.data.transferEdit.transfer);

    console.warn('response', response);

    return transfer;
  }

  static async delete({ transfer }: { transfer: Transfer}) {
    console.log('deleting transfer', transfer, JSON.stringify(transfer));

    const response = await apolloClient.mutate({
      mutation: mutateTransferDelete,
      variables: {
        idTransfer: transfer.id,
      },
    });

    console.warn('response', response);
  }
}
