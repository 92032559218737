
























































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useImport } from '@/modules/import/import.service';
import useVuelidate, { ValidationRuleWithoutParams } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import BaseSelectTitle from '@/modules/title/components/base/base-select-title.vue';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category.vue';
import ItemImportSetting from '@/modules/import/components/table-import-settings/item-import-setting.vue';
import { ImportSetting } from '@/modules/import/import-setting.model';

export default defineComponent({
  name: 'CreateImportSettings',
  components: { ItemImportSetting },
  props: {
  },
  setup() {
    const dialog = ref(false);

    const newSetting = ref(new ImportSetting());

    const rules = computed<{
      iban: {} | {required: ValidationRuleWithoutParams};
      usage: {} | {required: ValidationRuleWithoutParams};
      title: {} | {required: ValidationRuleWithoutParams};
      category: {required: ValidationRuleWithoutParams};
    }>(() => ({
      iban: helpers.req(newSetting.value.usage) ? {} : { required },
      usage: helpers.req(newSetting.value.iban) ? {} : { required },
      title: { required },
      category: { required },
    }));

    const vuelidate = useVuelidate(rules, newSetting);

    const save = async () => {
      await useImport.addSetting(newSetting.value);

      dialog.value = false;

      newSetting.value = new ImportSetting();
    };

    return {
      dialog,
      newSetting,
      vuelidate,
      save,
    };
  },
});
