








import { defineComponent } from '@vue/composition-api';
import DataOverview from '@/modules/data/components/data-overview.vue';

export default defineComponent({
  name: 'Home',
  components: {
    DataOverview,
  },
});
