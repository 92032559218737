<template>
  <v-dialog v-model="isOpen">
    <template #activator="{ on }">
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        :color="color"
        v-on="on"
      >
        Filter
      </v-btn>
      <v-btn
        v-else
        icon
        small
        :color="color"
        v-on="on"
      >
        <v-icon>
          fas fa-filter
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Filter
        <v-spacer />
        <v-btn
          icon
          @click="isOpen = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <base-select-category
              :value="category"
              :display-add="false"
              :clearable="true"
              @input="$emit('update:category', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <base-select-account
              :value="account"
              :display-add="false"
              :clearable="true"
              @input="$emit('update:account', $event)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <base-select-title
              :value="title"
              :display-add="false"
              :clearable="true"
              @input="$emit('update:title', $event)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { comparatorCategories } from '@/helpers';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category';
import { Account } from '@/modules/account/account.model';
import { Category } from '@/modules/category/category.model';
import { Title } from '@/modules/title/title.model';
import BaseSelectAccount from '@/modules/account/components/base-select-account';
import BaseSelectTitle from '@/modules/title/components/base/base-select-title';

export default {
  name: 'TableTransactionsFilter',
  components: { BaseSelectAccount, BaseSelectCategory, BaseSelectTitle },
  props: {
    category: {
      required: false,
      type: Category,
      default: null,
    },
    account: {
      required: false,
      type: Account,
      default: null,
    },
    title: {
      required: false,
      type: Title,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    color() {
      if (this.category !== null || this.account !== null) {
        return 'warning';
      }

      return null;
    },
  },
};
</script>

<style scoped>

</style>
