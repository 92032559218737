











import { defineComponent, ref, watch } from '@vue/composition-api';
import BaseDetailsMonth from '@/modules/data/components/base/base-details-month.vue';
import { ServiceData } from '@/modules/data/data.service';
import { ServiceChartData } from '@/modules/data/chart/chart-data.service';
import { format } from 'date-fns';
import { i18n } from '@/i18n';

export default defineComponent({
  name: 'DetailsMonthLast',
  components: { BaseDetailsMonth },
  setup(props, { emit }) {
    ServiceData.loadDataIncomeOutcomePerMonthCategories();

    const chart = ref(null);

    const updateTitle = (month) => {
      if (month !== undefined && month !== null) {
        emit('update-title', i18n.t('data.detailsMonthLastSpecific', {
          date: format(month.dateGroupedBy, 'MMMM yyyy'),
        }));
      }
    };

    watch(ServiceChartData.lastMonthAll, (value) => {
      updateTitle(value);
    });

    updateTitle(ServiceChartData.lastMonthAll.value);

    return {
      month: ServiceChartData.lastMonthAll,
      chart,
      redraw() {
        chart.value.redraw();
      },
    };
  },
});
