




























import { defineComponent } from '@vue/composition-api';
import TableImportDataTransactionsItem
  from '@/modules/import/components/table-import-data/table-import-data-transactions-item.vue';

export default defineComponent({
  name: 'ImportTableTransactions',
  components: {
    TableImportDataTransactionsItem,
  },
  props: {
    transactions: {
      required: true,
    },
  },
  setup() {
    return {};
  },
});
