<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col>
          <v-card>
            <table-persons />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import TablePersons from '@/modules/person/components/table-persons';

export default {
  name: 'ViewPersons',
  components: { TablePersons },
};
</script>

<style scoped>

</style>
