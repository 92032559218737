import { store } from '@/modules/app/app.store';
import Big from 'big.js';
import { apolloClient } from '@/vue-apollo';
import {
  queryDataBasicStatisticsOverTime,
  queryDataIncomeOutcomePerMonthCategories,
  queryDataIncomeOutcomePerWeekCategories,
  queryDataStatisticsAccountsOverTime,
  queryDataStatisticsBookingsOverTime,
  queryDataStatisticsInvestmentsOverTime, queryDataStatisticsSharesProfit,
} from '@/modules/data/data.graphql';
import {
  eachMonthOfInterval, eachWeekOfInterval, endOfMonth, parseISO, startOfMonth, subMonths,
} from 'date-fns';
import { Category } from '@/modules/category/category.model';
import { arrayHasEntries, comparatorCategories } from '@/helpers';
import { zonedTimeToUtc } from 'date-fns-tz';
import vuetify from '@/plugins/vuetify';
import { ref } from '@vue/composition-api';
import { Account } from '@/modules/account/account.model';
import { Title } from '../title/title.model';

const loadingData: Record<string, boolean> = {};
export const dataStatistics = {
  statisticsAccountsOverTime: ref<Array<{dateGroupedBy: Date, accounts: Array<{
    account: Account,
    income: Big,
    outcome: Big,
    balance: Big,
    }>}> | null>(null),
  statisticsInvestmentsOverTime: ref<Array<{
    dateGroupedBy: Date,
    invested: Big,
    interestNet: Big,
  }> | null>(null),
  statisticsInvestmentsStockOverTime: ref<Array<{
    dateGroupedBy: Date,
    shares: Big,
    amount: Big,
    fee: Big,
  }> | null>(null),
  sharesProfit: ref<Big | null>(null),
};

export class ServiceData {
  static async loadStatisticsInvestmentssOverTime() {
    const response = await apolloClient.query({
      query: queryDataStatisticsInvestmentsOverTime,
      fetchPolicy: 'no-cache',
    });

    let items = response.data.statisticsInvestmentsOverTime;

    if (items === null) {
      items = [];
    } else {
      items = items.map((item) => {
        item.dateGroupedBy = parseISO(item.dateGroupedBy);
        item.invested = new Big(item.invested);

        return item;
      });
    }

    dataStatistics.statisticsInvestmentsOverTime.value = items;
  }

  static async loadStatisticsInvestmentsStockOverTime() {
    const response = await apolloClient.query({
      query: queryDataStatisticsBookingsOverTime,
      fetchPolicy: 'no-cache',
    });

    let items = response.data.statisticsBookingsOverTime;

    if (items === null) {
      items = [];
    } else {
      items = items.map((item) => {
        item.dateGroupedBy = parseISO(item.dateGroupedBy);
        item.shares = new Big(item.shares);
        item.amount = new Big(item.amount);
        item.fee = new Big(item.fee);

        return item;
      });
    }

    dataStatistics.statisticsInvestmentsStockOverTime.value = items;
  }

  static async loadStatisticsSharesProfit() {
    const response = await apolloClient.query({
      query: queryDataStatisticsSharesProfit,
      fetchPolicy: 'no-cache',
    });

    dataStatistics.sharesProfit.value = response.data.statisticsSharesProfit === null ? null : new Big(response.data.statisticsSharesProfit);
  }

  static async loadStatisticsAccountsOverTime({
    accountsExcluded,
    accountsIncluded,
  }: {
    accountsExcluded?: Array<Category>,
    accountsIncluded?: Array<Category>
  } = {}) {
    const hasPassedAccountsExcluded = arrayHasEntries(accountsExcluded);
    const hasPassedAccountsIncluded = arrayHasEntries(accountsIncluded);

    if (hasPassedAccountsExcluded === false && hasPassedAccountsIncluded === false) {
      if (loadingData.statisticsAccountsOverTime) {
        return;
      }

      loadingData.statisticsAccountsOverTime = true;
    }

    const response = await apolloClient.query({
      query: queryDataStatisticsAccountsOverTime,
      variables: {
        accountsExcluded: hasPassedAccountsExcluded ? accountsExcluded.map((account) => account.id) : undefined,
        accountsIncluded: hasPassedAccountsIncluded ? accountsIncluded.map((account) => account.id) : undefined,
      },
      fetchPolicy: 'no-cache',
    });

    let items = response.data.statisticsAccountsOverTime;
    items = items.map((item) => {
      item.dateGroupedBy = parseISO(item.dateGroupedBy);

      item.accounts = item.accounts.map((account) => ({
        account: store.state.moduleAccount.accounts[account.id],
        income: new Big(account.income),
        outcome: new Big(account.outcome),
        balance: new Big(account.balance),
      }));
      return item;
    });

    if (hasPassedAccountsExcluded === false && hasPassedAccountsIncluded === false) {
      dataStatistics.statisticsAccountsOverTime.value = items;
    }

    return items;
  }

  static async loadBasicStatisticsOverTime({
    categoriesExcluded,
    categoriesIncluded,
  }: {
    categoriesExcluded?: Array<Category>,
    categoriesIncluded?: Array<Category>
  } = {}) {
    const hasPassedCategoriesExcluded = arrayHasEntries(categoriesExcluded);
    const hasPassedCategoriesIncluded = arrayHasEntries(categoriesIncluded);

    if (hasPassedCategoriesExcluded === false && hasPassedCategoriesIncluded === false) {
      if (loadingData.basicStatisticsOverTime) {
        return;
      }

      loadingData.basicStatisticsOverTime = true;
    }

    const response = await apolloClient.query({
      query: queryDataBasicStatisticsOverTime,
      variables: {
        categoriesExcluded: hasPassedCategoriesExcluded ? categoriesExcluded.map((category) => category.id) : undefined,
        categoriesIncluded: hasPassedCategoriesIncluded ? categoriesIncluded.map((category) => category.id) : undefined,
      },
      fetchPolicy: 'no-cache',
    });

    let items = response.data.basicStatisticsOverTime;
    items = items.map((item) => {
      item.dateGroupedBy = parseISO(item.dateGroupedBy);
      item.income = new Big(item.income);
      item.outcome = new Big(item.outcome);
      item.balance = new Big(item.balance);
      return item;
    });

    if (hasPassedCategoriesExcluded === false && hasPassedCategoriesIncluded === false) {
      await store.commit('moduleData/setBasicStatisticsOverTime', items);
    }

    return items;
  }

  static async loadDataIncomeOutcomePerMonthCategories({
    categoriesExcluded,
    categoriesIncluded,
    titlesExcluded,
  }: {
    categoriesExcluded?: Array<Category>,
    categoriesIncluded?: Array<Category>,
    titlesExcluded?: Array<Title>,
  } = {}) {
    const hasPassedCategoriesExcluded = arrayHasEntries(categoriesExcluded);
    const hasPassedCategoriesIncluded = arrayHasEntries(categoriesIncluded);
    const hasPassedTitlesExcluded = arrayHasEntries(titlesExcluded);

    if (hasPassedCategoriesExcluded === false && hasPassedCategoriesIncluded === false) {
      if (loadingData.incomeOutcomePerMonthCategories) {
        return;
      }

      loadingData.incomeOutcomePerMonthCategories = true;
    }

    loadingData.incomeOutcomePerMonthCategories = true;

    const response = await apolloClient.query({
      query: queryDataIncomeOutcomePerMonthCategories,
      variables: {
        categoriesExcluded: hasPassedCategoriesExcluded ? categoriesExcluded.map((category) => category.id) : undefined,
        categoriesIncluded: hasPassedCategoriesIncluded ? categoriesIncluded.map((category) => category.id) : undefined,
        titlesExcluded: hasPassedTitlesExcluded ? titlesExcluded.map((title) => title.id) : undefined,
      },
      fetchPolicy: 'no-cache',
    });

    let items = response.data.incomeOutcomePerMonthCategories;

    items = items.map((item) => {
      // if (item.dateGroupedBy === null) {
      //   return {
      //     dateGroupedBy: null
      //   }
      // }
      item.dateGroupedBy = parseISO(item.dateGroupedBy);

      item.data = item.categories.map((data) => {
        const category = store.state.moduleCategory.categories[data.id];

        return {
          amount: new Big(data.amount).mul(category.income === true ? 1 : -1),
          category,
        };
      });

      delete item.categories;

      return item;
    });

    if (hasPassedCategoriesExcluded === false && hasPassedCategoriesIncluded === false) {
      await store.dispatch('moduleData/setDataIncomeOutcomePerMonth', items);
    }

    return items;
  }

  static async loadDataIncomeOutcomePerWeekCategories() {
    if (loadingData.incomeOutcomePerWeekCategories) {
      return;
    }

    loadingData.incomeOutcomePerWeekCategories = true;

    const response = await apolloClient.query({
      query: queryDataIncomeOutcomePerWeekCategories,
      fetchPolicy: 'no-cache',
    });

    let items = response.data.incomeOutcomePerWeekCategories;
    items = items.map((item) => {
      item.dateGroupedBy = parseISO(item.dateGroupedBy);
      return item;
    });

    await store.dispatch('moduleData/setDataIncomeOutcomePerWeek', {
      dataIncomeOutcomePerWeek: items,
    });

    return items;
  }

  static getInterval({ dateGroupedBy }): Array<{dateGroupedBy: Date; categories: Category[]}> {
    const dateCurrent: Date = new Date();
    const monthEnd = endOfMonth(dateCurrent);
    const monthStart = startOfMonth(subMonths(dateCurrent, 11));
    let months;
    if (dateGroupedBy === 0) {
      months = eachMonthOfInterval({ start: monthStart, end: monthEnd });
    } else if (dateGroupedBy === 1) {
      months = eachWeekOfInterval({ start: monthStart, end: monthEnd }, { weekStartsOn: 1 });
    }
    return months.map((month) => ({
      dateGroupedBy: zonedTimeToUtc(month, 'UTC'),
      categories: [],
    }));
  }

  static processDataCapitalPerMonth({
    data,
  }: {
    data;
  }) {
    const { interval, datasets } = data;
    const { accounts } = store.state.moduleAccount;

    const initialCapital: Big = Object.values(accounts).reduce((amount, account) => amount.plus(account.amountInitial), new Big(0));
    /**
     *
     */
    const dataProcessedIncome = {};
    for (let i = 0; i < datasets[0].data.length; i++) {
      dataProcessedIncome[datasets[0].months[i].toISOString()] = datasets[0].data[i];
    }
    const dataProcessedOutcome = {};
    for (let i = 0; i < datasets[1].data.length; i++) {
      dataProcessedOutcome[datasets[1].months[i].toISOString()] = datasets[1].data[i];
    }

    const dataset = {
      data: [],
      label: 'Kapital',
      type: 'line',
      backgroundColor: vuetify.framework.theme.themes.light.info,
      borderColor: vuetify.framework.theme.themes.light.info,
      lineTension: 0,
      fill: false,
      yAxisID: 'right-y-axis',
    };

    let amountCapital: Big = initialCapital;

    for (let i = 0; i < interval.length; i += 1) {
      const amountIncome = dataProcessedIncome[datasets[0].months[i].toISOString()];
      const amountOutcome = dataProcessedOutcome[datasets[1].months[i].toISOString()];

      if (amountIncome === null && amountOutcome === null) {
        dataset.data.push(null);
      } else {
        if (amountIncome !== null) {
          amountCapital = amountCapital.plus(amountIncome);
        }
        if (amountOutcome !== null) {
          amountCapital = amountCapital.plus(amountOutcome);
        }
        dataset.data.push(parseFloat(amountCapital));
      }
    }

    return { interval, dataset };
  }

  static processDataIncomeOutcomePerMonthCategories({
    data,
    dateGroupedBy,
  }: {
    data: Array<{month: Date; categories: Category[]}>;
    dateGroupedBy: number;
  }) {
    /**
       * Extract object of used categories
       */
    const categoriesRaw = data.reduce((obj, value) => {
      for (let i = 0; i < value.categories.length; i += 1) {
        const category = value.categories[i];

        if (category.id !== undefined) {
          obj[category.id] = category;
        }
      }
      return obj;
    }, {} as {[key: number]: Category});

    const categories: Category[] = Object.values(categoriesRaw).map((category) => store.state.moduleCategory.categories[category.id]);

    categories.sort(comparatorCategories);

    const dataProcessed: {} = data.reduce((obj, value) => {
      obj[value.dateGroupedBy.toISOString()] = value;
      return obj;
    }, {} as {[key: string]: {dateGroupedBy: Date; categories: Category[]}});

    const interval = this.getInterval({ dateGroupedBy });

    const datasetsByCategories: ChartDataSet = categories.map((category) => ({
      data: [],
      backgroundColor: category.color,
      borderColor: category.color,
      lineTension: 0,
      label: category.title,
      category,
      income: category.income,
      // datalabels: {
      //   color: category.income === true ? vuetify.framework.theme.themes.light.success : vuetify.framework.theme.themes.light.error,
      // },
    }));

    for (let i = 0; i < interval.length; i += 1) {
      const invervallItem = interval[i];
      const monthCurrent = invervallItem.dateGroupedBy;

      const monthFound = dataProcessed[monthCurrent.toISOString()];

      if (monthFound !== undefined) {
        invervallItem.categories = monthFound.categories;

        for (let j = 0; j < categories.length; j++) {
          const categoryFound = monthFound.categories.find((category) => category.id === categories[j].id);

          if (categoryFound !== undefined) {
            let amount = parseFloat(categoryFound.amount);
            if (!categories[j].income) {
              amount = -amount;
            }
            datasetsByCategories[j].data.push(amount);
          } else {
            datasetsByCategories[j].data.push(0);
          }
        }
      } else {
        for (let j = 0; j < categories.length; j++) {
          datasetsByCategories[j].data.push(null);
        }
      }
    }
    /**
     *
     */
    const datasets: ChartDataSet = [
      {
        data: [],
        backgroundColor: vuetify.framework.theme.themes.light.success,
        label: 'Income',
        months: [],
        income: true,
      }, {
        data: [],
        backgroundColor: vuetify.framework.theme.themes.light.error,
        label: 'Outcome',
        months: [],
        income: false,
      },
    ];

    for (let i = 0; i < interval.length; i += 1) {
      const invervallItem = interval[i];
      const monthCurrent = invervallItem.dateGroupedBy;

      datasets[0].months.push(monthCurrent);
      datasets[1].months.push(monthCurrent);

      const monthFound = dataProcessed[monthCurrent.toISOString()];

      if (monthFound !== undefined) {
        invervallItem.categories = monthFound.categories;

        const incomeOutcome = {
          income: 0,
          outcome: 0,
        };

        for (let j = 0; j < categories.length; j++) {
          const categoryFound = monthFound.categories.find((category) => category.id === categories[j].id);

          if (categoryFound !== undefined) {
            const amount = parseFloat(categoryFound.amount);
            if (categories[j].income) {
              incomeOutcome.income += amount;
            } else {
              incomeOutcome.outcome -= amount;
            }
          }
        }
        datasets[0].data.push(incomeOutcome.income);
        datasets[1].data.push(incomeOutcome.outcome);
      } else {
        datasets[0].data.push(null);
        datasets[1].data.push(null);
      }
    }

    return {
      interval,
      datasets,
      datasetsByCategories,
    };
  }
}
