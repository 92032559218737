




















import Vue from 'vue';
import { queue } from '@/queue';

export default Vue.extend({
  name: 'TheSnackbar',
  data() {
    return {
      isProcessing: false,
      isOpen: false,
      snackbarCurrent: null,
      timeout: null,
      message: null,
      color: null,
    };
  },
  watch: {
    '$store.state.messagesSnackbar': {
      handler(arr) {
        if (this.isProcessing || arr.length === 0) return;
        this.start();
      },
      deep: true,
    },
    isOpen() {
      if (this.isOpen === true) return;

      this.$nextTick(() => {
        if (this.$store.state.messagesSnackbar.length > 0) {
          this.processNextSnackbar();
        } else {
          this.isProcessing = false;
        }
      });
    },
  },
  created() {
    queue.listen('graphqlError', (error) => {
      if (['titleDelete'].indexOf(error.operation.operationName) > -1) {
        return;
      }

      const { message } = error.graphQLErrors[0];
      this.$store.dispatch('addSnackbar', {
        message,
        color: 'error',
        timeout: message === 'PermissionDenied' ? 2000 : 0,
      });
    });
  },
  methods: {
    processNextSnackbar() {
      this.isProcessing = true;

      this.snackbarCurrent = this.$store.state.messagesSnackbar.shift();

      this.timeout = this.snackbarCurrent.timeout === undefined ? 3000 : this.snackbarCurrent.timeout;
      this.color = this.snackbarCurrent.color === undefined ? 'primary' : this.snackbarCurrent.color;
      this.message = this.snackbarCurrent.message;

      this.isOpen = true;
    },
    start() {
      if (this.isProcessing) return;

      this.processNextSnackbar();
    },
  },
});
