




















import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import { Options } from 'highcharts';
import { formatterTooltip, getConfigChart } from '@/modules/data/chart/base-config-chart';
import BaseChart from '@/modules/data/chart/base-chart.vue';
import { ServiceData } from '@/modules/data/data.service';
import HeaderActionsDataCategories from '@/modules/data/components/cards/header-actions-data-categories.vue';
import { useDataCategories } from '@/modules/data/composables/useDataCategories';

export default defineComponent({
  name: 'DataCategories',
  components: { HeaderActionsDataCategories, BaseChart },
  setup() {
    ServiceData.loadDataIncomeOutcomePerMonthCategories();

    const chart = ref(null);

    const { categoriesPerMonth } = useDataCategories();

    // emit('update-header-actions', HeaderActionsDataCategories);

    const optionsGeneral = getConfigChart({
      chart: {
        height: 500,
        // events: {
        //   click(event) {
        //     const point = this.series[0].searchPoint(event, true);
        //     openDetailsMonth(point.x);
        //   },
        // },
      },
      plotOptions: {
        series: {
          // events: {
          //   click(event) {
          //     openDetailsMonth(event.point.x);
          //   },
          // },
        },
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
        column: {
          stacking: 'normal',
          pointWidth: 40,
        },
      },
      xAxis: {
        type: 'datetime',
        endOnTick: true,
      },
      tooltip: {
        formatter: formatterTooltip,
      },
    });

    const chartType = ref('area');

    const options = computed<Options>(() => {
      if (categoriesPerMonth.value === null) {
        return {};
      }
      return {
        ...optionsGeneral,
        series: categoriesPerMonth.value.map((dataCategory) => ({
          type: chartType.value,
          name: dataCategory.category.title,
          data: dataCategory.data,
          color: dataCategory.category.color,
        })),
      };
    });

    return {
      options,
      chart,
      redraw() {
        chart.value.redraw();
      },
      setChartType(event) {
        chartType.value = event;
      },
    };
  },
});
