



















































import { Category } from '@/modules/category/category.model';
import ItemCategory from '@/modules/category/components/category/item-category.vue';
import { ServiceCategory } from '@/modules/category/category.service';
import BaseButtonCancel from '@/modules/app/components/base/base-button-cancel.vue';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import BaseButtonSaveAndNew from '@/modules/app/components/base/base-button-save-and-new.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { store } from '@/modules/app/app.store';

export default defineComponent({
  name: 'CreateCategory',
  components: {
    BaseButtonSaveAndNew, BaseButtonSave, BaseButtonCancel, ItemCategory,
  },
  setup(props, { emit }) {
    const category = ref<Category>(new Category());
    const dialog = ref(false);
    const isLoading = ref(false);
    const itemCategory = ref(null);

    const reset = ({ addNew = false } = {}) => {
      if (addNew === false) {
        dialog.value = false;
      }
      category.value = new Category();

      itemCategory.value.focus();
    };

    const save = async ({ addNew = false } = {}) => {
      isLoading.value = true;

      const categoryNew = await ServiceCategory.create({
        category: category.value,
      });
      reset({ addNew });

      emit('created', categoryNew);

      isLoading.value = false;
    };

    return {
      vuelidate: useVuelidate({
        title: {
          required,
          unique: (title) => store.getters['moduleCategory/titles'][title] === undefined,
        },
        income: {
          required,
        },
      }, category, { $stopPropagation: true }),
      itemCategory,
      dialog,
      category,
      isLoading,
      reset,
      save,
    };
  },
});
