import { store } from '@/modules/app/app.store';
import { Entity } from '@/modules/app/entity/entity.model';
import { Category } from '@/modules/category/category.model';
import { ImportSettingFromServer, InterfaceImportSetting } from '@/modules/import/import-setting.type';
import { Title } from '@/modules/title/title.model';
import { Transaction } from '@/modules/transactions/transaction.model';
import Big from 'big.js';

export class ImportSetting extends Entity implements InterfaceImportSetting {
    iban: string

    usage: string

    amount: Big | null

    title: Title;

    category: Category

    countUsed: number;

    lastTransaction: Transaction | null

    constructor(data: InterfaceImportSetting = {}) {
      super(data);

      this.iban = data.iban ?? '';
      this.usage = data.usage ?? '';
      this.amount = data.amount ?? null;
      this.title = data.title ?? new Title();
      this.category = data.category ?? new Category();
      this.countUsed = data.countUsed ?? 0;
      this.lastTransaction = data.lastTransaction ?? null;
    }

    static async parseFromServer(data: ImportSettingFromServer): Promise<ImportSetting> {
      const entity = await super.parseFromServer(data) as ImportSetting;

      if (entity.amount !== null) {
        entity.amount = new Big(entity.amount);
      }

      entity.title = await Title.parseFromServer(data.title);

      if (data.lastTransaction !== null) {
        entity.lastTransaction = await Transaction.parseFromServer(data.lastTransaction);
      }

      entity.category = store.state.moduleCategory.categories[data.category.id];

      return entity;
    }

    prepareForServer() {
      const data = super.prepareForServer() as ImportSettingFromServer;

      data.iban = this.iban;
      data.usage = this.usage;
      data.amount = this.amount === null ? null : this.amount.toString();
      data.title = this.title.id;
      data.category = this.category.id;

      return data;
    }
}
