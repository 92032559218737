import gql from 'graphql-tag';
import { fragments } from '@/modules/app/app.fragments';

export const queryImportData = gql`query {
  importData {
    transactions {
      ...transaction
    }
    transfers {
      ...transfer
    }
  }
}
${fragments.transaction}
${fragments.transfer}
`;

export const queryImportSettings = gql`query {
  importSettings {
    ...importSetting
  }
}
${fragments.importSetting}
`;

export const mutateImportData = gql`mutation importData($file: Upload!) {
  importData(file: $file) {
    transactions {
      ...transaction
    }
    transfers {
      ...transfer
    }
  }
}
${fragments.transaction}
${fragments.transfer}
`;

export const mutateSubmitImportData = gql`mutation submitImportData($transactions: [TransactionInput]!, $transfers: [TransferInput]!) {
  submitImportData(transactions: $transactions, transfers: $transfers) {
    success
  }
}
`;

export const mutateAddImportSetting = gql`mutation addImportData($setting: ImportSettingInput!) {
  addImportSetting(setting: $setting) {
    setting {
        ...importSetting
    }
  }
}
${fragments.importSetting}
`;

export const mutateEditImportSetting = gql`mutation editImportData($setting: ImportSettingInput!) {
  editImportSetting(setting: $setting) {
    setting {
        ...importSetting
    }
  }
}
${fragments.importSetting}
`;

export const mutateDeleteImportSetting = gql`mutation deleteImportSetting($idImportSetting: ID!) {
  deleteImportSetting(idImportSetting: $idImportSetting) {
    success
  }
}`;
