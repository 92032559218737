



























import { computed, defineComponent, watch } from '@vue/composition-api';
import { store } from '@/modules/app/app.store';
import ImportTableTransactions from '@/modules/import/components/table-import-data/table-import-data-transactions';
import ImportTableTransfers from '@/modules/import/components/table-import-data/table-import-data-transfers';
import ImportDataSubmit from '@/modules/import/components/table-import-data/import-data-submit';
import useVuelidate from '@vuelidate/core';
import DisplayImportProgress from '@/modules/import/components/table-import-data/display-import-progress.vue';

export default defineComponent({
  name: 'ImportTable',
  components: {
    DisplayImportProgress,
    ImportDataSubmit,
    ImportTableTransfers,
    ImportTableTransactions,
  },
  setup() {
    const importData = computed(() => store.state.moduleImport.importData);

    const vuelidate = useVuelidate();

    return {
      importData,
      vuelidate,
    };
  },
});
