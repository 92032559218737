import gql from 'graphql-tag';
import { fragments } from '@/modules/app/app.fragments';

export const queryTitle = gql`query title($id: ID!) {
  title(id: $id) {
    ...title
  }
}
${fragments.title}
`;

export const queryTitlesPage = gql`query titles($page: Int!, $count: Int!, $sortBy: [String]!, $sortDesc: [Boolean]!, $filters: TitleFilters) {
  titles(page: $page, count: $count, sortBy: $sortBy, sortDesc: $sortDesc, filters: $filters) {
    ...title
  }
  countTitles(filters: $filters)
}
${fragments.title}
`;

export const mutateTitleCreate = gql`mutation titleCreate($title: TitleInput!) {
  titleCreate(title: $title) {
    title {
      ...title
    }
  }
}
${fragments.title}
`;

export const mutateTitleEdit = gql`mutation titleEdit($title: TitleInput!) {
  titleEdit(title: $title) {
    title {
    ...title
    }
  }
}
${fragments.title}
`;

export const mutateTitleDelete = gql`mutation titleDelete($idTitle: ID!, $idTitleReplacement: ID) {
  titleDelete(idTitle: $idTitle, idTitleReplacement: $idTitleReplacement) {
    success
  }
}`;
