


































import BaseCategoryName from '@/modules/category/components/base/base-category-name';
import { i18n } from '@/i18n';
import CreateCategory from '@/modules/category/components/category/create-category';
import { computed, defineComponent } from '@vue/composition-api';
import { useGroupsCategory } from '@/modules/category/composables/useGroupCategories';

export default defineComponent({
  name: 'BaseSelectGroupCategory',
  components: { BaseCategoryName, CreateCategory },
  props: {
    value: {
      required: false,
      type: Object,
      default: undefined,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true,
    },
    hideLabel: {
      required: false,
      type: Boolean,
      default: false,
    },
    singleLine: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: i18n.tc('category.title', 1),
    },
  },
  setup() {
    const { groupsCategorySorted } = useGroupsCategory();
    return {
      groupsCategory: groupsCategorySorted,
    };
  },
});
