
























































































import { GroupCategory } from '@/modules/category/groupCategory.model';
import SettingsGroupCategory from '@/modules/category/components/groupCategory/settings-group-category';
import {
  computed, defineComponent, nextTick, ref, watch,
} from '@vue/composition-api';
import BaseCategoryName from '@/modules/category/components/base/base-category-name.vue';
import BaseSelectTitle from '@/modules/title/components/base/base-select-title.vue';
import BaseTitleName from '@/modules/title/components/base/base-title-name.vue';
import { Title } from '@/modules/title/title.model';
import { values } from 'lodash-es';

export default defineComponent({
  name: 'ItemGroupCategory',
  components: { BaseCategoryName, SettingsGroupCategory, BaseSelectTitle, BaseTitleName },
  props: {
    value: {
      type: GroupCategory,
      required: true,
    },
  },
  setup(props) {
    const settings = computed(() => props.value.settings);
    const settingsGroupCategory = ref(null);
    const titleAdded = ref(undefined);

    watch(settings, () => {
      props.value.updateCategories();
    }, { deep: true });

    return {
      settingsGroupCategory,
      reset() {
        settingsGroupCategory.value.reset();
        // this.$refs.title.focus();
      },
      addTitle: (title: Title) => {
        const set = new Set<string>(props.value.titlesExcluded.map((titleTmp) => titleTmp.id).filter(id => id !== undefined));

        nextTick(() => {
          titleAdded.value = undefined;
        });

        if (set.has(title.id)) {
          return true;
        }

        
        props.value.titlesExcluded.push(title);
      },
      titleAdded,
      removeTitle: (title: Title) => {
        props.value.titlesExcluded = props.value.titlesExcluded.filter(value => value.id !== title.id)
      }
    };
  },
});
