<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col>
          <v-text-field
            ref="name"
            v-model="value.name"
            prepend-icon="fas fa-tags"
            autofocus
            :label="$t('person.item.name')"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { Person } from '@/modules/person/person.model';

export default {
  name: 'ItemPerson',
  props: {
    value: {
      type: Person,
      required: true,
    },
  },
  methods: {
    focus() {
      this.$refs.name.focus();
    },
  },
};
</script>

<style scoped>

</style>
