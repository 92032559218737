













































import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category.vue';
import { useUserSettings } from '@/modules/app/composables/useUserSettings';
import BaseSelectGroupCategory from '@/modules/category/components/base/base-select-group-category.vue';
import { GroupCategory } from '@/modules/category/groupCategory.model';
import { useGroupsCategory } from '@/modules/category/composables/useGroupCategories';
import { useDataCategories } from '@/modules/data/composables/useDataCategories';
import { Category } from '@/modules/category/category.model';
import DataStatistics from '@/modules/data/components/cards/data-statistics.vue';
import { store } from '@/modules/app/app.store';

export default defineComponent({
  name: 'HeaderActionsDataCategories',
  components: { BaseSelectGroupCategory, BaseSelectCategory, DataStatistics },
  setup(props, { emit }) {
    const categoriesSelected = ref <Array<Category>>([]);
    const { getSetting, setSetting } = useUserSettings();
    const keyUserSetting = 'data-categories-group-category';

    const { objectGroupsCategory } = useGroupsCategory();
    const { loadData, categoriesPerMonth } = useDataCategories();

    const settingGroupCategory = getSetting(keyUserSetting);
    const groupCategory = computed<GroupCategory | null>({
      get() {
        if (settingGroupCategory.value === null) {
          return null;
        }

        return objectGroupsCategory.value[settingGroupCategory.value];
      },
      set(groupCategoryNew) {
        setSetting(
          keyUserSetting,
          groupCategoryNew === null ? null : groupCategoryNew.id as string,
        );
      },
    });

    watch(groupCategory, () => {
      if (groupCategory.value !== null) {
        categoriesSelected.value = groupCategory.value?.categories;
      } else {
        categoriesSelected.value = store.getters['moduleCategory/categories'];
      }
    }, { immediate: true });

    watch(categoriesSelected, () => {
      loadData({ categoriesIncluded: categoriesSelected.value, titlesExcluded: groupCategory.value === null ? undefined : groupCategory.value.titlesExcluded });
    }, { immediate: true });

    const chartTypes = [
      { value: 'area', text: 'Flächen' },
      { value: 'column', text: 'Balken' },
    ];

    const chartType = ref<string>('area');

    emit('chart-type', chartType.value);

    return {
      categoriesPerMonth,
      groupCategory,
      categoriesSelected,
      chartType,
      chartTypes,
    };
  },
});
