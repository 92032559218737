























































































































































import { Title } from '@/modules/title/title.model';
import ItemTitle from '@/modules/title/components/item-title.vue';
import { ServiceTitle } from '@/modules/title/title.service';
import CreateTitle from '@/modules/title/components/create-title.vue';
import BaseSelectTitle from '@/modules/title/components/base/base-select-title.vue';
import BaseTitleName from '@/modules/title/components/base/base-title-name.vue';
import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import { store } from '@/modules/app/app.store';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { i18n } from '@/i18n';

export default defineComponent({
  name: 'TableTitles',
  components: {
    BaseSelectTitle, CreateTitle, ItemTitle, BaseTitleName,
  },
  setup() {
    const dialogDelete = ref(false);
    const dialogEdit = ref(false);
    const transferTitle = ref(false);
    const titleReplacement = ref<Title>();
    const itemCurrent = ref<Title>();
    const isLoading = ref(false);
    const options = ref({
      sortBy: ['countUsages'],
      sortDesc: [true],

    });
    const optionsFilter = ref({
      category: null,
      account: null,
    });

    const vuelidate = useVuelidate(computed(() => {
      const validations = {
        itemCurrent: {
          required,
        },
      };
      if (transferTitle.value === true) {
        validations.titleReplacement = {
          required,
        };
      }
      return validations;
    }), {
      itemCurrent,
      transferTitle,
      titleReplacement,
    });

    const loadPage = async () => {
      isLoading.value = true;

      await ServiceTitle.loadPage({
        page: options.value.page,
        itemsPerPage: options.value.itemsPerPage,
        // initialize: initialize.value,
        // filters: this.filters,
        sortBy: options.value.sortBy,
        sortDesc: options.value.sortDesc,
        optionsFilter: optionsFilter.value,
      });

      isLoading.value = false;
    };

    const openEditItem = (item) => {
      itemCurrent.value = new Title(item);
      dialogEdit.value = true;
    };
    const openDeleteItem = (item) => {
      itemCurrent.value = item;
      dialogDelete.value = true;
    };
    const editItem = async () => {
      await ServiceTitle.edit({ title: itemCurrent.value });
      dialogEdit.value = false;
      loadPage();
    };
    const deleteItem = async () => {
      if (itemCurrent.value === undefined) {
        return;
      }

      if (transferTitle.value === false) {
        try {
          await ServiceTitle.delete({ title: itemCurrent.value });
        } catch (e) {
          transferTitle.value = true;
          return;
        }
      } else {
        await ServiceTitle.delete({
          title: itemCurrent.value,
          titleReplacement: titleReplacement.value,
        });
        transferTitle.value = false;
        titleReplacement.value = undefined;
      }
      dialogDelete.value = false;
      loadPage();
    };

    watch(options, () => {
      loadPage();
    });

    return {
      vuelidate,
      dialogDelete,
      dialogEdit,
      transferTitle,
      titleReplacement,
      itemCurrent,
      openEditItem,
      openDeleteItem,
      editItem,
      deleteItem,
      tableHeaders: [
        {
          text: i18n.t('title.item.text'),
          value: 'text',
          class: ['text-no-wrap'],
        },
        {
          text: i18n.t('title.item.countUsages'),
          value: 'countUsages',
          class: ['text-no-wrap'],
        },
        {
          value: 'action',
          sortable: false,
          align: 'right',
          width: '1px',
        },
      ],

      titles: computed(() => Object.values(store.state.moduleTitle.titles)),
      countTitles: computed(() => store.state.moduleTitle.countTotalTitles),
      isLoading,
      options,
    };
  },
});
