import gql from 'graphql-tag';

const transaction = gql`
  fragment transaction on TypeTransaction {
    id
    title {
      id
      text
    }
    importSetting {
      id
    }
    amount
    date
    dateCreated
    dateChanged
    comment
    fromImport
    accountNumber
    person { id }
    account { id }
    category { id }
  }
`;

export const fragments = {
  user: gql`
    fragment user on TypeUser {
      username
      settings
    }
  `,
  transaction,
  transfer: gql`
    fragment transfer on TypeTransfer {
      id
      title
      amount
      date
      dateCreated
      dateChanged
      comment
      fromImport
      accountNumber
      accountSource { id }
      accountDestination { id }
      person { id }
    }
  `,
  category: gql`
    fragment category on TypeCategory {
      id
      title
      color
      income
    }
  `,
  person: gql`
    fragment person on TypePerson {
      id
      name
    }
  `,
  title: gql`
    fragment title on TypeTitle {
      id
      text
      countUsages
    }
  `,
  account: gql`
    fragment account on TypeAccount {
      id
      title
      amountInitial
      countTransactions
      countTransfersDestination
      countTransfersSource
      dateTransactionLast
      iban
    }
  `,
  importSetting: gql`
    fragment importSetting on TypeImportSetting {
      id
      iban
      usage
      amount
      title {
        id
        text
      }
      category {
        id
      }
      countUsed
      lastTransaction {
        ...transaction
      }
    }
    ${transaction}
  `,
  groupCategory: gql`
    fragment groupCategory on TypeGroupCategory {
      id
      title
      settings
      titlesExcluded {
          id
          text
      }
    }
  `,
};
