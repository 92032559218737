





















































import { Title } from '@/modules/title/title.model';
import CreateTitle from '@/modules/title/components/create-title.vue';
import {
  computed, defineComponent, ref,
} from '@vue/composition-api';
import { ServiceTitle } from '@/modules/title/title.service';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'BaseSelectTitle',
  components: { CreateTitle },
  props: {
    value: {
      required: false,
      type: Title,
      default: undefined,
    },
    displayAdd: {
      required: false,
      type: Boolean,
      default: true,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true,
    },
    hideIconPreprended: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideLabel: {
      required: false,
      type: Boolean,
      default: false,
    },
    singleLine: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const items = ref<Array<Title>>([]);
    const searchValue = ref('');

    const isLoading = ref(false);
    let page = 1;
    let countTotal = 0;

    const titles = computed(() => {
      let result = items.value;

      if (props.value !== null && props.value !== undefined) {
        result = result.concat([props.value]);
      }

      result.forEach((item, index) => item.index = index);

      return result;
    });

    const loadPage = async ({ value, initialize = true }: {value: string, initialize?: boolean}) => {
      isLoading.value = true;

      if (initialize === true) {
        page = 1;
      }

      const { titles: titlesNew, countTotal: countTotalNew } = await ServiceTitle.loadPage({
        itemsPerPage: 10,
        page,
        initialize,
        sortBy: ['countUsages', 'text'],
        sortDesc: [true, false],
        optionsFilter: {
          text: value,
        },
        saveToStore: false,
      });

      countTotal = countTotalNew;

      if (initialize) {
        items.value = titlesNew;
      } else {
        items.value = items.value.concat(titlesNew);
      }

      isLoading.value = false;
    };

    const searchDebounced = debounce(loadPage, 700);

    const updatedSearchInput = (value) => {
      searchValue.value = value;
      if (searchValue.value === null) {
        searchDebounced.cancel();
        loadPage({ value: '' });
        return;
      }
      searchDebounced({ value: searchValue.value });
    };

    const onIntersect = (entries, observer, isIntersecting) => {
      if (isIntersecting === false) {
        return;
      }

      if (titles.value.length - entries[0].target.dataset.index !== 1) {
        return;
      }

      if (
        isLoading.value === false
        && items.value.length < countTotal
      ) {
        page += 1;
        // console.warn(titles.value.length, page);
        loadPage({
          initialize: false,
          value: searchValue.value,
        });
      }
    };

    return {
      titles,
      updatedSearchInput,
      onIntersect,
    };
  },
});
