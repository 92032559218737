<template>
  <v-chip
    v-bind="optionsChip"
    v-on.prevent=" clickable === true ? { click: clicked } : {}"
  >
    {{ title.text }}
  </v-chip>
</template>

<script>
import { Title } from '../../title.model';

export default {
  name: 'BaseTitleName',
  props: {
    title: {
      required: true,
      type: Title,
    },
    optionsChip: {
      required: false,
      type: Object,
      default: () => ({ 'x-small': true }),
    },
    clickable: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clicked(event) {
      event.stopPropagation();

      this.$router.push({
        name: 'transactions',
        query: {
          filters__title: this.title.id,
        },
      }).catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
