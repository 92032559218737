






































































import { ServiceApp } from '@/modules/app/app.service';
import { computed, defineComponent } from '@vue/composition-api';
import { i18n } from '@/i18n';
import { useUserSettings } from '@/modules/app/composables/useUserSettings';

export default defineComponent({
  name: 'ViewApp',
  setup(props, { root }) {
    const items = [
      {
        title: i18n.t('app.home'),
        icon: 'fas fa-fw fa-home',
        to: { name: 'home' },
      },
      {
        title: i18n.tc('transaction.title', 2),
        icon: 'fas fa-fw fa-exchange-alt',
        to: { name: 'transactions' },
      },
      {
        title: i18n.tc('transfer.title', 2),
        icon: 'fas fa-fw fa-retweet',
        to: { name: 'transfers' },
      },
      {
        title: i18n.tc('category.title', 2),
        icon: 'fas fa-fw fa-list',
        to: { name: 'categories' },
      },
      {
        title: i18n.tc('person.title', 2),
        icon: 'fas fa-fw fa-users',
        to: { name: 'persons' },
      },
      {
        title: i18n.tc('account.title', 2),
        icon: 'fas fa-fw fa-university',
        to: { name: 'accounts' },
      },
      {
        title: i18n.tc('title.title', 2),
        icon: 'fas fa-fw fa-tag',
        to: { name: 'titles' },
      },
      {
        title: i18n.tc('import.title', 2),
        icon: 'fas fa-fw fa-file-import',
        to: { name: 'import' },
      },
      {
        title: i18n.t('app.logout'),
        icon: 'fas fa-fw fa-sign-out-alt',
        separated: true,
        click: () => {
          ServiceApp.logout();
        },
      },
    ];

    const titleCurrent = computed(() => {
      const item = items.find((item) => {
        if (item.to === undefined) return false;
        return item.to.name === root.$route.name;
      });
      return item.title;
    });

    return {
      titleCurrent,
      drawer: null,
      items,
      isModeEditActive: useUserSettings().getIsModeEdit(),
    };
  },
});
