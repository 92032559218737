















































import { computed, defineComponent, ref } from '@vue/composition-api';
import BaseCategoryName from '@/modules/category/components/base/base-category-name.vue';
import BaseAmount from '@/modules/app/components/base/base-amount.vue';
import Big from 'big.js';
import Vue from 'vue';

export default defineComponent({
  name: 'BaseTableAmountsPerCategory',
  components: { BaseAmount, BaseCategoryName },
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  setup(props, { emit }) {
    const total = computed(() => props.data.reduce((sum, value) => sum.plus(value.amount), new Big(0)));

    const blacklist = ref<Record<string, boolean>>({});

    return {
      total,
      blacklist,
      getIsActive: (key: string) => computed<boolean>(() => blacklist.value[key] !== true),
      setIsActive: (event, key: string) => {
        if (event === false) {
          Vue.set(blacklist.value, key, true);
        } else {
          Vue.delete(blacklist.value, key);
        }

        emit('update-blacklist', blacklist.value);
      },
    };
  },
});
