<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="categories"
      dense
    >
      <template #top>
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>{{ $tc('category.title', 2) }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />

          <create-category />
        </v-toolbar>
      </template>

      <template #item.title="{ item }">
        <base-category-name
          :category="item"
          :options-chip="{ small: true }"
        />
      </template>

      <template #item.income="{ value }">
        <span
          v-if="value === true"
          class="success--text"
        >
          {{ $t('app.income') }}
        </span>
        <span
          v-else
          class="error--text"
        >
          {{ $t('app.outcome') }}
        </span>
      </template>

      <template #item.action="{ item }">
        <v-row
          no-gutters
          class="flex-nowrap"
        >
          <v-col class="mr-5">
            <v-icon
              small
              class="mr-2"
              color="info"
              @click="openEditItem(item)"
            >
              fas fa-edit
            </v-icon>
          </v-col>
          <v-col>
            <v-icon
              small
              color="error"
              @click="openDeleteItem(item)"
            >
              fas fa-trash
            </v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogEdit"
      max-width="1000"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('category.edit') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            <v-container class="py-0">
              <item-category
                ref="item-category"
                v-model="itemCurrent"
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogEdit = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="editItem"
            >
              {{ $t('app.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ $t('category.delete') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            {{ $t('category.deleteConfirmation', {name:itemCurrent.title}) }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogDelete = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="error"
              @click="deleteItem"
            >
              {{ $t('app.delete') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ServiceCategory } from '@/modules/category/category.service';
import ItemCategory from '@/modules/category/components/category/item-category';
import { Category } from '@/modules/category/category.model';
import BaseCategoryName from '@/modules/category/components/base/base-category-name';
import CreateCategory from './create-category';

export default {
  name: 'TableCategories',
  components: { BaseCategoryName, ItemCategory, CreateCategory },
  data() {
    return {
      headers: [
        {
          text: this.$t('category.item.title'),
          value: 'title',
          class: ['text-no-wrap'],
        },
        {
          text: this.$t('category.item.income'),
          value: 'income',
          width: '1px',
          class: ['text-no-wrap'],
        },
        {
          value: 'action',
          sortable: false,
          align: 'right',
          width: '1px',
        },
      ],
      dialogDelete: false,
      dialogEdit: false,
      itemCurrent: {},
    };
  },
  computed: {
    categories() {
      return Object.values(this.$store.state.moduleCategory.categories).filter((category) => category.id >= 0);
    },
  },
  methods: {
    openEditItem(item) {
      this.itemCurrent = new Category(item);
      this.dialogEdit = true;
    },
    openDeleteItem(item) {
      this.itemCurrent = item;
      this.dialogDelete = true;
    },
    async editItem() {
      await ServiceCategory.edit({ category: this.itemCurrent });
      this.dialogEdit = false;
    },
    async deleteItem() {
      await ServiceCategory.delete({ category: this.itemCurrent });
      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>

</style>
