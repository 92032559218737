import { computed, ref } from '@vue/composition-api';
import { store } from '@/modules/app/app.store';
import { Category } from '@/modules/category/category.model';
import { dataStatistics, ServiceData } from '@/modules/data/data.service';
import { arrayHasEntries } from '@/helpers';
import { Account } from '@/modules/account/account.model';
import Big from 'big.js';
import { isEqual } from 'date-fns';

const basicStatisticsOverTimeAll = computed(() => store.state.moduleData.basicStatisticsOverTime);
const basicStatisticsOverTimeFiltered = ref(null);

const dataIncomeOutcomePerMonthAll = computed(() => store.state.moduleData.dataIncomeOutcomePerMonth);
const dataIncomeOutcomePerMonthFiltered = ref(null);

const basicStatisticsOverTime = computed(() => {
  if (basicStatisticsOverTimeFiltered.value !== null) {
    return basicStatisticsOverTimeFiltered.value;
  }

  return basicStatisticsOverTimeAll.value;
});

const getStatisticsOverTime = (property: string) => computed<Array<Array<number>>>(() => {
  if (basicStatisticsOverTime.value === null) {
    return null;
  }

  return basicStatisticsOverTime.value.map((data) => [
    data.dateGroupedBy.getTime(),
    data[property].toNumber(),
  ]);
});

const dataIncomeOutcomePerMonth = computed(() => {
  if (dataIncomeOutcomePerMonthFiltered.value !== null) {
    return dataIncomeOutcomePerMonthFiltered.value;
  }

  return dataIncomeOutcomePerMonthAll.value;
});

const balancePerMonth = getStatisticsOverTime('balance');

const accountsPerMonth = computed(() => {
  if (dataStatistics.statisticsAccountsOverTime.value === null) {
    return null;
  }

  const accountsUnique: Array<Account> = Array.from(
    new Set(
      dataStatistics.statisticsAccountsOverTime.value.map(
        (month) => month.accounts.map(
          (data) => data.account,
        ),
      ).flat(),
    ),
  );

  const monthsPrepared: Array<{month: Date, data: {[key: keyof string]: { income: Big, outcome: Big, balance: Big }}}> = dataStatistics.statisticsAccountsOverTime.value.map((monthData) => ({
    month: monthData.dateGroupedBy,
    data: monthData.accounts.reduce((obj, data) => {
      obj[data.account.id] = {
        income: data.income,
        outcome: data.outcome,
        balance: data.balance,
      };
      return obj;
    }, {}),
  }));

  const result = [];

  for (let i = 0; i < accountsUnique.length; i++) {
    const account = accountsUnique[i];
    const dataAccount = {
      account,
      data: [] as Array<[number, {income: Big, outcome: Big, balance: Big}]>,
    };

    for (let j = 0; j < monthsPrepared.length; j++) {
      const dataMonth = monthsPrepared[j];
      const data: { income: Big, outcome: Big, balance: Big } | undefined = dataMonth.data[account.id as string];

      if (data === undefined) {
        dataAccount.data.push([dataMonth.month.getTime(), {
          income: new Big(0),
          outcome: new Big(0),
          balance: new Big(0),
        }]);
      } else {
        dataAccount.data.push([dataMonth.month.getTime(), data]);
      }
    }

    result.push(dataAccount);
  }

  return result;
});

const getBalanceForIndexMonth = (arr: Array<unknown>, index: number, includeAmountInitial = false): number => {
  let result = arr.reduce(
    (result, value) => result.add(value.data[index][1].balance), new Big(0) as Big,
  );

  if (includeAmountInitial === true) {
    const amountInitialTotal = Object.values(store.state.moduleAccount.accounts).reduce(
      (result, value) => result.add(value.amountInitial), new Big(0) as Big,
    );
    result = result.add(amountInitialTotal);
  }

  return result;
};

const capitalTotalPerMonth = computed(() => {
  if (accountsPerMonth.value === null || accountsPerMonth.value.length === 0) {
    return null;
  }

  const result = accountsPerMonth.value[0].data.reduce((result, [month], index) => {
    if (result.length === 0) {
      result.push([
        month,
        getBalanceForIndexMonth(accountsPerMonth.value, index, true),
      ]);
    } else {
      result.push([
        month,
        result[index - 1][1].add(getBalanceForIndexMonth(accountsPerMonth.value, index)),
      ]);
    }

    return result;
  }, []);

  const resultFormatted = result.map((value) => [
    value[0],
    value[1].toNumber(),
  ]);

  return resultFormatted;
});

/**
 * P2P
 */
const getInitialSumInvestments = (capital: Array<[number, number]>, statisticsInvestmentsOverTime: Array<{dateGroupedBy: Date, invested: Big, interestNet: Big}>) => {
  const firstDate = new Date(capital[0][0]);
  let sumInvestments = new Big('0');
  let sumInterest = new Big('0');

  for (let i = 0; i < statisticsInvestmentsOverTime.length; i++) {
    const dataInvestment = statisticsInvestmentsOverTime[i];
    if (isEqual(firstDate, dataInvestment.dateGroupedBy)) {
      break;
    }

    sumInvestments = sumInvestments.add(dataInvestment.invested);
    sumInterest = sumInterest.add(dataInvestment.interestNet);
  }

  return { sumInvestments, sumInterest };
};

const investmentsP2P = computed(() => {
  if (dataStatistics.statisticsInvestmentsOverTime.value === null || capitalTotalPerMonth.value === null) {
    return null;
  }

  let { sumInvestments, sumInterest } = getInitialSumInvestments(capitalTotalPerMonth.value, dataStatistics.statisticsInvestmentsOverTime.value);

  const statisticsInvestestmentsOverTimePerMonth = dataStatistics.statisticsInvestmentsOverTime.value.reduce((map, value) => {
    map.set(value.dateGroupedBy.getTime(), value);
    return map;
  }, new Map());

  const result = [];

  for (let i = 0; i < capitalTotalPerMonth.value.length; i++) {
    const monthCurrent = capitalTotalPerMonth.value[i][0];
    // console.log(monthCurrent, new Date(monthCurrent), 'monthCurrent');

    if (statisticsInvestestmentsOverTimePerMonth.has(monthCurrent)) {
      sumInvestments = sumInvestments.add(statisticsInvestestmentsOverTimePerMonth.get(monthCurrent).invested);
      sumInterest = sumInterest.add(statisticsInvestestmentsOverTimePerMonth.get(monthCurrent).interestNet);
    }

    result.push([
      monthCurrent,
      sumInvestments.toNumber(),
      sumInterest.toNumber(),
    ]);
  }

  return result;
});

const investmentsP2PPerMonth = computed(() => {
  if (investmentsP2P.value === null) {
    return null;
  }

  return investmentsP2P.value.map((value) => ([
    value[0],
    value[1],
  ]));
});

/**
 * Stock
 */
const getInitialSumInvestmentsStock = (capital: Array<[number, number]>, statisticsInvestmentsOverTime: Array<{dateGroupedBy: Date, shares: Big, amount: Big, fee: Big}>) => {
  const firstDate = new Date(capital[0][0]);
  let sumShares = new Big('0');
  let sumAmount = new Big('0');
  let sumFee = new Big('0');

  for (let i = 0; i < statisticsInvestmentsOverTime.length; i++) {
    const dataInvestment = statisticsInvestmentsOverTime[i];
    if (isEqual(firstDate, dataInvestment.dateGroupedBy)) {
      break;
    }

    sumShares = sumShares.add(dataInvestment.shares);
    sumAmount = sumAmount.add(dataInvestment.amount);
    sumFee = sumFee.add(dataInvestment.fee);
  }

  return { sumShares, sumAmount, sumFee };
};

const investmentsStock = computed(() => {
  if (dataStatistics.statisticsInvestmentsStockOverTime.value === null || capitalTotalPerMonth.value === null) {
    return null;
  }

  let { sumShares, sumAmount, sumFee } = getInitialSumInvestmentsStock(capitalTotalPerMonth.value, dataStatistics.statisticsInvestmentsStockOverTime.value);

  const statisticsInvestmentsStockOverTimePerMonth = dataStatistics.statisticsInvestmentsStockOverTime.value.reduce((map, value) => {
    map.set(value.dateGroupedBy.getTime(), value);
    return map;
  }, new Map());

  const result = [];

  for (let i = 0; i < capitalTotalPerMonth.value.length; i++) {
    const monthCurrent = capitalTotalPerMonth.value[i][0];
    // console.log(monthCurrent, new Date(monthCurrent), 'monthCurrent');

    if (statisticsInvestmentsStockOverTimePerMonth.has(monthCurrent)) {
      sumShares = sumShares.add(statisticsInvestmentsStockOverTimePerMonth.get(monthCurrent).shares);
      sumAmount = sumAmount.add(statisticsInvestmentsStockOverTimePerMonth.get(monthCurrent).amount);
      sumFee = sumFee.add(statisticsInvestmentsStockOverTimePerMonth.get(monthCurrent).fee);
    }

    result.push([
      monthCurrent,
      sumShares.toNumber(),
      sumAmount.toNumber() + sumFee.toNumber(),
      // sumFee.toNumber(),
    ]);
  }

  return result;
});

const investmentsStockPerMonth = computed(() => {
  if (investmentsStock.value === null) {
    return null;
  }

  return investmentsStock.value.map((value) => ([
    value[0],
    value[2],
  ]));
});

const interestPerMonth = computed(() => {
  if (investmentsP2P.value === null) {
    return null;
  }

  return investmentsP2P.value.map((value) => ([
    value[0],
    value[2],
  ]));
});

const capitalAndInvestmentsPerMonth = computed(() => {
  if (
    capitalTotalPerMonth.value === null
    || investmentsP2PPerMonth.value === null
    || interestPerMonth.value === null
    || investmentsStockPerMonth.value === null
  ) {
    return null;
  }

  return capitalTotalPerMonth.value.map((value, index) => ([
    value[0],
    value[1] + investmentsP2PPerMonth.value[index][1] + interestPerMonth.value[index][1] + investmentsStockPerMonth.value[index][1],
  ]));
});

export const useDataBudget = () => ({
  incomePerMonth: getStatisticsOverTime('income'),
  outcomePerMonth: getStatisticsOverTime('outcome'),
  balancePerMonth,
  accountsPerMonth,

  capitalTotalPerMonth,
  investmentsP2PPerMonth,
  investmentsStockPerMonth,
  interestPerMonth,
  capitalAndInvestmentsPerMonth,

  sharesProfit: dataStatistics.sharesProfit,

  getMonth: (timestamp) => dataIncomeOutcomePerMonth.value.find((data) => data.dateGroupedBy.getTime() === timestamp),

  async loadData({
    categoriesExcluded,
    categoriesIncluded,
  }: {
    categoriesExcluded?: Array<Category>,
    categoriesIncluded?: Array<Category>
  } = {}) {
    if (arrayHasEntries(categoriesExcluded) === false && arrayHasEntries(categoriesIncluded) === false) {
      basicStatisticsOverTimeFiltered.value = null;
      dataIncomeOutcomePerMonthFiltered.value = null;
    } else {
      basicStatisticsOverTimeFiltered.value = await ServiceData.loadBasicStatisticsOverTime({
        categoriesExcluded,
        categoriesIncluded,
      });
      dataIncomeOutcomePerMonthFiltered.value = await ServiceData.loadDataIncomeOutcomePerMonthCategories({
        categoriesExcluded,
        categoriesIncluded,
      });
    }
  },
});
