
















































import { defineComponent } from '@vue/composition-api';
import BaseDate from '@/modules/app/components/base/base-date.vue';
import BaseAmount from '@/modules/app/components/base/base-amount.vue';
import BaseCategoryName from '@/modules/category/components/base/base-category-name.vue';
import BaseAccountName from '@/modules/account/components/base/base-account-name.vue';

export default defineComponent({
  name: 'ImportTableTransfers',
  components: {
    BaseAccountName, BaseCategoryName, BaseAmount, BaseDate,
  },
  props: {
    transfers: {
      required: true,
    },
  },
  setup() {
    return {};
  },
});
