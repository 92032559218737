<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="persons"
      dense
    >
      <template v-slot:top>
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>{{ $tc('person.title', 2) }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />

          <create-person />
        </v-toolbar>
      </template>

      <template v-slot:item.color="{ value }">
        <v-chip
          :color="value"
          small
        />
      </template>

      <template v-slot:item.income="{ value }">
        <span
          v-if="value === true"
          class="success--text"
        >
          {{ $t('app.income') }}
        </span>
        <span
          v-else
          class="error--text"
        >
          {{ $t('app.outcome') }}
        </span>
      </template>

      <template v-slot:item.action="{ item }">
        <v-row
          no-gutters
          class="flex-nowrap"
        >
          <v-col class="mr-5">
            <v-icon
              small
              class="mr-2"
              color="info"
              @click="openEditItem(item)"
            >
              fas fa-edit
            </v-icon>
          </v-col>
          <v-col>
            <v-icon
              small
              color="error"
              @click="openDeleteItem(item)"
            >
              fas fa-trash
            </v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogEdit"
      max-width="1000"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('person.edit') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            <v-container class="py-0">
              <item-person
                ref="item-person"
                v-model="itemCurrent"
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogEdit = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="editItem"
            >
              {{ $t('app.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ $t('person.delete') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            {{ $t('person.deleteConfirmation', {name: itemCurrent.name}) }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogDelete = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="error"
              @click="deleteItem"
            >
              {{ $t('app.delete') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ServicePerson } from '@/modules/person/person.service';
import ItemPerson from '@/modules/person/components/item-person';
import { Person } from '@/modules/person/person.model';
import CreatePerson from './create-person';

export default {
  name: 'TablePerson',
  components: { ItemPerson, CreatePerson },
  data() {
    return {
      headers: [
        {
          text: this.$t('person.item.name'),
          value: 'name',
          class: ['text-no-wrap'],
        },
        {
          value: 'action',
          sortable: false,
          align: 'right',
          width: '1px',
        },
      ],
      dialogDelete: false,
      dialogEdit: false,
      itemCurrent: {},
    };
  },
  computed: {
    persons() {
      return Object.values(this.$store.state.modulePerson.persons);
    },
  },
  methods: {
    openEditItem(item) {
      this.itemCurrent = new Person(item);
      this.dialogEdit = true;
    },
    openDeleteItem(item) {
      this.itemCurrent = item;
      this.dialogDelete = true;
    },
    async editItem() {
      await ServicePerson.edit({ person: this.itemCurrent });
      this.dialogEdit = false;
    },
    async deleteItem() {
      await ServicePerson.delete({ person: this.itemCurrent });
      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>

</style>
