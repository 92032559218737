


















import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category.vue';
import { store } from '@/modules/app/app.store';
import { useUserSettings } from '@/modules/app/composables/useUserSettings';
import { useDataBudget } from '@/modules/data/composables/useDataBudget';

export default defineComponent({
  name: 'BudgetHeaderActions',
  components: { BaseSelectCategory },
  setup() {
    const { getSetting, setSetting } = useUserSettings();
    const keyUserSettingCategoriesIgnored = 'budget-categories-ignored';

    const { loadData } = useDataBudget();

    const settingCategoriesIgnored = getSetting(keyUserSettingCategoriesIgnored);
    const categoriesIgnored = computed<Array>({
      get() {
        if (settingCategoriesIgnored.value === undefined) {
          return [];
        }

        return settingCategoriesIgnored.value.map((id) => store.state.moduleCategory.categories[id]);
      },
      set(categories) {
        setSetting(keyUserSettingCategoriesIgnored, categories.map((category) => category.id));
      },
    });

    watch(categoriesIgnored, () => {
      if (categoriesIgnored.value.length > 0) {
        loadData({ categoriesExcluded: categoriesIgnored.value });
      } else {
        loadData();
      }
    }, { immediate: true });

    return {
      categoriesIgnored,
    };
  },
});
