<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <base-select-title
            ref="title"
            v-model="value.title"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            v-model="value.amount"
            prepend-icon="fas fa-coins"
            :label="$t('transaction.item.amount')"
            type="number"
            dense
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          lg="6"
        >
          <base-date-picker v-model="value.date" />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <base-select-category v-model="value.category" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          lg="6"
        >
          <v-select
            v-model="value.person"
            :items="persons"
            :label="$tc('person.title', 1)"
            item-value="id"
            item-text="name"
            return-object
            prepend-icon="fas fa-users"
            dense
          >
            <template #append-outer>
              <create-person @created="$set(value, 'person', $event)">
                <template #default="{ on }">
                  <v-btn
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </template>
              </create-person>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <base-select-account v-model="value.account" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="value.comment"
            :label="$t('transaction.item.comment')"
            rows="3"
            dense
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BaseDatePicker from '@/modules/app/components/base/base-date-picker.vue';
import { Transaction } from '@/modules/transactions/transaction.model';
import CreatePerson from '@/modules/person/components/create-person';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category';
import BaseSelectAccount from '@/modules/account/components/base-select-account';
import BaseSelectTitle from '@/modules/title/components/base/base-select-title.vue';

export default {
  name: 'ItemTransaction',
  components: {
    BaseSelectTitle,
    BaseSelectAccount,
    BaseSelectCategory,
    CreatePerson,
    BaseDatePicker,
  },
  props: {
    value: {
      type: Transaction,
      required: true,
    },
  },
  computed: {
    persons() {
      return Object.values(this.$store.state.modulePerson.persons);
    },
  },
  methods: {
    focus() {
      // TODO: fix
      // this.$refs.title.focus();
    },
  },
};
</script>

<style scoped>

</style>
