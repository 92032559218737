<template>
  <v-select
    :value="value"
    :items="accounts"
    :label="$tc('account.title', 1)"
    item-value="id"
    item-text="title"
    return-object
    prepend-icon="fas fa-university"
    dense
    :clearable="clearable"
    @input="$emit('input', $event)"
  >
    <template
      v-if="displayAdd === true"
      v-slot:append-outer
    >
      <create-account @created="$emit('input', $event)">
        <template v-slot:default="{ on }">
          <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </template>
      </create-account>
    </template>
  </v-select>
</template>

<script>
import CreateAccount from '@/modules/account/components/create-account';
import { Account } from '@/modules/account/account.model';

export default {
  name: 'BaseSelectAccount',
  components: { CreateAccount },
  props: {
    value: {
      required: false,
      type: Account,
      default: undefined,
    },
    displayAdd: {
      required: false,
      type: Boolean,
      default: true,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  computed: {

    accounts() {
      return Object.values(this.$store.state.moduleAccount.accounts);
    },
  },
};
</script>

<style scoped>

</style>
