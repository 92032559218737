<template>
  <v-btn
    text
    color="primary"
    :disabled="disabled"
    :icon="$vuetify.breakpoint.smAndDown === true"
    class="ml-7"
    @click="$emit('click')"
  >
    <template v-if="$vuetify.breakpoint.smAndDown === true">
      <v-icon>fas fa-save</v-icon><v-icon>fas fa-plus</v-icon>
    </template>
    <template v-else>
      {{ label }}
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButtonSaveAndNew',
  props: {
    label: {
      required: false,
      type: String,
      default() {
        return this.$t('app.saveAndNew');
      },
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
