<template>
  <v-data-iterator
    dense
    :headers="headers"
    :items="transfers"
    :loading="isLoading"
    disable-pagination
    hide-default-footer
    :server-items-length="$store.state.moduleTransfer.countTotalTransfers"
  >
    <!--    :page="page"-->
    <!--    :sort-by="sortBy"-->
    <!--    :sort-desc="sortDesc"-->
    <template v-slot:header>
      <v-toolbar
        dense
        elevation="2"
      >
        <sort-transfers
          v-bind.sync="optionsSort"
        />
        <v-spacer />
        <create-transfer @created="loadPage" />
        <!--        <v-toolbar-title>{{ $tc('transfer.title', 2) }}</v-toolbar-title>-->
        <!--        <v-divider-->
        <!--          class="mx-4"-->
        <!--          inset-->
        <!--          vertical-->
        <!--        />-->
        <!--        <sort-transfers />-->
        <!--        <v-spacer></v-spacer>-->
        <!--        <create-transfer />-->
      </v-toolbar>
    </template>

    <template v-slot:default="{ items }">
      <v-row dense>
        <template v-for="(transfer, index) in items">
          <transfer
            :key="transfer.id"
            v-intersect="transfers.length - index === 50 && intersected"
            :transfer="transfer"
            @update="loadPage"
            @delete="loadPage"
          />
        </template>
      </v-row>
    </template>

    <template v-slot:footer>
      <v-row v-if="isLoading === true">
        <v-col class="text-center">
          <v-progress-circular indeterminate />
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import CreateTransfer from '@/modules/transfer/components/create-transfer';
import { ServiceTransfers } from '@/modules/transfer/transfer.service';
import Transfer from '@/modules/transfer/components/list-transfers/transfer';
import SortTransfers
  from '@/modules/transfer/components/list-transfers/sort-transfers';

export default {
  name: 'TableTransfers',
  components: { SortTransfers, Transfer, CreateTransfer },
  data() {
    return {
      headers: [
        {
          text: this.$t('transfer.item.title'),
          value: 'title',
          class: ['text-no-wrap'],
        },
        {
          value: 'action',
          sortable: false,
          align: 'right',
          width: '1px',
        },
      ],
      optionsSort: {
        sortBy: ['date'],
        sortDesc: [true],

      },
      page: 1,
      isLoading: false,
    };
  },
  computed: {
    transfers() {
      return this.$store.state.moduleTransfer.transfers;
    },
  },
  watch: {
    optionsSort: {
      handler() {
        this.loadPage();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    intersected(entries, observer, isIntersecting) {
      if (
        isIntersecting
        && this.isLoading === false
        && this.transfers.length < this.$store.state.moduleTransfer.countTotalTransfers
      ) {
        this.page += 1;
        this.loadPage({
          initialize: false,
        });
      }
    },
    async loadPage({ initialize = true } = {}) {
      this.isLoading = true;

      if (initialize === true) {
        this.page = 1;
      }

      await ServiceTransfers.loadPage({
        page: this.page,
        initialize,
        // filters: this.filters,
        sortBy: this.optionsSort.sortBy,
        sortDesc: this.optionsSort.sortDesc,
      });

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>

</style>
