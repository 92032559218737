

















import { defineComponent, ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import ItemSettingGroupCategory from '@/modules/category/components/groupCategory/item-setting-group-category.vue';

const newSetting = () => ({
  type: 'value',
});

export default defineComponent({
  name: 'CreateSettingGroupCategory',
  components: { ItemSettingGroupCategory },
  setup(props, { emit }) {
    const typesSetting = ref(['value']);
    const operatorsSetting = ref([{ text: 'ist gleich', value: 'isEqual' }]);
    const settingNew = ref(newSetting());

    return {
      settingNew,
      typesSetting,
      operatorsSetting,
      vuelidate: useVuelidate({ $stopPropagation: true }),
      add() {
        emit('create', settingNew.value);
        settingNew.value = newSetting();
      },
    };
  },
});
