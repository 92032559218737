


























































import {
  computed, defineComponent,
} from '@vue/composition-api';
import { store } from '@/modules/app/app.store';
import { i18n } from '@/i18n';
import BaseCategoryName from '@/modules/category/components/base/base-category-name.vue';
import BaseAmount from '@/modules/app/components/base/base-amount.vue';
import DeleteImportSettings from '@/modules/import/components/table-import-settings/delete-import-settings.vue';
import CreateImportSettings from '@/modules/import/components/table-import-settings/create-import-settings.vue';
import BaseDate from '@/modules/app/components/base/base-date.vue';
import EditImportSettings from '@/modules/import/components/table-import-settings/edit-import-settings.vue';

export default defineComponent({
  name: 'TableImportSettings',
  components: {
    CreateImportSettings,
    DeleteImportSettings,
    BaseAmount,
    BaseCategoryName,
    BaseDate,
    EditImportSettings,
  },
  setup() {
    const importSettingsFromStore = computed(() => store.state.moduleImport.importSettings);

    const isLoading = computed(() => importSettingsFromStore.value === null);

    const importSettings = computed(() => isLoading.value ? [] :  Object.values(importSettingsFromStore.value));

    const headers = [
      {
        text: i18n.tc('transaction.item.iban'),
        value: 'iban',
      },
      {
        text: i18n.tc('import.usage'),
        value: 'usage',
      },
      {
        text: i18n.tc('transaction.item.amount'),
        value: 'amount',
      },
      {
        text: i18n.tc('category.item.title'),
        value: 'title.text',
      },
      {
        text: i18n.tc('category.title'),
        value: 'category',
      },
      {
        text: i18n.tc('title.item.countUsages'),
        value: 'countUsed',
      },
      {
        text: i18n.tc('import.importSetting.lastUsed'),
        value: 'lastTransaction.date',
      },
      {
        text: i18n.tc(''),
        value: 'actions',
      },
    ];

    return {
      importSettings,
      isLoading,
      headers,
    };
  },
});
