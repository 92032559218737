<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            ref="title"
            v-model="value.text"
            prepend-icon="fas fa-tags"
            autofocus
            :label="$t('title.item.text')"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import { Title } from '@/modules/title/title.model';

export default {
  name: 'ItemTitle',
  props: {
    value: {
      type: Title,
      required: true,
    },
  },
  methods: {
    focus() {
      this.$refs.title.focus();
    },
  },
};
</script>

<style scoped>

</style>
