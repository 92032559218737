

















import {
  computed, defineComponent, nextTick, ref, watch,
} from '@vue/composition-api';
import { Options } from 'highcharts';

export default defineComponent({
  name: 'BaseChart',
  components: {
  },
  props: {
    options: {
      required: true,
      type: Object,
    },
    useHighstock: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const chart = ref(null);

    const chartDataLoaded = computed(() => {
      if (props.options.series === undefined) {
        return false;
      }

      return (props.options as Options).series.every((series) => Array.isArray(series.data));
    });

    return {
      chartDataLoaded,
      chart,
      redraw() {
        nextTick(() => {
          chart.value.chart.reflow();
        });
      },
    };
  },
});
