



















































import BaseButtonCancel from '@/modules/app/components/base/base-button-cancel.vue';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import BaseButtonSaveAndNew from '@/modules/app/components/base/base-button-save-and-new.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { defineComponent, ref } from '@vue/composition-api';
import ItemTitle from '@/modules/title/components/item-title.vue';
import { Title } from '@/modules/title/title.model';
import { ServiceTitle } from '@/modules/title/title.service';

export default defineComponent({
  name: 'CreateTitle',
  components: {
    ItemTitle,
    BaseButtonSaveAndNew,
    BaseButtonSave,
    BaseButtonCancel,
  },
  setup(props, { emit }) {
    const title = ref<Title>(new Title());
    const dialog = ref(false);
    const isLoading = ref(false);
    const itemTitle = ref(null);

    const reset = ({ addNew = false } = {}) => {
      if (addNew === false) {
        dialog.value = false;
      }
      title.value = new Title();

      itemTitle.value.focus();
    };

    const save = async ({ addNew = false } = {}) => {
      isLoading.value = true;

      const titleNew = await ServiceTitle.create({
        title: title.value,
      });
      reset({ addNew });

      emit('created', titleNew);

      isLoading.value = false;
    };

    return {
      vuelidate: useVuelidate({
        text: {
          required,
        },
      }, title, { $stopPropagation: true }),
      itemTitle,
      dialog,
      title,
      isLoading,
      reset,
      save,
    };
  },
});
