import { Account } from '@/modules/account/account.model';
import Vue from 'vue';
import Big from 'big.js';

export const moduleAccount = {
  namespaced: true,
  state: {
    accounts: null,
  },
  getters: {
    titles(state) {
      return Object.values(state.accounts).reduce((obj, account) => {
        obj[account.title] = true;
        return obj;
      }, {});
    },
  },
  mutations: {
    setAccounts(state, { accounts }: {accounts: Account[]}) {
      state.accounts = accounts.reduce((obj, account) => {
        if (account.id !== undefined) {
          obj[account.id] = account;
        }
        return obj;
      }, {} as {[key: number]: Account});
    },
    setAmounts(state, { accounts }: {accounts: Account[]}) {
      for (let i = 0; i < accounts.length; i++) {
        const account = accounts[i];

        if (state.accounts[account.id] !== undefined) {
          Vue.set(state.accounts[account.id], 'amount', new Big(account.amount));
        }
      }
    },
    addAccount(state, { account }: {account: Account}) {
      if (account.id !== undefined) {
        Vue.set(state.accounts, account.id, account);
      }
    },
    editAccount(state, { account }: {account: Account}) {
      if (account.id !== undefined) {
        Vue.set(state.accounts, account.id, account);
      }
    },
    removeAccount(state, { account }: {account: Account}) {
      Vue.delete(state.accounts, account.id);
    },
  },
  actions: {
    async setAccounts({ commit }, { accounts }: {accounts: Account[]}) {
      commit('setAccounts', {
        accounts,
      });
    },
    async setAmounts({ commit }, { accounts }: {accounts: Account[]}) {
      commit('setAmounts', {
        accounts,
      });
    },
    async addAccount({ commit }, { account }: {account: Account}) {
      commit('addAccount', {
        account,
      });
    },
    async editAccount({ commit }, { account }: {account: Account}) {
      commit('editAccount', {
        account,
      });
    },
    async removeAccount({ commit }, { account }: {account: Account}) {
      commit('removeAccount', {
        account,
      });
    },
  },
};
