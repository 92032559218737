



















































import { ServiceCategory } from '@/modules/category/category.service';
import BaseButtonCancel from '@/modules/app/components/base/base-button-cancel.vue';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import BaseButtonSaveAndNew from '@/modules/app/components/base/base-button-save-and-new.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { defineComponent, ref } from '@vue/composition-api';
import ItemGroupCategory from '@/modules/category/components/groupCategory/item-group-category.vue';
import { GroupCategory } from '@/modules/category/groupCategory.model';
import { useGroupsCategory } from '@/modules/category/composables/useGroupCategories';

export default defineComponent({
  name: 'CreateGroupCategory',
  components: {
    ItemGroupCategory,
    BaseButtonSaveAndNew,
    BaseButtonSave,
    BaseButtonCancel,
  },
  setup(props, { emit }) {
    const groupCategory = ref<GroupCategory>(new GroupCategory());
    const dialog = ref(false);
    const isLoading = ref(false);
    const itemGroupCategory = ref(null);

    const { isUniqueTitle, create } = useGroupsCategory();

    const reset = ({ addNew = false } = {}) => {
      if (addNew === false) {
        dialog.value = false;
      }
      groupCategory.value = new GroupCategory();

      itemGroupCategory.value.reset();
    };

    const save = async ({ addNew = false } = {}) => {
      isLoading.value = true;

      const categoryNew = await create(groupCategory.value);

      reset({ addNew });

      emit('created', categoryNew);

      isLoading.value = false;
    };

    return {
      vuelidate: useVuelidate({
        title: {
          required,
          unique: isUniqueTitle,
        },
        settings: {
          required,
        },
      }, groupCategory, { $stopPropagation: true }),
      itemGroupCategory,
      dialog,
      groupCategory,
      isLoading,
      reset,
      save,
    };
  },
});
