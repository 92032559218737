import cloneDeep from 'lodash-es/cloneDeep';
import Vue from 'vue';
import Vuex from 'vuex';
import { moduleCategory } from '@/modules/category/category.store';
import { modulePerson } from '@/modules/person/person.store';
import { moduleAccount } from '@/modules/account/account.store';
import { moduleTransaction } from '@/modules/transactions/transaction.store';
import { moduleTransfer } from '@/modules/transfer/transfer.store';
import { moduleData } from '@/modules/data/data.store';
import { moduleImport } from '@/modules/import/import.store';
import { moduleTitle } from '@/modules/title/title.store';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    moduleCategory,
    modulePerson,
    moduleAccount,
    moduleTransaction,
    moduleTransfer,
    moduleData,
    moduleImport,
    moduleTitle,
  },
  state: {
    isInitialized: false,
    isAuthenticated: false,
    messagesSnackbar: [],
  },
  mutations: {
    setState(state, { objectState, nameState }) {
      state[nameState] = cloneDeep(objectState);
    },
    addSnackbar(state, data) {
      Vue.set(state.messagesSnackbar, state.messagesSnackbar.length, data);
    },
  },
  actions: {
    async setState({ commit }, { objectState, nameState }) {
      commit('setState', {
        objectState,
        nameState,
      });
    },
    addSnackbar({ commit }, data) {
      commit('addSnackbar', data);
    },
  },
});
