







import { defineComponent } from '@vue/composition-api';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import { useImport } from '@/modules/import/import.service';
import { store } from '@/modules/app/app.store';

export default defineComponent({
  name: 'ImportDataSubmit',
  components: { BaseButtonSave },
  props: {
    disabled: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return {
      submitImport: () => {
        useImport.submitImport({
          transactions: store.state.moduleImport.importData.transactions,
          transfers: store.state.moduleImport.importData.transfers,
        });
      },
    };
  },
});
