<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            ref="title"
            v-model="value.title"
            prepend-icon="fas fa-tags"
            autofocus
            :label="$t('transfer.item.title')"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            v-model="value.amount"
            prepend-icon="fas fa-coins"
            :label="$t('transfer.item.amount')"
            type="number"
            dense
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          lg="6"
        >
          <base-date-picker v-model="value.date" />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-select
            v-model="value.person"
            :items="persons"
            :label="$tc('person.title', 1)"
            item-value="id"
            item-text="name"
            return-object
            prepend-icon="fas fa-users"
            dense
          >
            <template v-slot:append-outer>
              <create-person @created="$set(value, 'person', $event)">
                <template v-slot:default="{ on }">
                  <v-btn
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </template>
              </create-person>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          lg="6"
        >
          <v-select
            v-model="value.accountSource"
            :items="accounts"
            :label="$tc('account.title', 1)"
            item-value="id"
            item-text="title"
            return-object
            prepend-icon="fas fa-level-up-alt"
            dense
          >
            <template v-slot:append-outer>
              <create-account @created="$set(value, 'accountSource', $event)">
                <template v-slot:default="{ on }">
                  <v-btn
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </template>
              </create-account>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-select
            v-model="value.accountDestination"
            :items="accounts"
            :label="$tc('account.title', 1)"
            item-value="id"
            item-text="title"
            return-object
            prepend-icon="fas fa-level-down-alt"
            dense
          >
            <template v-slot:append-outer>
              <create-account @created="$set(value, 'accountDestination', $event)">
                <template v-slot:default="{ on }">
                  <v-btn
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </template>
              </create-account>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="value.comment"
            :label="$t('transfer.item.comment')"
            rows="3"
            dense
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BaseDatePicker from '@/modules/app/components/base/base-date-picker.vue';
import { Transfer } from '@/modules/transfer/transfer.model';
import CreateAccount from '@/modules/account/components/create-account';
import CreatePerson from '@/modules/person/components/create-person';

export default {
  name: 'ItemTransfer',
  components: {
    CreatePerson, CreateAccount, BaseDatePicker,
  },
  props: {
    value: {
      type: Transfer,
      required: true,
    },
  },
  computed: {
    accounts() {
      return Object.values(this.$store.state.moduleAccount.accounts);
    },
    persons() {
      return Object.values(this.$store.state.modulePerson.persons);
    },
  },
  methods: {
    focus() {
      this.$refs.title.focus();
    },
  },
};
</script>

<style scoped>

</style>
