import { GroupCategory } from '@/modules/category/groupCategory.model';
import { computed, ref } from '@vue/composition-api';
import { i18n } from '@/i18n';
import { TypeSettingGroupCategory } from '@/modules/category/category.types';
import {
  mutateGroupCategoryCreate, mutateGroupCategoryDelete,
  mutateGroupCategoryEdit,
} from '@/modules/category/category.graphql';
import { apolloClient } from '@/vue-apollo';
import Vue from 'vue';

const objectGroupsCategory = ref<Record<string, GroupCategory>>({});
const groupsCategorySorted = computed(() => Object.values(objectGroupsCategory.value));
const titlesGroupCategory = computed(() => groupsCategorySorted.value.reduce((obj, groupCategory) => {
  if (groupCategory.title !== undefined) {
    obj[groupCategory.title] = true;
  }

  return obj;
}, {} as Record<string, boolean>));

export const useGroupsCategory = () => ({
  objectGroupsCategory,
  groupsCategorySorted,
  setGroupsCategory(groupsCategoryPassed: Array<{id: string, title: string, settings: string}>) {
    objectGroupsCategory.value = {
      '-1': new GroupCategory({
        id: '-1',
        title: i18n.t('app.income') as string,
        settings: JSON.stringify([
          {
            type: 'all',
          },
          {
            type: 'value',
            field: 'income',
            operator: 'isEqual',
            value: true,
          },
        ] as Array<TypeSettingGroupCategory>),
        titlesExcluded: [],
      }),

      '-2': new GroupCategory({
        id: '-2',
        title: i18n.t('app.outcome') as string,
        settings: JSON.stringify([
          {
            type: 'all',
          },
          {
            type: 'value',
            field: 'income',
            operator: 'isEqual',
            value: false,
          },
        ] as Array<TypeSettingGroupCategory>),
        titlesExcluded: [],
      }),
    };

    groupsCategoryPassed.forEach((groupCategory) => Vue.set(
      objectGroupsCategory.value,
      groupCategory.id,
      new GroupCategory(groupCategory),
    ));
  },
  isUniqueTitle(title: string) {
    return titlesGroupCategory.value[title] !== true;
  },
  async create(groupCategoryPassed: GroupCategory) {
    const response = await apolloClient.mutate({
      mutation: mutateGroupCategoryCreate,
      variables: {
        groupCategory: groupCategoryPassed.prepareForServer(),
      },
    });

    const groupCategory = new GroupCategory(response.data.groupCategoryCreate.groupCategory);

    Vue.set(objectGroupsCategory.value, groupCategory.id as string, groupCategory);

    return groupCategory;
  },
  async edit(groupCategoryPassed: GroupCategory) {
    const response = await apolloClient.mutate({
      mutation: mutateGroupCategoryEdit,
      variables: {
        groupCategory: groupCategoryPassed.prepareForServer(),
      },
    });

    const groupCategory = new GroupCategory(response.data.groupCategoryEdit.groupCategory);

    Vue.set(objectGroupsCategory.value, groupCategory.id as string, groupCategory);

    return groupCategory;
  },

  async delete(groupCategoryPassed: GroupCategory) {
    await apolloClient.mutate({
      mutation: mutateGroupCategoryDelete,
      variables: {
        idGroupCategory: groupCategoryPassed.id,
      },
    });

    Vue.delete(objectGroupsCategory.value, groupCategoryPassed.id as string);
  },
});
