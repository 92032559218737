import { Category } from '@/modules/category/category.model';
import type { TypeSettingGroupCategory } from '@/modules/category/category.types';
import { store } from '@/modules/app/app.store';
import { TypeSettingGroupCategoryField } from '@/modules/category/category.types';
import { cloneDeep } from 'lodash-es';
import { Title } from '../title/title.model';

const handleSettingValue = (categories: Map<string, Category>, setting: TypeSettingGroupCategory): Map<string, Category> => {
  let func;
  switch (setting.operator) {
    case 'isEqual':
      func = ([, category]: [string, Category]) => category[setting.field as TypeSettingGroupCategoryField] === setting.value;
      break;
    default:
      func = () => true;
  }
  return new Map(Array.from(categories.entries()).filter(func));
};

const handleSettingInclude = (categories: Map<string, Category>, setting: TypeSettingGroupCategory): Map<string, Category> => {
  if (setting.value !== undefined && Array.isArray(setting.value)) {
    setting.value.forEach((category) => categories.set(category.id as string, category));
  }
  return categories;
};

const handleSettingExclude = (categories: Map<string, Category>, setting: TypeSettingGroupCategory): Map<string, Category> => {
  if (setting.value !== undefined && Array.isArray(setting.value)) {
    setting.value.forEach((category) => categories.delete(category.id as string));
  }
  return categories;
};

export class GroupCategory {
  id?: string;

  title?: string;

  settings: Array<TypeSettingGroupCategory>;
  
  titlesExcluded: Array<Title>

  categories: Array<Category>

  constructor({
    id,
    title,
    settings,
    titlesExcluded,
  }: {
    id?: string;
    title?: string;
    settings: string;
    titlesExcluded: Array<Title>
  } = {
    settings: '[]',
    titlesExcluded: []
  }) {
    this.id = id;
    this.title = title;

    this.settings = typeof settings === 'string' ? JSON.parse(settings) : settings;
    this.titlesExcluded = titlesExcluded;
    this.categories = this.updateCategories();
  }

  updateCategories(): Array<Category> {
    let result: Map<string, Category> = new Map();

    for (let i = 0; i < this.settings.length; i += 1) {
      const setting = this.settings[i];
      switch (setting.type) {
        case 'all':
          result = new Map(store.getters['moduleCategory/categories'].map((category) => [category.id, category]));
          break;
        case 'none':
          result = new Map();
          break;
        case 'value':
          result = handleSettingValue(result, setting);
          break;
        case 'include':
          result = handleSettingInclude(result, setting);
          break;
        case 'exclude':
          result = handleSettingExclude(result, setting);
          break;
        default:
          break;
      }
    }

    this.categories = Array.from(result.values());

    return this.categories;
  }

  prepareForServer() {
    return {
      id: this.id,
      title: this.title,
      settings: JSON.stringify(this.settings.map((settingPassed) => {
        const setting = cloneDeep(settingPassed);

        if (setting.type === 'include' || setting.type === 'exclude') {
          setting.value = (setting.value as Array<Category>).map((
            category,
          ) => ({ id: category.id }));
        }

        return setting;
      })),
      titlesExcluded: this.titlesExcluded.map(title => title.id),
    };
  }
}
