























import { computed, defineComponent } from '@vue/composition-api';
import { store } from '@/modules/app/app.store';

export default defineComponent({
  name: 'DisplayImportProgress',
  props: {
    vuelidate: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const importData = computed(() => store.state.moduleImport.importData);

    const countInvalid = computed(() => {
      const keys: Array<string> = Object.keys(props.vuelidate).filter((key) => key.startsWith('_vuelidate_'));
      return keys.filter((key) => props.vuelidate[key].$invalid).length;
    });
    return {
      countInvalid,
      countTotal: computed(() => importData.value.transactions.length + importData.value.transfers.length),
      classes: computed(() => (countInvalid.value > 0 ? ['error--text'] : ['success--text'])),
    };
  },
});
