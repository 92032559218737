
















import { computed, defineComponent, ref } from '@vue/composition-api';
import draggable from 'vuedraggable';
import DataCard from '@/modules/data/components/cards/data-card.vue';
import Budget from '@/modules/data/components/cards/budget.vue';
import DetailsMonthCurrent from '@/modules/data/components/cards/details-month-current.vue';
import DetailsMonthLast from '@/modules/data/components/cards/details-month-last.vue';
import DataCategories from '@/modules/data/components/cards/data-categories.vue';
import CapitalTotal from '@/modules/data/components/cards/capital-total.vue';
import type { DataCardInterface } from '@/modules/data/data.types';
import { i18n } from '@/i18n';
import { useUserSettings } from '@/modules/app/composables/useUserSettings';

export default defineComponent({
  name: 'DataOverview',
  components: {
    draggable,
    DataCard,
  },
  setup() {
    const keyUserSetting = 'data-cards-order';

    const { getSetting, setSetting } = useUserSettings();
    const userSettingDataCardsOrder = getSetting(keyUserSetting);
    const cardsOrderDefault = ['details-month-current', 'details-month-last', 'budget', 'categories', 'capital-total'];
    // const cardsOrderDefault = ['details-month-current', 'details-month-last', 'budget'];

    const cardsDataOrder = computed<Array<DataCardInterface>>({
      get() {
        if (userSettingDataCardsOrder.value === undefined) {
          return cardsOrderDefault;
        }

        const result = [...userSettingDataCardsOrder.value];

        for (let i = 0; i < cardsOrderDefault.length; i++) {
          if (userSettingDataCardsOrder.value.find((cardUser) => cardUser === cardsOrderDefault[i]) === undefined) {
            result.push(cardsOrderDefault[i]);
          }
        }

        return result;
      },
      set(value) {
        setSetting(keyUserSetting, value);
      },
    });

    const cardsData = {
      'details-month-current': {
        header: i18n.t('data.detailsMonthCurrent') as string,
        component: DetailsMonthCurrent,
        cols: 6,
      },
      'details-month-last': {
        header: i18n.t('data.detailsMonthLast') as string,
        component: DetailsMonthLast,
        cols: 6,
      },
      categories: {
        header: i18n.t('data.incomeOutcomePerCategory') as string,
        component: DataCategories,
      },
      budget: {
        header: i18n.t('data.incomeOutcomePerMonth') as string,
        component: Budget,
      },
      'capital-total': {
        header: i18n.t('data.capitalTotal') as string,
        component: CapitalTotal,
      },
      // 'income-outcome-per-month': {
      //   header: i18n.t('data.incomeOutcomePerMonth') as string,
      //   component: IncomeOutcomePerMonth,
      // },
      // 'income-outcome-per-month-categories': {
      //   header: i18n.t('data.incomeOutcomePerMonth') as string,
      //   component: IncomeOutcomePerMonthCategories,
      // },
    };

    const cardsDataOrdered = computed(() => cardsDataOrder.value.map((id) => {
      const cardData = cardsData[id];
      cardData.id = id;
      return cardData;
    }));

    const componentData = ref({
      attrs: { dense: true },
    });

    return {
      cardsDataOrder,
      cardsDataOrdered,
      componentData,
    };
  },
});
