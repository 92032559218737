var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{attrs:{"items":[{text: 'Durschnitt', value: 'mean'},{text: 'Median', value: 'median'},{text: 'Std. Abweichung', value: 'std'}],"dense":"","hide-details":""},on:{"input":function($event){return _vm.$emit('chart-type', $event)}},model:{value:(_vm.statistic),callback:function ($$v) {_vm.statistic=$$v},expression:"statistic"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataPrepared,"hide-default-footer":"","items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.title)+" ")]}},{key:"item.all",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('base-amount',{attrs:{"value":value[_vm.statistic],"positive":item.category.income,"negative":item.category.income === false}})]}},{key:"item.lastYear",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('base-amount',{attrs:{"value":value[_vm.statistic],"positive":item.category.income,"negative":item.category.income === false}})]}},{key:"item.lastHalfYear",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('base-amount',{attrs:{"value":value[_vm.statistic],"positive":item.category.income,"negative":item.category.income === false}})]}},{key:"item.lastQuarter",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('base-amount',{attrs:{"value":value[_vm.statistic],"positive":item.category.income,"negative":item.category.income === false}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }