<template>
  <div>
    <v-text-field
      :value="dateFormatted"
      :label="label"
      prepend-icon="fas fa-calendar-day"
      dense
      v-bind="properties"
      @input="input"
      @click:prepend="openDialog"
    >
      <template v-slot:append-outer>
        <v-btn
          text
          @click="reset"
        >
          {{ $t('app.today') }}
        </v-btn>
      </template>
    </v-text-field>
    <v-dialog
      v-model="dialog"
      max-width="300px"
    >
      <v-date-picker
        v-model="date"
        scrollable
        first-day-of-week="1"
        @change="save"
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="dialog = false"
        >
          {{ $t('app.cancel') }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import addHours from 'date-fns/addHours';
import debounce from 'lodash-es/debounce';

export default {
  name: 'BaseDatePicker',
  props: {
    value: {
      type: Date | null,
      required: false,
      default() {
        return new Date();
      },
    },
    label: {
      type: String,
      required: false,
      default() {
        return this.$t('app.date');
      },
    },
    properties: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dialog: false,
      date: this.value === null ? undefined : this.convertDateToVuetifyDate(this.value),
    };
  },
  computed: {
    dateFormatted() {
      return this.value === null ? 'ungültig' : format(this.value, 'dd.MM.yyyy');
    },
    emitDebounced() {
      return debounce(this.emitInput, 1000);
    },
  },
  methods: {
    reset() {
      const dateNew = new Date();
      this.date = this.convertDateToVuetifyDate(dateNew);
      this.$emit('input', dateNew);
    },
    emitInput(value) {
      this.$emit('input', value);
    },
    input(value) {
      if (value.trim() === '') {
        this.emitDebounced.cancel();
        return;
      }

      const dateNew = parse(value, 'dd.MM.yyyy', new Date());
      if (isValid(dateNew)) {
        this.date = this.convertDateToVuetifyDate(dateNew);
        this.emitDebounced(dateNew);
      } else {
        this.emitDebounced(null);
      }
    },
    openDialog() {
      this.dialog = true;
    },
    save() {
      this.$emit('input', parseISO(this.date));
      this.dialog = false;
    },
    convertDateToVuetifyDate(date) {
      return addHours(date, 5).toISOString().substr(0, 10);
    },
  },
};
</script>

<style scoped>

</style>
