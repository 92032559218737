

























































import { Transaction } from '@/modules/transactions/transaction.model';
import ItemTransaction from '@/modules/transactions/components/item-transaction.vue';
import { ServiceTransactions } from '@/modules/transactions/transactions.service';
import BaseButtonCancel from '@/modules/app/components/base/base-button-cancel.vue';
import BaseButtonSaveAndNew from '@/modules/app/components/base/base-button-save-and-new.vue';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
import { store } from '@/modules/app/app.store';

export default defineComponent({
  name: 'CreateTransaction',
  components: {
    BaseButtonSave, BaseButtonSaveAndNew, BaseButtonCancel, ItemTransaction,
  },
  setup(props, { emit }) {
    const transaction = ref<Transaction>(new Transaction());
    const dialog = ref(false);
    const isLoading = ref(false);
    const refItemTransaction = ref(null);

    const categories = computed(() => Object.values(store.state.moduleCategory.categories));
    const accounts = computed(() => Object.values(store.state.moduleAccount.accounts));
    const persons = computed(() => Object.values(store.state.modulePerson.persons));

    const prepareTransaction = () => {
      const { lastInputs } = store.state.moduleTransaction;
      // Date
      if (lastInputs.date !== undefined) {
        transaction.value.date = lastInputs.date;
      }
      // Category
      if (lastInputs.category !== undefined) {
        transaction.value.category = lastInputs.category;
      } else if (categories.value.length === 1) {
        transaction.value.category = categories.value[0];
      }
      // Account
      if (lastInputs.account !== undefined) {
        transaction.value.account = lastInputs.account;
      } else if (accounts.value.length === 1) {
        transaction.value.account = accounts.value[0];
      }
      // Person
      if (lastInputs.person !== undefined) {
        transaction.value.person = lastInputs.person;
      } else if (persons.value.length === 1) {
        transaction.value.person = persons.value[0];
      }
    };

    const updateLastInputs = async () => {
      await store.dispatch('moduleTransaction/setLastInputs', {
        lastInputs: {
          date: transaction.value.date,
          category: transaction.value.category,
          account: transaction.value.account,
          person: transaction.value.person,
        },
      });
    };

    const reset = ({ addNew = false } = {}) => {
      transaction.value = new Transaction();

      if (addNew === false) {
        dialog.value = false;
      } else {
        prepareTransaction();
      }

      refItemTransaction.value.focus();
    };

    const save = async ({ addNew = false } = {}) => {
      isLoading.value = true;

      const transactionNew = await ServiceTransactions.create({
        transaction: transaction.value,
      });

      updateLastInputs();

      reset({ addNew });

      emit('created', transactionNew);

      isLoading.value = false;
    };

    watch(dialog, () => {
      if (dialog.value === true) {
        prepareTransaction();
      }
    });

    return {
      vuelidate: useVuelidate({
        title: {
          required,
        },
        amount: {
          required,
        },
        date: {
          required,
        },
        category: {
          required,
        },
        person: {
          required,
        },
        account: {
          required,
        },
      }, transaction),
      dialog,
      transaction,
      isLoading,
      reset,
      save,
      categories,
      accounts,
      persons,
      refItemTransaction,
    };
  },
});
