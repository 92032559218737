import gql from 'graphql-tag';
import { fragments } from '@/modules/app/app.fragments';

export const queryInitial = gql`query {
  user {
    ...user
  }
  categories {
    ...category
  }
  persons {
    ...person
  }
  accounts {
    ...account
  }
  groupCategories {
    ...groupCategory
  }
}
${fragments.user}
${fragments.category}
${fragments.person}
${fragments.account}
${fragments.groupCategory}
`;

export const mutateUserSettingSet = gql`mutation user($userSettingSet: UserSettingInput!) {
  userSettingSet(setting: $userSettingSet) {
    success
  }
}
`;
