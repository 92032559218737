



















































import { defineComponent, ref } from '@vue/composition-api';
import { useImport } from '@/modules/import/import.service';
import itemImportSetting from '@/modules/import/components/table-import-settings/item-import-setting.vue';

export default defineComponent({
  name: 'EditImportSettings',
  components: { itemImportSetting },
  props: {
    importSetting: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const dialog = ref(false);
    return {
      dialog,
      update: async () => {
        await useImport.editSetting(props.importSetting);
        dialog.value = false;
      },
    };
  },
});
