




















































import { ServiceApp } from '@/modules/app/app.service';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { store } from '@/modules/app/app.store';
import { i18n } from '@/i18n';

export default defineComponent({
  name: 'LoginView',
  setup() {
    const username = ref<string>('');
    const password = ref<string>('');
    const refUsername = ref(null);

    const login = async () => {
      const result = await ServiceApp.login({
        username: username.value,
        password: password.value,
      });

      if (result === false) {
        store.dispatch('addSnackbar', {
          message: i18n.t('app.wrongCredentials'),
          color: 'error',
        });
      }
    };

    onMounted(() => {
      refUsername.value.focus();
    });

    return {
      vuelidate: useVuelidate({
        username: {
          required,
        },
        password: {
          required,
        },
      }, { username, password }),
      username,
      password,
      refUsername,
      login,
    };
  },
});
