import gql from 'graphql-tag';
import { fragments } from '@/modules/app/app.fragments';

export const queryAccountsWithAmounts = gql`query accountsWithAmounts($date: Date) {
  accountsWithAmounts(date: $date) {
    id
    amount
  }
}`;

export const mutateAccountCreate = gql`mutation accountCreate($account: AccountInput!) {
  accountCreate(account: $account) {
    account {
      ...account
    }
  }
}
${fragments.account}
`;

export const mutateAccountEdit = gql`mutation accountEdit($account: AccountInput!) {
  accountEdit(account: $account) {
    account {
    ...account
    }
  }
}
${fragments.account}
`;

export const mutateAccountDelete = gql`mutation accountDelete($idAccount: ID!) {
  accountDelete(idAccount: $idAccount) {
    success
  }
}`;
