



















































import { Account } from '@/modules/account/account.model';
import ItemAccount from '@/modules/account/components/item-account';
import { ServiceAccount } from '@/modules/account/account.service';
import BaseButtonCancel from '@/modules/app/components/base/base-button-cancel.vue';
import BaseButtonSave from '@/modules/app/components/base/base-button-save.vue';
import BaseButtonSaveAndNew from '@/modules/app/components/base/base-button-save-and-new.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { store } from '@/modules/app/app.store';
import { ref } from '@vue/composition-api';

export default {
  name: 'CreateAccount',
  components: {
    BaseButtonSaveAndNew, BaseButtonSave, BaseButtonCancel, ItemAccount,
  },
  setup(props, { emit }) {
    const account = ref<Account>(new Account());
    const dialog = ref(false);
    const isLoading = ref(false);
    const refItemAccount = ref(null);

    const reset = ({ addNew = false } = {}) => {
      if (addNew === false) {
        dialog.value = false;
      }
      account.value = new Account();

      refItemAccount.value.focus();
    };

    const save = async ({ addNew = false } = {}) => {
      isLoading.value = true;

      const accountNew = await ServiceAccount.create({
        account: account.value,
      });
      reset({ addNew });

      emit('created', accountNew);

      isLoading.value = false;
    };

    return {
      vuelidate: useVuelidate({
        title: {
          required,
          unique: (title) => store.getters['moduleAccount/titles'][title] === undefined,
        },
        amountInitial: {
          required,
        },
      }, account, { $stopPropagation: true }),
      save,
      refItemAccount,
      dialog,
      account,
      reset,
    };
  },
};
