import Big from 'big.js';
import Dinero from 'dinero.js';

export function displayMoney({ value }: {value: string}): string {
  const valueBigJs: Big = new Big(value);
  const valuePrepared: number = parseInt(valueBigJs.round(2).times(100), 10);
  return Dinero({ amount: valuePrepared, currency: 'EUR' }).setLocale('de').toFormat('$0,0.00');
}

export function comparatorCategories(category1, category2) {
  if (category1.income !== category2.income) {
    return category1.income === true ? 1 : -1;
  }

  return category1.title > category2.title ? 1 : -1;
}

export function comparatorAmount(amount1, amount2) {
  if (amount1.eq(amount2)) {
    return 0;
  }

  return amount1.lt(amount2) ? 1 : -1;
}

export const arrayHasEntries = (arr) => Array.isArray(arr) && arr.length > 0;
