import { Person } from '@/modules/person/person.model';
import Vue from 'vue';

export const modulePerson = {
  namespaced: true,
  state: {
    persons: null,
  },
  getters: {
    names(state) {
      return Object.values(state.persons).reduce((obj, person) => {
        obj[person.name] = true;
        return obj;
      }, {});
    },
  },
  mutations: {
    setPersons(state, { persons }: {persons: Person[]}) {
      state.persons = persons.reduce((obj, person) => {
        if (person.id !== undefined) {
          obj[person.id] = person;
        }
        return obj;
      }, {} as {[key: number]: Person});
    },
    addPerson(state, { person }: {person: Person}) {
      if (person.id !== undefined) {
        Vue.set(state.persons, person.id, person);
      }
    },
    editPerson(state, { person }: {person: Person}) {
      if (person.id !== undefined) {
        Vue.set(state.persons, person.id, person);
      }
    },
    removePerson(state, { person }: {person: Person}) {
      Vue.delete(state.persons, person.id);
    },
  },
  actions: {
    async setPersons({ commit }, { persons }: {persons: Person[]}) {
      commit('setPersons', {
        persons,
      });
    },
    async addPerson({ commit }, { person }: {person: Person}) {
      commit('addPerson', {
        person,
      });
    },
    async editPerson({ commit }, { person }: {person: Person}) {
      commit('editPerson', {
        person,
      });
    },
    async removePerson({ commit }, { person }: {person: Person}) {
      commit('removePerson', {
        person,
      });
    },
  },
};
