import { Entity } from '@/modules/app/entity/entity.model';
import { InterfaceTitle, TitleFromServer } from '@/modules/title/title.type';

export class Title extends Entity implements InterfaceTitle {
  text: string;

  countUsages: number;

  constructor(data: InterfaceTitle = {}) {
    super(data);

    this.text = data.text ?? '';
    this.countUsages = data.countUsages ?? 0;
  }

  prepareForServer() {
    const data = super.prepareForServer() as TitleFromServer;

    data.id = this.id;
    data.text = this.text;

    return data;
  }

  static async parseFromServer(data: TitleFromServer): Promise<Title> {
    const entity = await super.parseFromServer(data) as Title;

    return entity;
  }
}
