<template>
  <v-col
    cols="12"
    xl="3"
    lg="4"
    sm="6"
  >
    <v-dialog
      v-model="dialogEdit"
      max-width="1000"
      persistent
    >
      <template #activator="{ on }">
        <v-card
          v-on="on"
        >
          <v-row
            dense
            class="px-3"
          >
            <v-col class="shrink font-weight-bold text-no-wrap">
              {{ transaction.title.text }}
            </v-col>
            <v-col
              class="text-end"
            >
              <base-amount
                :class="classes"
                :value="transaction.amount"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            class="px-3 body-2"
          >
            <v-col
              class="shrink text--secondary"
            >
              <base-date :value="transaction.date" />
            </v-col>
            <v-col
              class="shrink text--secondary"
            >
              -
            </v-col>
            <v-col
              class="text--secondary"
            >
              <base-account-name
                :account="transaction.account"
                :options-chip="{ small: true }"
              />
            </v-col>
            <v-col class="shrink text-end">
              <base-category-name
                :category="transaction.category"
                :options-chip="{ small: true }"
              />
            </v-col>
          </v-row>
          <!--      <v-row-->
          <!--        dense-->
          <!--        class="px-3"-->
          <!--      >-->
          <!--        <v-col class="shrink">-->
          <!--          <base-amount-->
          <!--            :class="classes"-->
          <!--            :value="transaction.amount"-->
          <!--          />-->
          <!--        </v-col>-->
          <!--        <v-col-->
          <!--          class="text-end"-->
          <!--        >-->
          <!--          <base-date :value="transaction.date" />-->
          <!--        </v-col>-->
          <!--      </v-row>-->
        </v-card>
      </template>

      <v-card>
        <v-card-title>
          {{ $t('transaction.edit') }}
        </v-card-title>

        <v-card-text>
          <v-container class="py-0">
            <item-transaction
              ref="item-transaction"
              v-model="itemCurrent"
            />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            color="error"
            @click="openDeleteItem"
          >
            {{ $t('app.delete') }}
          </v-btn>

          <v-spacer />

          <v-btn
            text
            @click="dialogEdit = false"
          >
            {{ $t('app.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="editItem"
          >
            {{ $t('app.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ $t('transaction.delete') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            {{ $t('transaction.deleteConfirmation', {name: itemCurrent.title}) }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogDelete = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="error"
              @click="deleteItem"
            >
              {{ $t('app.delete') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import BaseCategoryName from '@/modules/category/components/base/base-category-name';
import BaseDate from '@/modules/app/components/base/base-date';
import BaseAmount from '@/modules/app/components/base/base-amount';
import { Transaction } from '@/modules/transactions/transaction.model';
import ItemTransaction from '@/modules/transactions/components/item-transaction';
import { ServiceTransactions } from '@/modules/transactions/transactions.service';
import BaseAccountName from '@/modules/account/components/base/base-account-name';

export default {
  name: 'Transaction',
  components: {
    BaseAccountName,
    ItemTransaction,
    BaseAmount,
    BaseDate,
    BaseCategoryName,
  },
  props: {
    transaction: {
      required: true,
      type: Transaction,
    },
  },
  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      itemCurrent: {},
    };
  },
  computed: {
    classes() {
      return {
        'success--text': this.transaction.category.income === true,
        'error--text': this.transaction.category.income === false,
      };
    },
  },
  watch: {
    dialogEdit() {
      if (this.dialogEdit === true) {
        this.itemCurrent = new Transaction(this.transaction);
      }
    },
  },
  methods: {
    async editItem() {
      const transaction = await ServiceTransactions.edit({ transaction: this.itemCurrent });

      this.$emit('update', transaction);

      this.dialogEdit = false;
    },
    openDeleteItem() {
      this.dialogDelete = true;
    },
    async deleteItem() {
      await ServiceTransactions.delete({ transaction: this.itemCurrent });

      this.$emit('delete');

      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>

</style>
