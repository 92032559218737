import { ServiceData } from '@/modules/data/data.service';

export const moduleData = {
  namespaced: true,
  state: {
    basicStatisticsOverTime: null,
    basicStatisticsOverTimeFiltered: null,

    dataIncomeOutcomePerMonth: null,
    dataIncomeOutcomePerMonthObject: null,

    dataIncomeOutcomePerMonthFiltered: null,
    dataIncomeOutcomePerMonthObjectFiltered: null,

    dataIncomeOutcomePerWeek: null,
  },
  getters: {
    processedDataIncomeOutcomePerMonth(state) {
      return ServiceData.processDataIncomeOutcomePerMonthCategories({
        data: state.dataIncomeOutcomePerMonth,
        dateGroupedBy: 0,
      });
    },
    processedDataCapitalPerMonth(state, getters) {
      return ServiceData.processDataCapitalPerMonth({
        data: getters.processedDataIncomeOutcomePerMonth,
      });
    },
    processedDataIncomeOutcomePerWeek(state) {
      return ServiceData.processDataIncomeOutcomePerMonthCategories({
        data: state.dataIncomeOutcomePerWeek,
        dateGroupedBy: 1,
      });
    },
    processedDataCapitalPerWeek(state, getters) {
      return ServiceData.processDataCapitalPerMonth({
        data: getters.processedDataIncomeOutcomePerWeek,
      });
    },
  },
  mutations: {
    setBasicStatisticsOverTime(state, data) {
      state.basicStatisticsOverTime = data;
    },
    setBasicStatisticsOverTimeFiltered(state, data) {
      state.basicStatisticsOverTimeFiltered = data;
    },
    setDataIncomeOutcomePerMonth(state, dataIncomeOutcomePerMonth) {
      state.dataIncomeOutcomePerMonthObject = dataIncomeOutcomePerMonth.reduce((obj, value) => {
        obj[value.dateGroupedBy] = value;
        return obj;
      }, {});

      state.dataIncomeOutcomePerMonth = dataIncomeOutcomePerMonth;
    },
    setDataIncomeOutcomePerMonthFiltered(state, dataIncomeOutcomePerMonth) {
      if (Array.isArray(dataIncomeOutcomePerMonth)) {
        state.dataIncomeOutcomePerMonthObjectFiltered = dataIncomeOutcomePerMonth.reduce((obj, value) => {
          obj[value.dateGroupedBy] = value;
          return obj;
        }, {});
      }

      state.dataIncomeOutcomePerMonthFiltered = dataIncomeOutcomePerMonth;
    },
    setDataIncomeOutcomePerWeek(state, { dataIncomeOutcomePerWeek }) {
      state.dataIncomeOutcomePerWeek = dataIncomeOutcomePerWeek;
    },
  },
  actions: {
    async setDataIncomeOutcomePerMonth({ commit }, dataIncomeOutcomePerMonth) {
      commit('setDataIncomeOutcomePerMonth', dataIncomeOutcomePerMonth);
    },
    async setDataIncomeOutcomePerWeek({ commit }, { dataIncomeOutcomePerWeek }) {
      commit('setDataIncomeOutcomePerWeek', {
        dataIncomeOutcomePerWeek,
      });
    },
  },
};
