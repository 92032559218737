<template>
  <v-select
    :value="value"
    :items="categories"
    :label="hideLabel ? null : label"
    item-value="id"
    item-text="title"
    return-object
    :prepend-icon="hideIconPreprended ? null : 'fas fa-list'"
    dense
    :multiple="multiple"
    :single-line="singleLine"
    hide-details
    :clearable="clearable"
    @input="$emit('input', $event)"
  >
    <template #item="{ item, attrs }">
      <v-list-item-action v-if="multiple">
        <v-checkbox
          :input-value="attrs.inputValue"
          color="primary"
        />
      </v-list-item-action>
      <v-list-item-content class="py-0">
        <v-list-item-title>
          <v-chip
            :color="item.color"
            dark
          >
            {{ item.title }}
          </v-chip>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-content class="py-0 text-right">
        <v-list-item-title>
          <span
            v-if="item.income === true"
            class="success--text"
          >
            {{ $t('app.income') }}
          </span>
          <span
            v-else
            class="error--text"
          >
            {{ $t('app.outcome') }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <template #selection="{ item, index }">
      <base-category-name
        v-if="index === 0"
        :category="item"
        :clickable="false"
      />
      <span
        v-if="index === 1"
        class="caption"
      >
        {{ $t('category.multipleSelected', {count: value.length - 1}) }}
      </span>
    </template>

    <template
      v-if="displayAdd === true"
      #append-outer
    >
      <create-category @created="$emit('input', $event)">
        <template #default="{ on }">
          <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </template>
      </create-category>
    </template>
  </v-select>
</template>

<script>
import { Category } from '@/modules/category/category.model';
import BaseCategoryName from '@/modules/category/components/base/base-category-name';
import { i18n } from '@/i18n';
import CreateCategory from '@/modules/category/components/category/create-category';
import { comparatorCategories } from '@/helpers';

export default {
  name: 'BaseSelectCategory',
  components: { BaseCategoryName, CreateCategory },
  props: {
    value: {
      required: false,
      type: Category | Array,
      default: undefined,
    },
    items: {
      required: false,
      type: Array,
      default: undefined,
    },
    displayAdd: {
      required: false,
      type: Boolean,
      default: true,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true,
    },
    hideIconPreprended: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideLabel: {
      required: false,
      type: Boolean,
      default: false,
    },
    showMetaCategories: {
      required: false,
      type: Boolean,
      default: false,
    },
    singleLine: {
      required: false,
      type: Boolean,
      default: false,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: i18n.tc('category.title', 1),
    },
  },
  computed: {
    categories() {
      let result = this.items !== undefined
        ? this.items.sort(comparatorCategories)
        : Object.values(this.$store.state.moduleCategory.categories).sort(comparatorCategories);

      if (this.showMetaCategories === false) {
        result = result.filter((category) => category.id >= 0);
      }

      return result;
    },
  },
};
</script>

<style scoped>

</style>
