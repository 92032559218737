<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <table-import-settings />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <import />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <import-table />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Import from '@/modules/import/components/import';
import ImportTable from '@/modules/import/components/table-import-data/table-import-data';
import { useImport } from '@/modules/import/import.service';
import TableImportSettings from '@/modules/import/components/table-import-settings/table-import-settings';

export default defineComponent({
  name: 'ViewImport',
  components: { TableImportSettings, ImportTable, Import },
  setup() {
    useImport.readImportData();

    return {};
  },
});
</script>

<style scoped>

</style>
