<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            ref="title"
            v-model="value.title"
            prepend-icon="fas fa-tags"
            autofocus
            :label="$t('account.item.title')"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            v-model="value.amountInitial"
            prepend-icon="fas fa-coins"
            :label="$t('account.item.amountInitial')"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            v-model="value.iban"
            prepend-icon="fas fa-credit-card"
            :label="$t('account.item.iban')"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { Account } from '@/modules/account/account.model';

export default {
  name: 'ItemAccount',
  props: {
    value: {
      type: Account,
      required: true,
    },
  },
  methods: {
    focus() {
      this.$refs.title.focus();
    },
  },
};
</script>

<style scoped>

</style>
