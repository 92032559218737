import { merge } from 'lodash-es';
import { Options } from 'highcharts';
import { displayMoney } from '@/helpers';
import { format } from 'date-fns';

export const getConfigChart = (options: Options): Options => (merge({
  credits: {
    enabled: false,
  },
  chart: {
  },
  rangeSelector: {
    buttons: [{
      type: 'month',
      count: 6,
      text: '6m',
      title: 'View 6 months',
    }, {
      type: 'ytd',
      text: 'YTD',
      title: 'View year to date',
    }, {
      type: 'year',
      count: 1,
      text: '1y',
      title: 'View 1 year',
    }, {
      type: 'all',
      text: 'All',
      title: 'View all',
    }],
    selected: 2,
  },
  tooltip: {
    shared: true,
    split: false,
    valueDecimals: 2,
    valueSuffix: ' €',
  },
} as Options, options));

export const formatterTooltip = function () {
  const stringPoints = this.points.reduce((result, point) => {
    const amount = point.y.toFixed(2);
    result += `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: <b>${displayMoney({ value: amount })}</b><br>`;
    return result;
  }, '');

  return `${format(new Date(this.x), 'MMMM yyyy')}<br><br>${stringPoints}`;
};
