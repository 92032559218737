

























































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import BaseDate from '@/modules/app/components/base/base-date.vue';
import BaseAmount from '@/modules/app/components/base/base-amount.vue';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category.vue';
import useVuelidate from '@vuelidate/core';
import {
  helpers,
  minValue, required,
} from '@vuelidate/validators';
import BaseSelectTitle from '@/modules/title/components/base/base-select-title.vue';
import { store } from '@/modules/app/app.store';

const regexAmazonOrder = /\d{3}-\d{7}-\d{7}/;

export default defineComponent({
  name: 'TableImportDataTransactionsItem',
  components: {
    BaseSelectTitle, BaseSelectCategory, BaseAmount, BaseDate,
  },
  props: {
    transaction: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const infoOpen = ref(false);
    const deleteOpen = ref(false);

    const amazonOrder = computed(() => {
      if (
        props.transaction.comment?.startsWith('AMAZON EU S.A R.L') === false && 
        props.transaction.comment?.startsWith('AMAZON PAYMENTS EUROPE S.C.A') === false
      ) {
        return false;
      }

      console.log(regexAmazonOrder.exec(props.transaction.comment));
      const match = regexAmazonOrder.exec(props.transaction.comment);
      return match === null ? false : match[0];
    })

    const classes = computed(() => {
      const result = {
        'warning lighten-5': props.transaction.category.id < 0 || helpers.req(props.transaction.title) === false,
      };

      return result;
    });

    useVuelidate({
      category: {
        id: {
          minValue: minValue(0),
        },
      },
      title: {
        required,
      },
    }, props.transaction);
    return {
      infoOpen,
      deleteOpen,
      classes,
      amazonOrder,
      deleteItem: (transaction) => {
        store.commit('moduleImport/deleteTransaction', transaction);
        deleteOpen.value = false;
      },
    };
  },
});
