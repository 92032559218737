import { Title } from '@/modules/title/title.model';
import { store } from '@/modules/app/app.store';
import { apolloClient } from '@/vue-apollo';

import {
  mutateTitleCreate,
  mutateTitleDelete,
  mutateTitleEdit,
  queryTitle,
  queryTitlesPage,
} from '@/modules/title/title.graphql';
import { InterfaceTitle } from '@/modules/title/title.type';

export class ServiceTitle {
  static async loadPage({
    page, itemsPerPage, sortBy, sortDesc, optionsFilter = {}, initialize = false, saveToStore = true,
  }: { page: number; itemsPerPage: number; sortBy: string[]; sortDesc: boolean[]; optionsFilter: {}; initialize: boolean, saveToStore: boolean }) {
    if (initialize && saveToStore === true) {
      await store.dispatch('moduleTitle/setTitles', {
        titles: [],
      });
    }

    const optionsFilterProcessed = Object.entries(optionsFilter).reduce((obj, [key, value]) => {
      if (value !== null) {
        obj[key] = value;
      }
      return obj;
    }, {});

    const response = await apolloClient.query({
      query: queryTitlesPage,
      variables: {
        page,
        count: itemsPerPage,
        sortBy,
        sortDesc,
        filters: optionsFilterProcessed,
      },
      fetchPolicy: 'no-cache',
    }) as {data: {titles: Array<InterfaceTitle>, countTitles: number}};

    const titles = response.data.titles.map((title: {}) => new Title(title));

    if (saveToStore) {
      await store.commit('moduleTitle/setTitles', {
        titles,
      });

      await store.commit('moduleTitle/setCountTotalTitles', {
        count: response.data.countTitles,
      });
    }

    return { titles, countTotal: response.data.countTitles };
  }

  static async load({ id }: { id: string}): Promise<Title> {
    const response = await apolloClient.query({
      query: queryTitle,
      variables: {
        id,
      },
    });

    return new Title(response.data.title);
  }

  static async create({ title: titlePassed }: { title: Title}): Promise<Title> {
    console.log('creating title', titlePassed, JSON.stringify(titlePassed));

    const response = await apolloClient.mutate({
      mutation: mutateTitleCreate,
      variables: {
        title: titlePassed.prepareForServer(),
      },
    });

    return new Title(response.data.titleCreate.title);
  }

  static async edit({ title }: { title: Title}) {
    console.log('editing title', title, JSON.stringify(title));

    const response = await apolloClient.mutate({
      mutation: mutateTitleEdit,
      variables: {
        title: title.prepareForServer(),
      },
    });
  }

  static async delete({ title, titleReplacement }: { title: Title, titleReplacement?: Title}) {
    console.log('deleting title', title, JSON.stringify(title));

    const response = await apolloClient.mutate({
      mutation: mutateTitleDelete,
      variables: {
        idTitle: title.id,
        idTitleReplacement: titleReplacement === undefined ? undefined : titleReplacement.id,
      },
    });
  }
}
