import { Person } from '@/modules/person/person.model';
import { Account } from '@/modules/account/account.model';
import Big from 'big.js';
import { parseISO } from 'date-fns';
import { Entity } from '@/modules/app/entity/entity.model';
import { InterfaceTransfer, TransferFromServer } from '@/modules/transfer/transfer.type';
import { Title } from '@/modules/title/title.model';
import { store } from '@/modules/app/app.store';

export class Transfer extends Entity implements InterfaceTransfer {
  title?: string;

  amount?: Big;

  date: Date;

  dateCreated?: Date;

  dateChanged?: Date;

  comment?: string;

  accountSource?: Account;

  accountDestination?: Account;

  person?: Person;

  constructor(data: InterfaceTransfer = {}) {
    super(data);

    this.title = data.title;
    this.amount = data.amount;
    this.date = data.date;
    this.dateCreated = data.dateCreated;
    this.dateChanged = data.dateChanged;
    this.comment = data.comment;
    this.accountSource = data.accountSource;
    this.accountDestination = data.accountDestination;
    this.person = data.person;
  }

  prepareForServer() {
    const data = super.prepareForServer() as TransferFromServer;

    data.title = this.title;
    data.amount = this.amount.toString();
    data.date = this.date;
    data.comment = this.comment;
    data.accountNumber = this.accountNumber;
    data.fromImport = this.fromImport;
    data.accountSource = this.accountSource.id;
    data.accountDestination = this.accountDestination.id;
    data.person = this.person.id;

    return data;
  }

  static async parseFromServer(data: TransferFromServer): Promise<Transfer> {
    const entity = await super.parseFromServer(data) as Transfer;

    entity.amount = new Big(data.amount);
    entity.date = parseISO(data.date ?? '');
    entity.dateCreated = parseISO(data.dateCreated ?? '');
    entity.dateChanged = parseISO(data.dateChanged ?? '');
    entity.title = data.title;
    entity.accountSource = store.state.moduleAccount.accounts[data.accountSource.id];
    entity.accountDestination = store.state.moduleAccount.accounts[data.accountDestination.id];
    entity.person = store.state.modulePerson.persons[data.person.id];

    return entity;
  }
}
