import { computed, ref } from '@vue/composition-api';
import { store } from '@/modules/app/app.store';
import { Category } from '@/modules/category/category.model';
import { arrayHasEntries } from '@/helpers';
import { ServiceData } from '@/modules/data/data.service';
import { eachMonthOfInterval, isEqual } from 'date-fns';
import { Title } from '@/modules/title/title.model';

const dataIncomeOutcomePerMonthAll = computed(() => store.state.moduleData.dataIncomeOutcomePerMonth);
const dataIncomeOutcomePerMonthFiltered = ref(null);

const dataIncomeOutcomePerMonth = computed(() => {
  if (dataIncomeOutcomePerMonthFiltered.value !== null) {
    return dataIncomeOutcomePerMonthFiltered.value;
  }

  return dataIncomeOutcomePerMonthAll.value;
});

const categoriesPerMonth = computed(() => {
  if (dataIncomeOutcomePerMonth.value === null) {
    return null;
  }

  const categoriesUnique: Array<Category> = Array.from(new Set(dataIncomeOutcomePerMonth.value.map((month) => month.data.map((data) => data.category)).flat()));

  const monthsPrepared = dataIncomeOutcomePerMonth.value.map((monthData) => ({
    month: monthData.dateGroupedBy,
    data: monthData.data.reduce((obj, data) => {
      obj[data.category.id] = data.amount.lt(0) === true ? data.amount.mul(-1) : data.amount;
      return obj;
    }, {}),
  }));

  const result = [];

  for (let i = 0; i < categoriesUnique.length; i += 1) {
    const category = categoriesUnique[i];
    const dataCategory = {
      category,
      data: [],
    };

    const monthFirst = monthsPrepared[0].month;
    const monthLast = monthsPrepared[monthsPrepared.length - 1].month;

    const interval = eachMonthOfInterval({ start: monthFirst, end: monthLast });

    let indexMonthsPrepared = 0;
    for (let j = 0; j < interval.length; j += 1) {
      if (isEqual(interval[j], monthsPrepared[indexMonthsPrepared].month)) {
        const dataMonth = monthsPrepared[indexMonthsPrepared];
        const amount = dataMonth.data[category.id];

        dataCategory.data.push([dataMonth.month.getTime(), amount === undefined ? 0 : amount.toNumber()]);

        indexMonthsPrepared += 1;
      } else {
        dataCategory.data.push([interval[j].getTime(), 0]);
      }
    }

    result.push(dataCategory);
  }

  return result;
});

export const useDataCategories = () => ({
  categoriesPerMonth,

  async loadData({
    categoriesExcluded,
    categoriesIncluded,
    titlesExcluded,
  }: {
    categoriesExcluded?: Array<Category>,
    categoriesIncluded?: Array<Category>
    titlesExcluded?: Array<Title>,
  } = {}) {
    if (arrayHasEntries(categoriesExcluded) === false && arrayHasEntries(categoriesIncluded) === false) {
      dataIncomeOutcomePerMonthFiltered.value = null;
    } else {
      dataIncomeOutcomePerMonthFiltered.value = await ServiceData.loadDataIncomeOutcomePerMonthCategories({
        categoriesExcluded,
        categoriesIncluded,
        titlesExcluded,
      });
    }
  },
});
