<template>
  <v-chip
    :color="category.color"
    dark
    v-bind="optionsChip"
    v-on.prevent=" clickable === true ? { click: clicked } : {}"
  >
    {{ category.title }}
  </v-chip>
</template>

<script>
import { Category } from '../../category.model';

export default {
  name: 'BaseCategoryName',
  props: {
    category: {
      required: true,
      type: Category,
    },
    optionsChip: {
      required: false,
      type: Object,
      default: () => ({ 'x-small': true }),
    },
    clickable: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clicked(event) {
      event.stopPropagation();

      this.$router.push({
        name: 'transactions',
        query: {
          filters__category: this.category.id,
        },
      }).catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
