








































































import { TypeSettingGroupCategory } from '@/modules/category/category.types';
import {
  computed,
  defineComponent, PropType, ref, watch,
} from '@vue/composition-api';
import BaseSelectCategory from '@/modules/category/components/base/base-select-category.vue';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';

const validatorArrayValue = (value, vm) => {
  if (vm.value.type !== 'include' || vm.value.type !== 'exclude') {
    return true;
  }

  return Array.isArray(value) && value.length > 0;
};

export default defineComponent({
  name: 'ItemSettingGroupCategory',
  components: { BaseSelectCategory },
  props: {
    value: {
      type: Object as PropType<TypeSettingGroupCategory>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const typesSetting = ref([
      { text: 'Wert', value: 'value' },
      { text: 'Inkludieren', value: 'include' },
      { text: 'Exkludieren', value: 'exclude' }]);
    const operatorsSetting = ref([{ text: 'ist gleich', value: 'isEqual' }]);
    const fieldsSetting = ref([{ text: 'Einkommen', value: 'income' }]);
    const booleanValues = ref([{ text: 'Ja', value: true }, { text: 'Nein', value: false }]);

    watch(computed(() => props.value.type), () => {
      emit('input', { type: props.value.type });
    });

    return {
      typesSetting,
      operatorsSetting,
      fieldsSetting,
      booleanValues,
      vuelidate: useVuelidate({
        type: {
          required,
        },
        field: {
          requiredIf: requiredIf(() => props.value.type === 'value'),
        },
        operator: {
          requiredIf: requiredIf(() => props.value.type === 'value'),
        },
        value: {
          required,
          validatorArrayValue,
        },
      }, computed(() => props.value)),
    };
  },
});
