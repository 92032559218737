var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.importSettings,"sort-by":"countUsed","sort-desc":true},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount !== null)?_c('base-amount',{attrs:{"value":item.amount}}):_vm._e()]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('base-category-name',{attrs:{"category":item.category}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-5"},[_c('edit-import-settings',{attrs:{"import-setting":item}})],1),_c('v-col',[_c('delete-import-settings',{attrs:{"import-setting":item}})],1)],1)]}},{key:"item.lastTransaction.date",fn:function(ref){
var item = ref.item;
return [(item.lastTransaction !== null)?_c('base-date',{attrs:{"value":item.lastTransaction.date}}):_vm._e()]}}])})],1)])],1),_c('v-row',[_c('v-col',[_c('create-import-settings')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }