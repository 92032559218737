







































  import Big from 'big.js';
  import { Category } from '@/modules/category/category.model';
import BaseAmount from '@/modules/app/components/base/base-amount.vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import {mean, median, std} from 'mathjs';

const getDataForlastMonths = (data, months) => {
    return {
        mean: new Big(mean(data.slice(months * -1).map(item => item[1]))),
        median: new Big(median(data.slice(months * -1).map(item => item[1]))),
        std: new Big(std(data.slice(months * -1).map(item => item[1]))),
    }
}

  export default defineComponent({
    name: 'DataStatistics',
    components: {BaseAmount},
    props: {
        data: {
            required: true,
        }
    },
    setup(props) {
        const statistic = ref('mean');

        const dataPrepared = computed(() => {
            if (props.data === null) {
                return [];
            }

            const data = props.data.map(({category, data}: {category: Category, data: Array<unknown>}) => {
                return {
                    category: category,
                    'all': getDataForlastMonths(data, 0),
                    'lastYear': getDataForlastMonths(data, 12),
                    'lastHalfYear': getDataForlastMonths(data, 6),
                    'lastQuarter': getDataForlastMonths(data, 3),
                }
            })

            // data.push({
            //     cateogry: 'Insgesamt',
            //     'all': getDataForlastMonths(data, 12)
            // });

            console.log('data', data)

            return data;
        });

        return {
            headers: [
                {
                    text: 'Kategorie',
                    value: 'category',
                },
                {
                    text: 'Allzeit',
                    value: 'all',
                },
                {
                    text: 'Letztes Jahr',
                    value: 'lastYear',
                },
                {
                    text: 'Halbes Jahr',
                    value: 'lastHalfYear',
                },
                {
                    text: 'Letztes Quartal',
                    value: 'lastQuarter',
                },
            ],
            dataPrepared,
            statistic,
        };
    },
  });
  