











import { defineComponent, ref, watch } from '@vue/composition-api';
import BaseDetailsMonth from '@/modules/data/components/base/base-details-month.vue';
import { ServiceData } from '@/modules/data/data.service';
import { ServiceChartData } from '@/modules/data/chart/chart-data.service';
import { format, isValid } from 'date-fns';
import { i18n } from '@/i18n';

export default defineComponent({
  name: 'DetailsMonthCurrent',
  components: { BaseDetailsMonth },
  setup(props, { emit }) {
    ServiceData.loadDataIncomeOutcomePerMonthCategories();

    const chart = ref(null);

    const updateTitle = (month) => {
      if (month !== undefined && month !== null && isValid(month.dateGroupedBy)) {
        emit('update-title', i18n.t('data.detailsMonthCurrentSpecific', {
          date: format(month.dateGroupedBy, 'MMMM yyyy'),
        }));
      }
    };

    watch(ServiceChartData.currentMonthAll, (value) => {
      updateTitle(value);
    });

    updateTitle(ServiceChartData.currentMonthAll.value);

    return {
      month: ServiceChartData.currentMonthAll,
      chart,
      redraw() {
        chart.value.redraw();
      },
    };
  },
});
