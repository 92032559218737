import { computed, ref } from '@vue/composition-api';
import { mutateUserSettingSet } from '@/modules/app/app.graphql';
import { apolloClient } from '@/vue-apollo';
import Vue from 'vue';

type TypeSettingValue = string | number | boolean;
type TypeSettings = Record<string, TypeSettingValue>;

const settingsStored = ref<Record<string, TypeSettingValue>>({});
const isModeEdit = ref(false);

export const useUserSettings = () => ({
  getSetting: (key: string) => computed(() => settingsStored.value[key]),
  async setSetting(key: string, value: TypeSettingValue) {
    Vue.set(settingsStored.value, key, value);

    await apolloClient.mutate({
      mutation: mutateUserSettingSet,
      variables: {
        userSettingSet: { key, value: JSON.stringify(value) },
      },
    });
  },
  setSettings: (settings: TypeSettings) => {
    settingsStored.value = settings;
  },
  getIsModeEdit() {
    return isModeEdit;
  },
});
