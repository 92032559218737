import gql from 'graphql-tag';
import { fragments } from '@/modules/app/app.fragments';

export const queryTransfersPage = gql`query transfers($page: Int!, $count: Int!, $sortBy: [String]!, $sortDesc: [Boolean]!) {
  transfers(page: $page, count: $count, sortBy: $sortBy, sortDesc: $sortDesc) {
    ...transfer
  }
  countTransfers
}
${fragments.transfer}
`;

export const mutateTransferCreate = gql`mutation transferCreate($transfer: TransferInput!) {
  transferCreate(transfer: $transfer) {
    transfer {
      ...transfer
    }
  }
}
${fragments.transfer}
`;

export const mutateTransferEdit = gql`mutation transferEdit($transfer: TransferInput!) {
  transferEdit(transfer: $transfer) {
    transfer {
    ...transfer
    }
  }
}
${fragments.transfer}
`;

export const mutateTransferDelete = gql`mutation transferDelete($idTransfer: ID!) {
  transferDelete(idTransfer: $idTransfer) {
    success
  }
}`;
