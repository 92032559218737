import { Category } from '@/modules/category/category.model';
import Vue from 'vue';
import { comparatorCategories } from '@/helpers';

export const moduleCategory = {
  namespaced: true,
  state: {
    categories: null,
  },
  getters: {
    titles(state) {
      return Object.values(state.categories).reduce((obj, category) => {
        obj[category.title] = true;
        return obj;
      }, {});
    },
    categoriesAll(state) {
      return Object.values(state.categories).sort(comparatorCategories);
    },
    categories(state, getters) {
      return getters.categoriesAll.filter((category) => category.id >= 0);
    },
  },
  mutations: {
    setCategories(state, { categories }: {categories: Category[]}) {
      categories.push(new Category({
        id: -1,
        income: false,
        title: 'Unbekannte Ausgabe',
      }));
      categories.push(new Category({
        id: -2,
        income: true,
        title: 'Unbekannte Einnahme',
      }));

      state.categories = categories.reduce((obj, category) => {
        if (category.id !== undefined) {
          obj[category.id] = category;
        }
        return obj;
      }, {} as {[key: number]: Category});
    },
    addCategory(state, { category }: {category: Category}) {
      if (category.id !== undefined) {
        Vue.set(state.categories, category.id, category);
      }
    },
    editCategory(state, { category }: {category: Category}) {
      if (category.id !== undefined) {
        Vue.set(state.categories, category.id, category);
      }
    },
    removeCategory(state, { category }: {category: Category}) {
      Vue.delete(state.categories, category.id);
    },
  },
  actions: {
    async setCategories({ commit }, { categories }: {categories: Category[]}) {
      commit('setCategories', {
        categories,
      });
    },
    async addCategory({ commit }, { category }: {category: Category}) {
      commit('addCategory', {
        category,
      });
    },
    async editCategory({ commit }, { category }: {category: Category}) {
      commit('editCategory', {
        category,
      });
    },
    async removeCategory({ commit }, { category }: {category: Category}) {
      commit('removeCategory', {
        category,
      });
    },
  },
};
