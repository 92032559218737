



































import {
  computed, defineComponent, ref,
} from '@vue/composition-api';
import { ServiceData } from '@/modules/data/data.service';
import vuetify from '@/plugins/vuetify';
import { Options } from 'highcharts';
import { formatterTooltip, getConfigChart } from '@/modules/data/chart/base-config-chart';
import BaseChart from '@/modules/data/chart/base-chart.vue';
import BaseDetailsMonth from '@/modules/data/components/base/base-details-month.vue';
import BudgetHeaderActions from '@/modules/data/components/budget-header-actions.vue';
import { format } from 'date-fns';
import { useDataBudget } from '@/modules/data/composables/useDataBudget';

export default defineComponent({
  name: 'Budget',
  components: {
    BaseDetailsMonth,
    BaseChart,
  },
  setup(props, { emit }) {
    ServiceData.loadBasicStatisticsOverTime();
    ServiceData.loadDataIncomeOutcomePerMonthCategories();

    const {
      balancePerMonth, incomePerMonth, outcomePerMonth, getMonth,
    } = useDataBudget();

    const isOpenDetails = ref(false);
    const currentMonth = ref<Record<string, unknown> | null>(null);
    const chart = ref(null);

    emit('update-header-actions', BudgetHeaderActions);

    const openDetailsMonth = (timestamp) => {
      currentMonth.value = getMonth(timestamp);
      isOpenDetails.value = true;
    };

    const titleDetails = computed(() => {
      if (currentMonth.value === null) {
        return;
      }

      return format(currentMonth.value.dateGroupedBy, 'MMMM yyyy');
    });

    const options = computed<Options>(() => getConfigChart({
      chart: {
        height: 500,
        events: {
          click(event) {
            const point = this.series[0].searchPoint(event, true);
            openDetailsMonth(point.x);
          },
        },
      },
      plotOptions: {
        series: {
          events: {
            click(event) {
              openDetailsMonth(event.point.x);
            },
          },
        },
      },
      xAxis: {
        type: 'datetime',
        endOnTick: true,
      },
      yAxis: [{
        height: '70%',
        top: '30%',
      }, {
        labels: {
          enabled: false,
        },
        height: '30%',
        plotLines: [
          {
            color: vuetify.framework.theme.themes.light.info,
            value: 0,
            width: 1,
            zIndex: 3,
          },
        ],
      }],
      series: [
        {
          type: 'column',
          name: 'Einnahmen',
          data: incomePerMonth.value,
          color: vuetify.framework.theme.themes.light.success,
        },
        {
          type: 'column',
          name: 'Ausgaben',
          data: outcomePerMonth.value,
          color: vuetify.framework.theme.themes.light.error,
        },
        {
          type: 'line',
          name: 'Saldo',
          data: balancePerMonth.value,
          color: vuetify.framework.theme.themes.light.success,
          yAxis: 1,
          zones: [{
            value: 0,
            color: vuetify.framework.theme.themes.light.error,
          }],
        },
      ],
      tooltip: {
        formatter: formatterTooltip,
      },
    }));

    return {
      options,
      isOpenDetails,
      currentMonth,
      titleDetails,
      chart,
      redraw() {
        chart.value.redraw();
      },
    };
  },
});
