import { Category } from '@/modules/category/category.model';
import { Person } from '@/modules/person/person.model';
import { Account } from '@/modules/account/account.model';
import Big from 'big.js';
import { parseISO } from 'date-fns';
import { Title } from '@/modules/title/title.model';
import { Entity } from '@/modules/app/entity/entity.model';
import { InterfaceTransaction, TransactionFromServer } from '@/modules/transactions/transaction.type';
import { store } from '@/modules/app/app.store';
import { ImportSetting } from '@/modules/import/import-setting.model';

export class Transaction extends Entity implements InterfaceTransaction {
  title?: Title;

  amount?: Big;

  date?: Date;

  dateCreated?: Date;

  dateChanged?: Date;

  comment?: string;

  accountNumber?: string;

  fromImport?: boolean;

  category?: Category;

  account?: Account;

  person?: Person;

  importSetting?: ImportSetting | null;

  constructor(data: InterfaceTransaction = {}) {
    super(data);

    this.title = data.title;
    this.amount = data.amount;
    this.date = data.date;
    this.dateCreated = data.dateCreated;
    this.dateChanged = data.dateChanged;
    this.comment = data.comment;
    this.accountNumber = data.accountNumber;
    this.fromImport = data.fromImport;
    this.category = data.category;
    this.account = data.account;
    this.person = data.person;
    this.importSetting = data.importSetting;
  }

  prepareForServer() {
    const data = super.prepareForServer() as TransactionFromServer;

    if (this.title !== null) {
      data.title = this.title.id;
    }

    data.amount = this.amount.toString();
    data.date = this.date;
    data.comment = this.comment;
    data.accountNumber = this.accountNumber;
    data.fromImport = this.fromImport;
    data.category = this.category.id;
    data.account = this.account.id;
    data.person = this.person.id;

    if (this.importSetting !== null && this.importSetting !== undefined) {
      data.importSetting = this.importSetting.id;
    }

    return data;
  }

  static async parseFromServer(data: TransactionFromServer): Promise<Transaction> {
    const entity = await super.parseFromServer(data) as Transaction;

    entity.amount = new Big(data.amount);
    entity.date = parseISO(data.date ?? '');
    entity.dateCreated = parseISO(data.dateCreated ?? '');
    entity.dateChanged = parseISO(data.dateChanged ?? '');

    if (data.title !== null) {
      entity.title = new Title(data.title);
    }

    entity.category = store.state.moduleCategory.categories[data.category.id];
    entity.account = store.state.moduleAccount.accounts[data.account.id];
    entity.person = store.state.modulePerson.persons[data.person.id];

    // if (data.importSetting !== null) {
    // entity.importSetting = store.state.moduleImport.importSettings[data.importSetting.id];
    // }

    return entity;
  }
}
