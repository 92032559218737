
































































































import {
  defineComponent, ref, watch,
} from '@vue/composition-api';
import { TypeSettingGroupCategory } from '@/modules/category/category.types';
import Vue, { PropType } from 'vue';
import CreateSettingGroupCategory
  from '@/modules/category/components/groupCategory/create-setting-group-category.vue';
import ItemSettingGroupCategory from '@/modules/category/components/groupCategory/item-setting-group-category.vue';

const settingsDefault = () => [{
  type: 'all',
}];

export default defineComponent({
  name: 'SettingsGroupCategory',
  components: { ItemSettingGroupCategory, CreateSettingGroupCategory },
  props: {
    value: {
      type: Array as PropType<Array<TypeSettingGroupCategory>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const settings = ref([...props.value]);

    if (settings.value.length === 0) {
      settings.value = settingsDefault();
    }

    watch(settings, () => {
      emit('input', settings.value);
    }, { deep: true, immediate: true });

    return {
      settings,
      add(settingNew) {
        settings.value.push(settingNew);
      },
      update(settingEdited, index) {
        Vue.set(settings.value, index, settingEdited);
      },
      deleteSetting(index) {
        Vue.delete(settings.value, index);
      },
      reset() {
        settings.value = settingsDefault();
      },
    };
  },
});
