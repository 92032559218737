<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="accounts"
      dense
    >
      <template #top>
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title>{{ $tc('account.title', 2) }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <base-date-picker
            v-model="date"
            class="mr-3"
            :properties="{'hide-details': true}"
          />
          <create-account />
        </v-toolbar>
      </template>

      <template #item.title="{ item }">
        <base-account-name
          :account="item"
          :options-chip="{ 'small': true, color: 'white' }"
        />
      </template>

      <template #item.countTransactions="{ item, value }">
        <base-date
          v-if="item.dateTransactionLast !== null"
          :value="item.dateTransactionLast"
        />
        <v-btn
          text
          x-small
          :to="{
            name: 'transactions',
            query: {
              filters__account: item.id,
            },
          }"
        >
          {{ value }}
        </v-btn>
      </template>

      <template #item.amountInitial="{ item }">
        <base-amount
          :value="item.amountInitial"
          :colored="true"
        />
      </template>

      <template #item.iban="{ value }">
        <template v-if="value === null">
          -
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>

      <template #item.amount="{ item }">
        <base-amount
          v-if="item.amount"
          :value="item.amount"
          :colored="true"
        />
      </template>

      <template #item.action="{ item }">
        <v-row
          no-gutters
          class="flex-nowrap"
        >
          <v-col class="mr-5">
            <v-icon
              small
              class="mr-2"
              color="info"
              @click="openEditItem(item)"
            >
              fas fa-edit
            </v-icon>
          </v-col>
          <v-col>
            <v-icon
              small
              color="error"
              @click="openDeleteItem(item)"
            >
              fas fa-trash
            </v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogEdit"
      max-width="1000"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('account.edit') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            <v-container class="py-0">
              <item-account
                ref="item-account"
                v-model="itemCurrent"
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogEdit = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="editItem"
            >
              {{ $t('app.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ $t('account.delete') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            {{ $t('account.deleteConfirmation', {name:itemCurrent.title}) }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogDelete = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="error"
              @click="deleteItem"
            >
              {{ $t('app.delete') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ServiceAccount } from '@/modules/account/account.service';
import ItemAccount from '@/modules/account/components/item-account';
import { Account } from '@/modules/account/account.model';
import BaseAmount from '@/modules/app/components/base/base-amount';
import BaseAccountName from '@/modules/account/components/base/base-account-name';
import BaseDate from '@/modules/app/components/base/base-date';
import BaseDatePicker from '@/modules/app/components/base/base-date-picker';
import CreateAccount from './create-account';

export default {
  name: 'TableAccounts',
  components: {
    BaseDatePicker,
    BaseDate,
    BaseAccountName,
    BaseAmount,
    ItemAccount,
    CreateAccount,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('account.item.title'),
          value: 'title',
          class: ['text-no-wrap'],
        },
        {
          text: this.$t('account.item.iban'),
          value: 'iban',
          align: 'right',
          class: ['text-no-wrap'],
        },
        {
          text: this.$t('account.item.countTransactions'),
          value: 'countTransactions',
          align: 'right',
          class: ['text-no-wrap'],
        },
        {
          text: this.$t('account.item.countTransfersSource'),
          value: 'countTransfersSource',
          align: 'right',
          class: ['text-no-wrap'],
        },
        {
          text: this.$t('account.item.countTransfersDestination'),
          value: 'countTransfersDestination',
          align: 'right',
          class: ['text-no-wrap'],
        },
        {
          text: this.$t('account.item.amountInitial'),
          value: 'amountInitial',
          align: 'right',
          class: ['text-no-wrap'],
        },
        {
          text: this.$t('account.item.amount'),
          value: 'amount',
          align: 'right',
          class: ['text-no-wrap'],
        },
        {
          value: 'action',
          sortable: false,
          align: 'right',
          width: '1px',
        },
      ],
      dialogDelete: false,
      dialogEdit: false,
      itemCurrent: {},
      date: new Date(),
    };
  },
  computed: {
    accounts() {
      return Object.values(this.$store.state.moduleAccount.accounts);
    },
  },
  watch: {
    date() {
      if (this.date !== null) {
        ServiceAccount.loadAmounts({ date: this.date });
      }
    },
  },
  created() {
    ServiceAccount.loadAmounts();
  },
  methods: {
    openEditItem(item) {
      this.itemCurrent = new Account(item);
      this.dialogEdit = true;
    },
    openDeleteItem(item) {
      this.itemCurrent = item;
      this.dialogDelete = true;
    },
    async editItem() {
      if (this.itemCurrent.iban === '') {
        this.itemCurrent.iban = null;
      }

      if (typeof this.itemCurrent.iban === 'string') {
        this.itemCurrent.iban = this.itemCurrent.iban.replaceAll(' ', '');
      }

      await ServiceAccount.edit({ account: this.itemCurrent });
      this.dialogEdit = false;
    },
    async deleteItem() {
      await ServiceAccount.delete({ account: this.itemCurrent });
      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>

</style>
