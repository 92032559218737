import { Category } from '@/modules/category/category.model';
import { store } from '@/modules/app/app.store';
import { apolloClient } from '@/vue-apollo';
import {
  mutateCategoryCreate,
  mutateCategoryDelete,
  mutateCategoryEdit,
} from '@/modules/category/category.graphql';

export class ServiceCategory {
  static async create({ category: categoryPassed }: { category: Category}): Promise<Category> {
    console.log('creating category', categoryPassed, JSON.stringify(categoryPassed));

    const response = await apolloClient.mutate({
      mutation: mutateCategoryCreate,
      variables: {
        category: categoryPassed,
      },
    });

    const category = new Category(response.data.categoryCreate.category);

    await store.dispatch('moduleCategory/addCategory', {
      category: new Category(response.data.categoryCreate.category),
    });

    console.warn('response', response);
    return category;
  }

  static async edit({ category }: { category: Category}) {
    console.log('editing category', category, JSON.stringify(category));

    const response = await apolloClient.mutate({
      mutation: mutateCategoryEdit,
      variables: {
        category,
      },
    });

    await store.dispatch('moduleCategory/editCategory', {
      category,
    });

    console.warn('response', response);
  }

  static async delete({ category }: { category: Category}) {
    console.log('deleting category', category, JSON.stringify(category));

    const response = await apolloClient.mutate({
      mutation: mutateCategoryDelete,
      variables: {
        idCategory: category.id,
      },
    });

    await store.dispatch('moduleCategory/removeCategory', {
      category,
    });

    console.warn('response', response);
  }
}
