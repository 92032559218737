import Big from 'big.js';
import { parseISO } from 'date-fns';

export class Account {
  id?: number;

  title?: string;

  amountInitial: Big = new Big(0);

  countTransactions?: number;

  countTransfersSource?: number;

  countTransfersDestination?: number;

  dateTransactionLast?: Date;

  iban: string | null;

  constructor({
    id,
    title,
    amountInitial,
    countTransactions,
    countTransfersSource,
    countTransfersDestination,
    dateTransactionLast,
    iban = null,
  }: {
    id?: number;
    title?: string;
    amountInitial?: Big | string;
    countTransactions?: number;
    countTransfersSource?: number;
    countTransfersDestination?: number;
    dateTransactionLast?: Date | string;
    iban?: string | null;
  } = {}) {
    this.id = id;
    this.title = title;
    this.countTransactions = countTransactions;
    this.countTransfersSource = countTransfersSource;
    this.countTransfersDestination = countTransfersDestination;
    this.iban = iban;

    if (typeof dateTransactionLast === 'string') {
      this.dateTransactionLast = parseISO(dateTransactionLast);
    } else if (typeof dateTransactionLast === 'object') {
      this.dateTransactionLast = dateTransactionLast;
    }

    if (typeof amountInitial === 'string') {
      this.amountInitial = new Big(amountInitial);
    } else if (amountInitial instanceof Big) {
      this.amountInitial = amountInitial;
    }
  }
}
