import gql from 'graphql-tag';
import { fragments } from '@/modules/app/app.fragments';

export const queryTransactionsPage = gql`query transactions($page: Int!, $count: Int!, $sortBy: [String]!, $sortDesc: [Boolean]!, $filters: TransactionFilters) {
  transactions(page: $page, count: $count, sortBy: $sortBy, sortDesc: $sortDesc, filters: $filters) {
    ...transaction
  }
  countTransactions(filters: $filters)
}
${fragments.transaction}
`;

export const mutateTransactionCreate = gql`mutation transactionCreate($transaction: TransactionInput!) {
  transactionCreate(transaction: $transaction) {
    transaction {
      ...transaction
    }
  }
}
${fragments.transaction}
`;

export const mutateTransactionEdit = gql`mutation transactionEdit($transaction: TransactionInput!) {
  transactionEdit(transaction: $transaction) {
    transaction {
    ...transaction
    }
  }
}
${fragments.transaction}
`;

export const mutateTransactionDelete = gql`mutation transactionDelete($idTransaction: ID!) {
  transactionDelete(idTransaction: $idTransaction) {
    success
  }
}`;
