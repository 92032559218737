import { Transfer } from '@/modules/transfer/transfer.model';

export const moduleTransfer = {
  namespaced: true,
  state: {
    transfers: [],
    countTotalTransfers: 0,

    lastInputs: {},
  },
  mutations: {
    setTransfers(state, { transfers }: {transfers: Transfer[]}) {
      state.transfers = transfers;
    },
    setCountTotalTransfers(state, { count }: {count: number}) {
      state.countTotalTransfers = count;
    },
    setLastInputs(state, { lastInputs }: {lastInputs: {}}) {
      state.lastInputs = lastInputs;
    },
    // addAccount(state, { account }: {account: Account}) {
    //   if (account.id !== undefined) {
    //     Vue.set(state.accounts, account.id, account);
    //   }
    // },
    // editAccount(state, { account }: {account: Account}) {
    //   if (account.id !== undefined) {
    //     Vue.set(state.accounts, account.id, account);
    //   }
    // },
    // removeAccount(state, { account }: {account: Account}) {
    //   Vue.delete(state.accounts, account.id);
    // },
  },
  actions: {
    async setTransfers({ commit }, { transfers }: {transfers: Transfer[]}) {
      commit('setTransfers', {
        transfers,
      });
    },
    async setCountTotalTransfers({ commit }, { count }: {count: number}) {
      commit('setCountTotalTransfers', {
        count,
      });
    },
    async setLastInputs({ commit }, { lastInputs }: {lastInputs: {}}) {
      commit('setLastInputs', {
        lastInputs,
      });
    },
    // async addAccount({ commit }, { account }: {account: Account}) {
    //   commit('addAccount', {
    //     account,
    //   });
    // },
    // async editAccount({ commit }, { account }: {account: Account}) {
    //   commit('editAccount', {
    //     account,
    //   });
    // },
    // async removeAccount({ commit }, { account }: {account: Account}) {
    //   commit('removeAccount', {
    //     account,
    //   });
    // },
  },
};
