<template>
  <v-col
    cols="12"
    xl="3"
    lg="4"
    sm="6"
  >
    <v-dialog
      v-model="dialogEdit"
      max-width="1000"
      persistent
    >
      <template v-slot:activator="{ on }">
        <v-card
          v-on="on"
        >
          <v-row
            dense
            class="px-3"
          >
            <v-col class="shrink font-weight-bold text-no-wrap">
              {{ transfer.title }}
            </v-col>
            <v-col
              class="text-end"
            >
              <base-amount
                :value="transfer.amount"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            class="px-3 body-2"
          >
            <v-col
              class="shrink text--secondary"
            >
              <base-date :value="transfer.date" />
            </v-col>
            <v-col
              class="shrink text--secondary"
            >
              -
            </v-col>
            <v-col
              class="text--secondary text-truncate text-no-wrap"
            >
              {{ transfer.accountSource.title }}
            </v-col>
            <v-col class="text--secondary shrink">
              <v-icon small>
                fas fa-long-arrow-alt-right
              </v-icon>
            </v-col>
            <v-col
              class="text--secondary text-no-wrap text-truncate"
            >
              {{ transfer.accountDestination.title }}
            </v-col>
            <v-col class="shrink text-end" />
          </v-row>
          <!--      <v-row-->
          <!--        dense-->
          <!--        class="px-3"-->
          <!--      >-->
          <!--        <v-col class="shrink">-->
          <!--          <base-amount-->
          <!--            :class="classes"-->
          <!--            :value="transfer.amount"-->
          <!--          />-->
          <!--        </v-col>-->
          <!--        <v-col-->
          <!--          class="text-end"-->
          <!--        >-->
          <!--          <base-date :value="transfer.date" />-->
          <!--        </v-col>-->
          <!--      </v-row>-->
        </v-card>
      </template>

      <v-card>
        <v-card-title>
          {{ $t('transfer.edit') }}
        </v-card-title>

        <v-card-text>
          <v-container class="py-0">
            <item-transfer
              ref="item-transfer"
              v-model="itemCurrent"
            />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            color="error"
            @click="openDeleteItem"
          >
            {{ $t('app.delete') }}
          </v-btn>

          <v-spacer />

          <v-btn
            text
            @click="dialogEdit = false"
          >
            {{ $t('app.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="editItem"
          >
            {{ $t('app.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ $t('transfer.delete') }}
        </v-card-title>

        <v-form>
          <v-card-text>
            {{ $t('transfer.deleteConfirmation', {name: itemCurrent.title}) }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogDelete = false"
            >
              {{ $t('app.cancel') }}
            </v-btn>
            <v-btn
              color="error"
              @click="deleteItem"
            >
              {{ $t('app.delete') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import BaseDate from '@/modules/app/components/base/base-date';
import BaseAmount from '@/modules/app/components/base/base-amount';
import { Transfer } from '@/modules/transfer/transfer.model';
import ItemTransfer from '@/modules/transfer/components/item-transfer';
import { ServiceTransfers } from '@/modules/transfer/transfer.service';

export default {
  name: 'Transfer',
  components: {
    ItemTransfer, BaseAmount, BaseDate,
  },
  props: {
    transfer: {
      required: true,
      type: Transfer,
    },
  },
  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      itemCurrent: {},
    };
  },
  watch: {
    dialogEdit() {
      if (this.dialogEdit === true) {
        this.itemCurrent = new Transfer(this.transfer);
      }
    },
  },
  methods: {
    async editItem() {
      const transfer = await ServiceTransfers.edit({ transfer: this.itemCurrent });

      this.$emit('update', transfer);

      this.dialogEdit = false;
    },
    openDeleteItem() {
      this.dialogDelete = true;
    },
    async deleteItem() {
      await ServiceTransfers.delete({ transfer: this.itemCurrent });

      this.$emit('delete');

      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>

</style>
