import Vue from 'vue';

export const moduleImport = {
  namespaced: true,
  state: {
    importData: null,
    importSettings: null,
  },
  mutations: {
    setImportData(state, importData: Record<string, unknown>) {
      state.importData = importData;
    },
    setImportSettings(state, { importSettings }: {importSettings: unknown[]}) {
      state.importSettings = importSettings.reduce((obj, importSetting) => {
        if (importSetting.id !== undefined) {
          obj[importSetting.id] = importSetting;
        }
        return obj;
      }, {} as {[key: number]: unknown});
    },
    deleteTransaction(state, item) {
      state.importData.transactions = state.importData.transactions.filter((transaction) => transaction !== item);
    },
    addImportSetting(state, importSetting) {
      if (importSetting.id !== undefined) {
        Vue.set(state.importSettings, importSetting.id, importSetting);
      }
    },
    editImportSetting(state, importSetting) {
      if (importSetting.id !== undefined) {
        Vue.set(state.importSettings, importSetting.id, importSetting);
      }
    },
    deleteImportSetting(state, importSetting) {
      Vue.delete(state.importSettings, importSetting.id);
    },
  },
};
