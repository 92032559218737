<template>
  <v-row
    no-gutters
    align="center"
  >
    <v-col
      cols="auto"
    >
      <v-select
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="sortByInternal"
        :items="items"
        hide-details
        dense
        min-width="400px"
        full-width
      />
      <v-dialog
        v-else
        v-model="dialog"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>
              fas fa-sort-amount-down
            </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-subheader>{{ $t('app.sortBy') }}</v-subheader>
            <v-list-item-group v-model="sortByInternal">
              <v-list-item
                v-for="item in items"
                :key="item.value"
                :value="item.value"
              >
                <v-list-item-content>
                  {{ item.text }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col class="shrink ml-1">
      <v-btn
        icon
        small
        @click="sortDescInternal = !sortDescInternal"
      >
        <v-icon>fas {{ sortDescInternal === true ? 'fa-chevron-down' : 'fa-chevron-up' }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SortTransactions',
  props: {
    sortBy: {
      required: true,
      type: Array,
    },
    sortDesc: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          value: 'title',
          text: this.$t('transaction.item.title'),
        },
        {
          value: 'amount',
          text: this.$t('transaction.item.amount'),
        },
        {
          value: 'date',
          text: this.$t('transaction.item.date'),
        },
        {
          value: 'category',
          text: this.$t('transaction.item.category'),
        },
        {
          value: 'account',
          text: this.$t('transaction.item.account'),
        },
        {
          value: 'person',
          text: this.$t('transaction.item.person'),
        },
        {
          value: 'date_created',
          text: this.$t('transaction.item.dateCreated'),
        },
        {
          value: 'date_changed',
          text: this.$t('transaction.item.dateChanged'),
        },
      ],
    };
  },
  computed: {
    sortByInternal: {
      get() {
        return this.sortBy[0];
      },
      set(sortBy) {
        this.dialog = false;
        this.$emit('update:sortBy', [sortBy]);
      },
    },
    sortDescInternal: {
      get() {
        return this.sortDesc[0];
      },
      set(sortDesc) {
        this.$emit('update:sortDesc', [sortDesc]);
      },
    },
  },
};
</script>

<style scoped>

</style>
