var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.accounts,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$tc('account.title', 2)))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('base-date-picker',{staticClass:"mr-3",attrs:{"properties":{'hide-details': true}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('create-account')],1)]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('base-account-name',{attrs:{"account":item,"options-chip":{ 'small': true, color: 'white' }}})]}},{key:"item.countTransactions",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.dateTransactionLast !== null)?_c('base-date',{attrs:{"value":item.dateTransactionLast}}):_vm._e(),_c('v-btn',{attrs:{"text":"","x-small":"","to":{
          name: 'transactions',
          query: {
            filters__account: item.id,
          },
        }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.amountInitial",fn:function(ref){
        var item = ref.item;
return [_c('base-amount',{attrs:{"value":item.amountInitial,"colored":true}})]}},{key:"item.iban",fn:function(ref){
        var value = ref.value;
return [(value === null)?[_vm._v(" - ")]:[_vm._v(" "+_vm._s(value)+" ")]]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [(item.amount)?_c('base-amount',{attrs:{"value":item.amount,"colored":true}}):_vm._e()]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.openEditItem(item)}}},[_vm._v(" fas fa-edit ")])],1),_c('v-col',[_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.openDeleteItem(item)}}},[_vm._v(" fas fa-trash ")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('account.edit'))+" ")]),_c('v-form',[_c('v-card-text',[_c('v-container',{staticClass:"py-0"},[_c('item-account',{ref:"item-account",model:{value:(_vm.itemCurrent),callback:function ($$v) {_vm.itemCurrent=$$v},expression:"itemCurrent"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogEdit = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.editItem}},[_vm._v(" "+_vm._s(_vm.$t('app.save'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('account.delete'))+" ")]),_c('v-form',[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('account.deleteConfirmation', {name:_vm.itemCurrent.title}))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t('app.delete'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }